import { Fragment, useMemo, useEffect } from 'react';

import {
	Text,
	Alert,
	Skeleton,
	Accordion,
	LoopWrapper,
	AccordionItem,
	AccordionTrigger,
	AccordionContent,
	TravelPlanPrice,
	NullTerniaryWrapper,
	Icon,
} from '@/components/common';
import { useBookingStore } from '@/store';
// import { useValueCallback } from '@/hooks';
import {
	FLIGHT_SPECIAL_ASSISTANCE_OPTIONS,
	cn,
	currencyFormatter,
	getCurrentStep,
} from '@/lib/utils';
import { TravelPlanFlightDetails } from '../..';
import { useLocation } from 'react-router-dom';
import { flightSteps as steps } from '@/lib/steps';

function TravelPlanDetails({ handleTotalPrice }) {
	const { pathname } = useLocation();
	const state = useBookingStore((store) => store.flights);

	const getBreakdown = (key) => {
		const breakdown = state?.preview?.breakdown || null;
		if (!breakdown || !Array.isArray(breakdown)) return null;
		return breakdown?.find((item) => item?.type === key) || null;
	};

	const totalPrice = useMemo(() => {
		if (!state?.preview?.overview?.total) return 0;
		return state?.preview?.overview?.total;
	}, [state?.preview?.overview?.total]);

	const optionPlusBreakdown = useMemo(
		() => getBreakdown('flightOptionPlus'),
		[state?.preview?.breakdown]
	);

	const baggageBreakdown = useMemo(
		() => getBreakdown('flightBaggage'),
		[state?.preview?.breakdown]
	);

	const pax = useMemo(() => {
		if (!state?.preview?.pax || !Array.isArray(state?.preview?.pax))
			return null;
		return state?.preview?.pax;
	}, [state?.preview?.pax]);

	const flightSeats = useMemo(
		() => getBreakdown('flightSeats'),
		[state?.preview?.breakdown]
	);

	useEffect(() => {
		if (handleTotalPrice) handleTotalPrice(totalPrice);
	}, [totalPrice, handleTotalPrice]);

	// useValueCallback(handleTotalPrice, totalPrice);

	const stateSelected = useMemo(() => {
		if (!state?.selected?.outbound && !state?.selected?.inbound) {
			return {
				title: 'Select your flights',
				description: 'Please select your flights to continue',
			};
		}

		if (!state?.selected?.outbound) {
			return {
				title: 'Select your flights',
				description: 'Please select your outbound flight to continue',
			};
		}

		// if not one way and no inbound flight selected - show the message to select inbound flight
		if (!state?.isOneWay && !state?.selected?.inbound) {
			return {
				title: 'Select your flights',
				description: 'Please select your return flight to continue',
			};
		}

		return null;
	}, [state?.selected, state?.isOneWay]);

	const isSeatingPage = useMemo(() => {
		if (!pathname) return false;

		const currentStep = getCurrentStep(steps, pathname);
		if (!currentStep?.to) return false;

		return currentStep.to.includes('seat');
	}, [steps, pathname]);

	return (
		<div className="flex flex-col gap-3">
			<NullTerniaryWrapper condition={!!state?.error} animate>
				<Alert
					className="mb-2"
					variant="destructive"
					title="Houston, we have a problem!"
					subtitle="Apologies, we have encountered an error with your request. Please <a href='mailto:enquiries@canadianaffair.com?subject=Website%20Error%20Query' target='_top' class='underline underline-offset-2'>email us</a> for assistance."
					showHtmlSubtitle={true}
				>
					{state?.error?.message}
				</Alert>
			</NullTerniaryWrapper>

			<NullTerniaryWrapper
				condition={
					isSeatingPage && !!state?.showOptionPlusSeatError && !state?.error
				}
				animate
			>
				<Alert
					className="mb-2"
					variant="destructive"
					title="Houston, we have a problem!"
					subtitle="Apologies, there is currently no seatmap available for this flight so we cannot complete your booking online. Our team would be happy to help complete this booking for you, please <a href='mailto:enquiries@canadianaffair.com?subject=Option%20Plus%20booking' target='_top' class='underline underline-offset-2'>email us</a> and we will be in touch shortly."
					showHtmlSubtitle={true}
				>
					{state?.error?.message}
				</Alert>
			</NullTerniaryWrapper>

			<NullTerniaryWrapper condition={!!stateSelected}>
				<Alert className="mb-2" variant="default" title={stateSelected?.title}>
					{stateSelected?.description}
				</Alert>
			</NullTerniaryWrapper>

			<NullTerniaryWrapper
				className="flex flex-col gap-2"
				condition={state?.previewLoading && !state?.preview}
			>
				<Alert className="mb-2" variant="default" title="Please wait">
					Checking your selected flights...
				</Alert>
				<Skeleton className="w-full h-5" />
				<Skeleton className="w-3/4 h-5" />
				<Skeleton className="w-1/2 h-5" />
			</NullTerniaryWrapper>

			<NullTerniaryWrapper condition={!!state?.preview}>
				<Accordion collapsible>
					<NullTerniaryWrapper condition={!!state?.preview?.breakdown}>
						{state?.preview?.breakdown?.map((item, idx) => {
							const allowedTypes = ['flights', 'flightsSeat'];

							if (!allowedTypes.includes(item?.type)) return null;

							const outbound = item?.outbound?.total ? item?.outbound : null;
							const inbound = item?.inbound?.total ? item?.inbound : null;

							const feesBreakdown = item?.breakdown || null;

							return (
								<Fragment key={`breakdown-${item?.type}-${idx}`}>
									<NullTerniaryWrapper condition={!!outbound}>
										<AccordionItem value="outbound" className="mb-2">
											<AccordionTrigger
												asChild
												className="flex-row-reverse items-start justify-end w-full gap-2 tracking-normal cursor-pointer"
											>
												<Text
													as="p"
													className="flex items-center justify-between w-full font-bold"
												>
													<Text as="span" className="font-bold">
														Outbound Flights
													</Text>
													<Text as="span" className="font-bold">
														{currencyFormatter({
															amount: outbound?.total ?? 0,
														})}
													</Text>
												</Text>
											</AccordionTrigger>
											<AccordionContent value="outbound">
												<LoopWrapper
													list={outbound?.flights ?? []}
													itemKey="flightNumber"
												>
													{(flight) => (
														<TravelPlanFlightDetails
															flight={flight}
															showPricingBreakdown={state?.preview?.showPricingBreakdown}
														/>
													)}
												</LoopWrapper>
											</AccordionContent>
										</AccordionItem>
									</NullTerniaryWrapper>
									<NullTerniaryWrapper condition={!!inbound}>
										<AccordionItem value="inbound" className="mb-2">
											<AccordionTrigger
												asChild
												className="flex-row-reverse items-start justify-end w-full gap-2 tracking-normal cursor-pointer"
											>
												<Text
													as="p"
													className="flex items-center justify-between w-full font-bold"
												>
													<Text as="span" className="font-bold">
														Return Flights
													</Text>
													<Text as="span" className="font-bold">
														{currencyFormatter({
															amount: inbound?.total ?? 0,
														})}
													</Text>
												</Text>
											</AccordionTrigger>
											<AccordionContent value="inbound">
												<LoopWrapper
													list={inbound?.flights ?? []}
													itemKey="flightNumber"
												>
													{(flight) => (
														<TravelPlanFlightDetails
															flight={flight}
															showPricingBreakdown={state?.preview?.showPricingBreakdown}
														/>
													)}
												</LoopWrapper>
											</AccordionContent>
										</AccordionItem>
									</NullTerniaryWrapper>
									<NullTerniaryWrapper condition={!!feesBreakdown}>
										<AccordionItem value="fees" className="mb-2">
											<AccordionTrigger
												asChild
												className="flex-row-reverse items-start justify-end w-full gap-2 tracking-normal cursor-pointer"
											>
												<Text
													as="p"
													className="flex items-center justify-between w-full font-bold"
												>
													<Text as="span" className="font-bold">
														Flights Breakdown
													</Text>
												</Text>
											</AccordionTrigger>
											<AccordionContent value="fees">
												<div className="my-2">
													<Text
														variant="muted"
														className="text-xs font-bold uppercase"
													>
														Air Transportation charges
													</Text>

													{Object.keys(feesBreakdown?.fares || {}).map((fare) => {
														if (!feesBreakdown?.fares[fare]) return null;

														const label = fare?.startsWith('fuel')
															? 'Fuel surcharge'
															: fare?.replace(/_/g, ' ');
														return (
															<div
																key={`breakdown-key-${fare}`}
																className="flex items-center justify-between my-4"
															>
																<Text
																	as="span"
																	className="text-sm font-bold capitalize"
																>
																	{label}
																</Text>
																<Text as="span" className="text-sm capitalize">
																	{currencyFormatter({
																		amount: feesBreakdown?.fares[fare],
																	})}
																</Text>
															</div>
														);
													})}
												</div>
												<div className="my-2">
													<Text
														variant="muted"
														className="text-xs font-bold uppercase"
													>
														Taxes, Fees & Charges
													</Text>

													<NullTerniaryWrapper
														condition={
															feesBreakdown?.fees &&
															Array.isArray(feesBreakdown?.fees)
														}
													>
														{feesBreakdown?.fees.map((fee) => (
															<div
																key={fee?.code}
																className="flex items-center justify-between my-4"
															>
																<Text
																	as="span"
																	className="text-sm font-bold capitalize"
																>
																	{fee?.name}
																</Text>
																<Text as="span" className="text-sm capitalize">
																	{currencyFormatter({
																		amount: fee?.price ?? 0,
																	})}
																</Text>
															</div>
														))}
													</NullTerniaryWrapper>
												</div>
											</AccordionContent>
										</AccordionItem>
									</NullTerniaryWrapper>
								</Fragment>
							);
						})}
					</NullTerniaryWrapper>
					<NullTerniaryWrapper
						condition={pax && Array.isArray(pax) && pax.length > 0}
					>
						<AccordionItem value="passengerDetails" className="mb-2">
							<AccordionTrigger
								asChild
								className="flex-row-reverse items-start justify-end w-full gap-2 tracking-normal cursor-pointer"
							>
								<Text
									as="p"
									className="flex items-center justify-between w-full font-bold"
								>
									Passenger Details
									{flightSeats?.pax && flightSeats?.pax.length > 0 && (
										<Icon name="seat" />
									)}
								</Text>
							</AccordionTrigger>
							<AccordionContent value="passengerDetails">
								<NullTerniaryWrapper
									condition={pax && Array.isArray(pax) && pax.length > 0}
									className="my-4"
								>
									<div className="my-4">
										{pax &&
											pax.map((passenger, idx) => {
												const seat = flightSeats?.pax?.find(
													(px) => px?.ref === passenger?.ref
												);

												// get selected special assistance selection
												const selectedAssistValue = state?.bookingDetails?.pax
													?.length
													? state?.bookingDetails?.pax.find(
															(px) => px?.ref === passenger?.ref
													  )?.assistance
													: null;

												const assistanceOption = selectedAssistValue
													? FLIGHT_SPECIAL_ASSISTANCE_OPTIONS.find(
															(opt) => opt?.value === selectedAssistValue
													  )
													: null;

												return (
													<div key={passenger?.ref || idx} className="mb-4">
														<div className="flex items-center justify-between">
															<Text
																as="span"
																className="text-sm font-bold capitalize"
															>
																{passenger?.fullName} ({passenger?.type})
															</Text>
															<Text as="span" className="text-sm">
																Passenger {idx + 1}{' '}
															</Text>
														</div>

														<NullTerniaryWrapper
															condition={
																seat?.seats &&
																Array.isArray(seat?.seats) &&
																seat?.seats.length > 0
																	? true
																	: false
															}
															className="mt-1"
														>
															{seat?.seats.map((item, seatIdx) => (
																<Text
																	key={seatIdx}
																	className={cn(
																		'text-sm capitalize',
																		seatIdx !== 0 && 'mt-1'
																	)}
																>
																	<span>
																		{`${item?.direction} Flight (${item?.carrier?.code}${item?.flightNumber}) Seat:`}{' '}
																	</span>
																	<span className="font-bold">
																		{item?.seatNumber}
																	</span>
																</Text>
															))}
														</NullTerniaryWrapper>

														<NullTerniaryWrapper
															condition={!!assistanceOption?.label}
															className="mt-1"
														>
															<Text className="text-sm">
																<span className="font-bold">
																	Special request:{' '}
																</span>
																<span>{assistanceOption?.label}</span>
															</Text>
														</NullTerniaryWrapper>
													</div>
												);
											})}
									</div>
								</NullTerniaryWrapper>
							</AccordionContent>
						</AccordionItem>
					</NullTerniaryWrapper>

					<NullTerniaryWrapper condition={optionPlusBreakdown ? true : false}>
						<AccordionItem value="optionPlus" className="mb-2">
							<AccordionTrigger
								asChild
								className="flex-row-reverse items-start justify-end w-full gap-2 tracking-normal cursor-pointer"
							>
								<Text
									as="p"
									className="flex items-center justify-between w-full font-bold"
								>
									<Text as="span" className="font-bold">
										Extras
									</Text>
									<Text as="span" className="font-bold">
										{currencyFormatter({
											amount: optionPlusBreakdown?.total ?? 0,
										})}
									</Text>
								</Text>
							</AccordionTrigger>
							<AccordionContent value="optionPlus">
								{optionPlusBreakdown?.breakdown && (
									<div className="flex flex-col gap-4 my-2">
										{optionPlusBreakdown?.breakdown?.map((option) => (
											<div
												key={option?.name}
												className="flex items-center justify-between"
											>
												<Text
													as="span"
													className="text-sm font-bold capitalize"
												>
													{option?.name}
												</Text>
												<Text as="span" className="text-sm">
													{currencyFormatter({
														amount: option?.total ?? 0,
													})}
												</Text>
											</div>
										))}
									</div>
								)}
							</AccordionContent>
						</AccordionItem>
					</NullTerniaryWrapper>

					<NullTerniaryWrapper
						condition={
							baggageBreakdown && baggageBreakdown?.total > 0 ? true : false
						}
					>
						<AccordionItem value="baggage" className="mb-2">
							<AccordionTrigger
								asChild
								className="flex-row-reverse items-start justify-end w-full gap-2 tracking-normal cursor-pointer"
							>
								<Text
									as="p"
									className="flex items-center justify-between w-full font-bold"
								>
									<Text as="span" className="font-bold">
										Additional Baggage
									</Text>
									<Text as="span" className="font-bold">
										{currencyFormatter({
											amount: baggageBreakdown?.total ?? 0,
										})}
									</Text>
								</Text>
							</AccordionTrigger>
							<AccordionContent value="baggage">
								{baggageBreakdown?.prices &&
									Object.keys(baggageBreakdown?.prices).map((person, idx) => {
										const personDetails = baggageBreakdown?.prices[person];

										if (!personDetails?.total) return null;
										return (
											<div className="my-1" key={`baggage-${idx}`}>
												<div className="flex items-center justify-between">
													<Text
														as="span"
														className="text-sm font-bold capitalize"
													>
														{person} Totals
													</Text>
													<Text as="span" className="text-sm">
														{currencyFormatter({
															amount: personDetails?.total ?? 0,
														})}
													</Text>
												</div>
											</div>
										);
									})}
							</AccordionContent>
						</AccordionItem>
					</NullTerniaryWrapper>
				</Accordion>
			</NullTerniaryWrapper>

			<TravelPlanPrice isLoading={state?.previewLoading} total={totalPrice} />
		</div>
	);
}

export default TravelPlanDetails;

import PropTypes from 'prop-types';
import { FlightCard } from '@components/flights';
import { CheckButton, Text } from '@/components/common';
import { formatLocalDate } from '@/lib/utils';
import { useBreakpoint } from '@/hooks';
import RouteDuration from '@components/flights/molecules/route-duration';

function FlightBlockListItemDetails({ flight, isSelected, onChange, disabled }) {
	const isMd = useBreakpoint('md');

	return (
		<div className="flex flex-col w-full gap-1.5 py-5 lg:py-6">
			<div className="flex flex-row items-center gap-2">
				{flight?.departureTime ? (
					<Text className="font-bold leading-snug lg:w-16 tracking-less-tight text-dark-grey shrink-0">
						{formatLocalDate(flight?.departureTime, 'dd MMM')}
					</Text>
				) : null}
				<Text className="flex flex-row items-center gap-1 grow">
					{flight?.carrier?.name ? (
						<Text
							as="span"
							className="text-sm font-normal leading-snug tracking-tighter text-dark-grey"
						>
							{flight?.carrier?.name}
						</Text>
					) : null}
					<Text
						as="span"
						className="text-sm font-normal leading-snug tracking-tighter text-dark-grey"
					>
						{flight?.carrier?.code}
						{flight?.flightNumber}
					</Text>
				</Text>

				{isMd && <RouteDuration flight={flight} />}
			</div>

			<div className="flex flex-col items-start gap-3 md:gap-3.5 xl:flex-row xl:items-center xl:gap-6">
				<FlightCard
					hideFlightLogo
					flight={flight}
					hideMoreInfoToggle
					durationBetweenLoc={true}
					className="grow"
					overviewClassName="gap-0"
					{...(isMd && {
						durationAboveRoute: true,
						showRouteNameWithCity: true,
					})}
				/>
				<div className="flex flex-col items-start gap-3 md:gap-2.5 xl:flex-row xl:items-center xl:gap-6">
					{flight?.availableFareSummary && (
						<Text className="text-base font-medium leading-snug xl:w-32 tracking-less-tight text-dark-grey">
							{flight?.availableFareSummary}
						</Text>
					)}
					<CheckButton
						checked={isSelected}
						onChange={onChange}
						className="w-40 gap-2"
						renderText={(checked) => (
							<Text
								as="span"
								className="text-base leading-snug tracking-tighter"
							>
								{checked ? 'Selected' : 'Select'}
							</Text>
						)}
						isDisabled={disabled}
					/>
				</div>
			</div>
		</div>
	);
}

FlightBlockListItemDetails.propTypes = {
	flight: PropTypes.object,
	isSelected: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

FlightBlockListItemDetails.defaultProps = {
	flight: {},
	disabled: false,
};

export default FlightBlockListItemDetails;

import { TravelPlanFlightDetails } from '@/components/flights';
import { currencyFormatter } from '@/lib/utils';
import {
	Text,
	AccordionItem,
	AccordionContent,
	AccordionTrigger,
	NullTerniaryWrapper,
	LoopWrapper,
} from '@components/common';
import { Fragment } from 'react';

const Flight = ({ option, showPricingBreakdown }) => {
	const outbound = option.outbound?.flights?.length ? option?.outbound : null;
	const inbound = option.inbound?.flights?.length ? option?.inbound : null;

	const feesBreakdown = showPricingBreakdown && option?.breakdown ?
		option?.breakdown :
		null;

	return (
		<Fragment key={`breakdown-${option.total}`}>
			<NullTerniaryWrapper condition={!!outbound}>
				<AccordionItem value="outbound" className="mb-2">
					<AccordionTrigger
						asChild
						className="flex-row-reverse items-start justify-end w-full gap-2 tracking-normal cursor-pointer"
					>
						<Text
							as="p"
							className="flex items-center justify-between w-full font-bold"
						>
							<Text as="span" className="font-bold">
								Outbound Flights
							</Text>
							<NullTerniaryWrapper
								condition={showPricingBreakdown && outbound?.total > 0}
							>
								<Text as="span" className="font-bold">
									{currencyFormatter({
										amount: outbound?.total ?? 0,
									})}
								</Text>
							</NullTerniaryWrapper>
						</Text>
					</AccordionTrigger>
					<AccordionContent value="outbound">
						<LoopWrapper list={outbound?.flights ?? []} itemKey="flightNumber">
							{(flight) => (
								<TravelPlanFlightDetails
									flight={flight}
									showPricingBreakdown={showPricingBreakdown}
								/>
							)}
						</LoopWrapper>
					</AccordionContent>
				</AccordionItem>
			</NullTerniaryWrapper>
			<NullTerniaryWrapper condition={!!inbound}>
				<AccordionItem value="inbound" className="mb-2">
					<AccordionTrigger
						asChild
						className="flex-row-reverse items-start justify-end w-full gap-2 tracking-normal cursor-pointer"
					>
						<Text
							as="p"
							className="flex items-center justify-between w-full font-bold"
						>
							<Text as="span" className="font-bold">
								Return Flights
							</Text>
							<NullTerniaryWrapper
								condition={showPricingBreakdown && inbound?.total > 0}
							>
								<Text as="span" className="font-bold">
									{currencyFormatter({
										amount: inbound?.total ?? 0,
									})}
								</Text>
							</NullTerniaryWrapper>
						</Text>
					</AccordionTrigger>
					<AccordionContent value="inbound">
						<LoopWrapper list={inbound?.flights ?? []} itemKey="flightNumber">
							{(flight) => (
								<TravelPlanFlightDetails
									flight={flight}
									showPricingBreakdown={showPricingBreakdown}
								/>
							)}
						</LoopWrapper>
					</AccordionContent>
				</AccordionItem>
			</NullTerniaryWrapper>
			<NullTerniaryWrapper condition={!!feesBreakdown}>
				<AccordionItem value="fees" className="mb-2">
					<AccordionTrigger
						asChild
						className="flex-row-reverse items-start justify-end w-full gap-2 tracking-normal cursor-pointer"
					>
						<Text
							as="p"
							className="flex items-center justify-between w-full font-bold"
						>
							<Text as="span" className="font-bold">
								Flights Breakdown
							</Text>
						</Text>
					</AccordionTrigger>
					<AccordionContent value="fees">
						<div className="my-2">
							<Text variant="muted" className="text-xs font-bold uppercase">
								Air Transportation charges
							</Text>

							<NullTerniaryWrapper
								condition={!!feesBreakdown?.fares && Array.isArray(feesBreakdown?.fees)}
							>
								{Object.keys(feesBreakdown?.fares || {}).map((fare) => {
									if (!feesBreakdown?.fares[fare]) return null;

									const label = fare?.startsWith('fuel')
										? 'Fuel surcharge'
										: fare?.replace(/_/g, ' ');
									return (
										<div
											key={`breakdown-key-${fare}`}
											className="flex items-center justify-between my-4"
										>
											<Text as="span" className="text-sm font-bold capitalize">
												{label}
											</Text>
											<Text as="span" className="text-sm capitalize">
												{currencyFormatter({
													amount: feesBreakdown?.fares[fare],
												})}
											</Text>
										</div>
									);
								})}
							</NullTerniaryWrapper>
						</div>
						<div className="my-2">
							<Text variant="muted" className="text-xs font-bold uppercase">
								Taxes, Fees & Charges
							</Text>

							<NullTerniaryWrapper
								condition={
									feesBreakdown?.fees && Array.isArray(feesBreakdown?.fees)
								}
							>
								{feesBreakdown?.fees?.map((fee) => (
									<div
										key={fee?.code}
										className="flex items-center justify-between my-4"
									>
										<Text as="span" className="text-sm font-bold capitalize">
											{fee?.name}
										</Text>
										<Text as="span" className="text-sm capitalize">
											{currencyFormatter({
												amount: fee?.price ?? 0,
											})}
										</Text>
									</div>
								))}
							</NullTerniaryWrapper>
						</div>
					</AccordionContent>
				</AccordionItem>
			</NullTerniaryWrapper>
		</Fragment>
	);
};

export default Flight;

import { useMemo } from 'react';

import { currencyFormatter } from '@/lib/utils';
import { FlightDestinationSummary, SmallFlightCard } from '@components/flights';
import { ToggleButtonGroup, Text, Icon } from '@components/common';
import pluralize from 'pluralize';
import { useBookingStore } from '@/store';

function BaggageOption({
	category,
	route,
	flight,
	baggage,
	passenger,
	selectedOptions,
	handleSelection,
}) {
	const state = useBookingStore((store) => store[category]);
	const formatBaggageOption = ({ quantity, weight, paxRef }) =>
		`${quantity}-${weight}-${paxRef}-${route}-bag`;

	// use a different weight for infants, if provided
	const weightForPax = useMemo(
		() =>
			passenger?.type?.toLowerCase() === 'infant' &&
			!isNaN(parseFloat(baggage?.infantWeight))
				? baggage?.infantWeight
				: baggage?.weight,
		[baggage, passenger]
	);

	const opts = useMemo(() => {
		if (!passenger?.ref) return [];
		if (!baggage?.pricingMatrix) return [];
		if (typeof weightForPax === 'undefined') return [];

		const radioOptions = [
			// default option - quantity included with flight
			{
				label: 'No Additional',
				value: formatBaggageOption({
					quantity: baggage?.totalIncludedWithFlight || 0,
					weight: weightForPax,
					paxRef: passenger.ref,
				}),
			},
		];

		if (baggage?.pricingMatrix?.quantity === 1) {
			radioOptions.push({
				label: `+ ${weightForPax}kg Bag (${currencyFormatter({
					amount: baggage?.pricingMatrix?.price,
					params: { hideDecimal: baggage?.pricingMatrix?.price % 1 === 0 },
				})})`,
				value: formatBaggageOption({
					quantity: baggage?.pricingMatrix?.totalToBook,
					weight: weightForPax,
					paxRef: passenger.ref,
				}),
			});
		}

		return radioOptions;
	}, [baggage?.pricingMatrix, passenger?.ref, weightForPax]);

	const value = useMemo(() => {
		if (typeof weightForPax === 'undefined') return;

		// do not select if infant and not allowed bags
		if (
			!baggage.infantAllowedBags &&
			passenger?.type?.toLowerCase() === 'infant'
		)
			return;

		// select default option if none selected
		const selectedOpt = selectedOptions?.[route]
			? selectedOptions[route]?.find((opt) => opt?.weight === weightForPax)
			: null;
		if (!(selectedOpt && Array.isArray(selectedOpt?.pax))) {
			return opts[0]?.value;
		}

		// select chosen value, or default to first option
		const selectedValue = selectedOpt?.pax
			? selectedOpt?.pax.find((pax) => pax?.ref === passenger?.ref)
			: null;
		return selectedValue?.quantity
			? formatBaggageOption({
					quantity: selectedValue?.quantity,
					weight: weightForPax,
					paxRef: passenger?.ref,
			  })
			: opts[0]?.value;
	}, [route, selectedOptions, opts, weightForPax, passenger?.ref]);

	const isAdditionalAvailable = useMemo(() => {
		if (!baggage?.pricingMatrix) return false;

		// check if infants can book bags
		if (passenger?.type?.toLowerCase() === 'infant') {
			return baggage?.infantAllowedBags === true;
		}

		// all other age groups can always book bags if available
		return baggage?.pricingMatrix?.quantity === 1;
	}, [baggage, passenger?.type]);

	const totalIncluded = useMemo(() => {
		if (
			!baggage?.infantAllowedBags &&
			passenger?.type?.toLowerCase() === 'infant'
		) {
			return 0;
		}

		return baggage?.totalIncludedWithFlight;
	}, [baggage, passenger?.type]);

	return (
		<div>
			<SmallFlightCard
				flight={flight}
				key={`${route}-${passenger?.ref}`}
				className="gap-1.5 lg:gap-2.5"
				renderTitle={() => (
					<Text className="font-bold w-fit mb-1.5 lg:gap-2.5">
						Additional Baggage ({route === 'outbound' ? 'Outbound' : 'Return'}{' '}
						Flights)
					</Text>
				)}
				renderSummary={() => (
					<div className="flex flex-col">
						<div className="flex items-center justify-start gap-2 text-dark-grey md:items-center">
							{totalIncluded >= 1 ? (
								<>
									<Icon name="baggage" className="w-8 h-8 p-1.5 shrink-0" />
									<Text className="font-normal tracking-normal">
										There {pluralize('is', totalIncluded)} {totalIncluded}x 23kg{' '}
										{pluralize('bag', totalIncluded)} included with your flight
									</Text>
								</>
							) : (
								<>
									<Icon
										name="baggage-strikethrough"
										className="w-8 h-8 p-1 shrink-0"
									/>
									<Text className="font-normal tracking-normal">
										There aren't any bags included with your flight
									</Text>
								</>
							)}
						</div>
						<FlightDestinationSummary flight={flight} />
					</div>
				)}
			>
				<div className="flex w-auto">
					{!isAdditionalAvailable ? (
						<div>
							<Text variant="muted">No additional baggage available</Text>
						</div>
					) : (
						<ToggleButtonGroup
							value={value}
							disabled={
								opts.length === 1 || state?.previewLoading || state?.error
							}
							className="w-full md:w-auto"
							onChange={(option) => {
								if (!option) return;
								if (!passenger?.ref) return;

								const [optQuantity, optWeight] = option?.split('-');
								const selected = {
									weight: parseFloat(optWeight),
									pax: {
										ref: passenger.ref,
										quantity: parseInt(optQuantity),
									},
								};
								if (isNaN(selected.weight) || isNaN(selected.pax.quantity))
									return;

								handleSelection(selected, route, passenger.ref);
							}}
							options={opts}
						/>
					)}
				</div>
			</SmallFlightCard>
		</div>
	);
}
export default BaggageOption;

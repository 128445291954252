import qs from 'qs';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	addDays,
	differenceInDays,
	format,
	isDate,
	isValid,
	startOfDay,
} from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import {
	Button,
	WhoForm,
	FormField,
	SearchFormButton,
} from '@/components/common';
import {
	HOTEL_WHO_OPTIONS,
	HOLIDAY_DURATIONS,
	HOLIDAY_DESTINATIONS_DEPARTING,
	SEARCH_HOLIDAY_TYPES,
	Required,
} from '@/lib/utils';
import { HOLIDAY_MAX_DATE } from '@/lib/utils/restrictions';
import { useSearchSave } from '@/hooks';
import { holidaySearchSchema } from '@/validationSchemas';
import RoomChoiceFields from '@/components/hotels/forms/RoomChoiceFields';

function HolidaySearchForm({ clear, params, hideClearBtn }) {
	const navigate = (url) => (window.location.href = url);

	const { saveSearch, getSearch } = useSearchSave();

	const [showRoomOptions, setShowRoomOptions] = useState(
		params?.rooms?.length >= 1
	);

	const [showDatePicker, setShowDatePicker] = useState(true);

	const savedSearch = getSearch('holidays');

	let defaultParams = null;

	if (savedSearch && !params) {
		defaultParams = qs.parse(savedSearch);
	} else {
		defaultParams = params;
	}

	const emptyParams = {
		type: {
			label: 'City Break',
			value: 'holidays',
		},
		location: null,
		from: null,
		rooms: [],
		who: HOTEL_WHO_OPTIONS.reduce((acc, option) => {
			acc[option.value] = null;
			return acc;
		}, {}),
		month: null,
		duration: null,
		when: {
			from: addDays(new Date(), 3),
			to: addDays(new Date(), 8),
		},
	};

	const defaultValues = useMemo(() => {
		if (!defaultParams) return emptyParams;

		const type = defaultParams?.type ? defaultParams.type.split(':') : [];
		const location = defaultParams?.location
			? defaultParams.location.split(':')
			: [];
		const from = defaultParams?.from ? defaultParams.from.split(':') : [];
		const month = defaultParams?.month ? defaultParams.month.split(':') : [];
		const duration = defaultParams?.duration
			? defaultParams.duration.split(':')
			: [];
		const startDate =
			defaultParams?.startDate && new Date(defaultParams.startDate);
		const endDate = defaultParams?.endDate && new Date(defaultParams.endDate);

		const roomCount = defaultParams?.roomCount
			? defaultParams.roomCount.split(':')
			: [];

		const roomdefaultParams = defaultParams?.rooms?.length
			? defaultParams.rooms
			: [];

		const rooms = roomdefaultParams.map((room) => {
			const acc = {};

			Object.keys(room).map((type) => {
				const value = room[type];
				acc[type] = {
					value,
					label: value.toString(),
				};
			}, {});

			return acc;
		});

		const hasMaxDate = HOLIDAY_MAX_DATE && isValid(new Date(HOLIDAY_MAX_DATE));

		// only pre-fill if at least 3 days from today
		const fromDate =
			isDate(startDate) &&
				differenceInDays(startOfDay(startDate), startOfDay(new Date())) >= 3 &&
				(!hasMaxDate || differenceInDays(
					startOfDay(startDate),
					startOfDay(new Date(HOLIDAY_MAX_DATE))
				) <= 0)
				? startDate
				: addDays(new Date(), 3);

		// only pre-fill if after the from date
		const defaultEndDate = !hasMaxDate ||
			differenceInDays(
				addDays(fromDate, 8),
				startOfDay(new Date(HOLIDAY_MAX_DATE))
			) <= 0
			? addDays(fromDate, 8)
			: new Date(HOLIDAY_MAX_DATE);
		const toDate =
			isDate(endDate) &&
				differenceInDays(startOfDay(endDate), startOfDay(fromDate)) >= 0 &&
				(!hasMaxDate || differenceInDays(
					startOfDay(endDate),
					startOfDay(new Date(HOLIDAY_MAX_DATE))
				) <= 0)
				? endDate
				: defaultEndDate;

		return {
			type: type[0]
				? {
						value: type[0],
						label: type[1] || '',
				  }
				: emptyParams.type,
			location: location[0]
				? {
						value: location[0],
						label: location[1] || '',
				  }
				: null,
			from: from[0]
				? {
						value: from[0],
						label: from[1] || '',
				  }
				: null,
			month: month[0]
				? {
						value: month[0],
						label: month[1] || '',
				  }
				: null,
			duration: duration[0]
				? {
						value: duration[0],
						label: duration[1] || '',
				  }
				: null,
			roomCount: roomCount[0]
				? {
						value: roomCount[0],
						label: roomCount[1] || '',
				  }
				: null,
			rooms: rooms,
			when: {
				from: fromDate,
				to: toDate,
			},
		};
	}, [defaultParams, emptyParams, HOLIDAY_MAX_DATE]);

	const methods = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(holidaySearchSchema(showDatePicker)),
	});

	const {
		watch,
		control,
		setValue,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = methods;

	const monthOptions = useMemo(() => {
		const date = startOfDay(new Date());
		const maxDate = startOfDay(new Date(HOLIDAY_MAX_DATE));
		const options = [
			{
				value: format(date, 'yyyy-MM'),
				label: format(date, 'MMMM yyyy'),
			},
		];

		// show all months up to a year in advance
		for (let i = 0; i <= 11; i++) {
			date.setMonth(date.getMonth() + 1);

			// do not exceed max date
			if (differenceInDays(date, maxDate) > 0) break;

			options.push({
				value: format(date, 'yyyy-MM'),
				label: format(date, 'MMMM yyyy'),
			});
		}

		return options;
	}, [HOLIDAY_MAX_DATE]);

	const formStartDate = watch('when.from');
	const formEndDate = watch('when.to');
	useEffect(() => {
		if (!HOLIDAY_MAX_DATE) return;
		if (!(formStartDate && formEndDate)) return;

		// reset form value if not valid with max date restriction
		const maxDate = new Date(HOLIDAY_MAX_DATE);
		if (
			differenceInDays(new Date(formStartDate), maxDate) > 0 ||
			differenceInDays(new Date(formEndDate), maxDate) > 0
		) {
			setValue('when', {});
		}
	}, [HOLIDAY_MAX_DATE, formStartDate, formEndDate]);

	const onSubmit = async (data) => {
		const { type, location, from, rooms, month, duration, when } = data;
		const totalRooms = roomCount?.value ? parseInt(roomCount.value) : 1;
		const formattedRooms = [];
		if (rooms?.length) {
			rooms.forEach((room, i) => {
				if (i + 1 > totalRooms) return;

				const roomData = {
					adults: 0,
					children: 0,
					infants: 0,
				};

				Object.keys(room).map((type) => {
					const roomInfo = room[type];
					if (!roomInfo) return;

					roomData[type] = roomInfo.value;
				});
				formattedRooms.push(roomData);
			});
		}

		const queryString = qs.stringify({
			type: `${type.value}:${type.label}`,
			location: `${location.value}:${location.label}`,
			from: `${from.value}:${from.label}`,
			month: month?.value ? `${month.value}:${month.label}` : '',
			duration: duration?.value ? `${duration.value}:${duration.label}` : '',
			startDate:
				!month?.value && when?.from ? format(when.from, 'yyyy-MM-dd') : '',
			endDate: !month?.value && when?.to ? format(when.to, 'yyyy-MM-dd') : '',
			roomCount: roomCount?.value
				? `${roomCount.value}:${roomCount.label}`
				: '',
			rooms: formattedRooms,
			category: 'holidays',
		});

		saveSearch(queryString, 'holidays');
		// console.log('submit', params.toString());
		navigate(`/search/holidays?${queryString}`);
	};

	const roomCount = watch('roomCount');

	const toggleDatePicker = (e) => {
		e.preventDefault();

		const newShowDatePicker = !showDatePicker;
		setShowDatePicker(newShowDatePicker);

		if (newShowDatePicker) {
			setValue('month', null);
			setValue('duration', null);
			clearErrors('month');
			clearErrors('duration');
		} else if (!newShowDatePicker) {
			setValue('when', {});
			clearErrors('when');
		}
	};

	useEffect(() => {
		if (savedSearch && !params) {
			const parsedSavedSearch = qs.parse(savedSearch);
			setShowDatePicker(
				!!parsedSavedSearch?.startDate && !!parsedSavedSearch?.endDate
			);
		} else {
			setShowDatePicker(
				!params?.month || (!!params?.startDate && !!params?.endDate)
			);
		}
	}, [params, savedSearch]);

	useEffect(() => {
		if (roomCount?.value) {
			setShowRoomOptions(true);
		}
	}, [roomCount]);

	const validateMaxPeopleOfType = useCallback((type) => (value, formValues) => {
		const whoKeys = HOTEL_HOTEL_WHO_OPTIONS.map((option) => option.value);
		if (!whoKeys.includes(type)) return true;

		// prevent more than the entered number of adults/children/infants being searched for
		const rooms = formValues.rooms || [];
		const maxPeople = formValues?.who[type]?.value
			? parseInt(formValues?.who[type].value)
			: 0;

		let total = 0;
		rooms.forEach(
			(room) => (total += room[type]?.value ? parseInt(room[type]?.value) : 0)
		);
		if (total <= maxPeople || !parseInt(value?.value)) return true; // hide message if valid, or an empty field

		return maxPeople <= 0
			? `You have not added any ${type}.`
			: `You have only added ${maxPeople} ${pluralize(type, maxPeople)}`;
	});

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="mx-auto flex flex-col 2xl:flex-row h-auto w-full items-center justify-center 2xl:items-start gap-2 md:gap-2.5 lg:gap-5">
					<div className="flex flex-col sm:flex-row w-full items-start justify-start gap-2 md:gap-2.5 lg:gap-5">
						<FormField
							name="type"
							label="Holiday Type"
							as="select"
							control={control}
							errors={errors}
							wrapperClassName="h-full w-full 2xl:min-w-[100px] sm:w-1/3"
							labelClassName="whitespace-nowrap"
							placeholder="Select"
							options={SEARCH_HOLIDAY_TYPES}
						/>

						<div className="flex flex-col sm:flex-row w-full items-start justify-start gap-2 md:gap-2.5 lg:gap-5 sm:w-2/3">
							<FormField
								name="from"
								label="Departing from"
								as="select"
								control={control}
								errors={errors}
								wrapperClassName="h-full w-full"
								labelClassName="whitespace-nowrap"
								placeholder="Select"
								options={HOLIDAY_DESTINATIONS_DEPARTING}
							/>

							<FormField
								name="location"
								label="Going to"
								as="select"
								control={control}
								errors={errors}
								wrapperClassName="h-full w-full 2xl:min-w-[100px]"
								labelClassName="whitespace-nowrap"
								placeholder="Select"
								endpoint="holiday/locations"
								queryKey="holiday-locations"
								isAsync
							/>
						</div>
					</div>

					<div className="flex flex-col sm:flex-row w-full items-start justify-start gap-2 md:gap-2.5 lg:gap-5">
						<FormField
							name="roomCount"
							label="Rooms"
							as="select"
							options={[
								{ value: 1, label: '1' },
								{ value: 2, label: '2' },
								{ value: 3, label: '3' },
							]}
							onChange={(field, value) => {
								field.onChange(value);

								// remove additional rooms from the form values
								const newTotal = parseInt(value?.value);
								const currentRooms = watch('rooms');
								if (
									newTotal &&
									currentRooms?.length &&
									currentRooms?.length > newTotal
								) {
									const newRooms = [...currentRooms];
									while (newRooms?.length && newRooms?.length > newTotal) {
										newRooms.pop();
									}

									// update the rooms field
									setValue('rooms', newRooms);
								}

								// reset errors from previous rooms
								clearErrors();
							}}
							control={control}
							errors={errors}
							validation={[Required]}
							wrapperClassName="xl:min-w-[100px] sm:w-1/3 2xl:w-full"
							placeholder="Select"
						/>

						{/* Mobile-only room fields */}
						{showRoomOptions && roomCount?.value && (
							<RoomChoiceFields
								errors={errors}
								control={control}
								roomCount={roomCount}
								className="w-full sm:hidden"
								handleSubmit={handleSubmit(onSubmit)}
								validateMaxPeopleOfType={validateMaxPeopleOfType}
							/>
						)}

						{showDatePicker ? (
							<FormField
								name="when"
								label="When"
								as="date-range"
								control={control}
								errors={errors}
								wrapperClassName="h-full w-full lg:min-w-[330px] sm:w-2/3 2xl:w-full"
								footer={
									<div className="flex flex-row items-center gap-1 mb-1">
										Don’t know exact dates?{' '}
										<Button
											variant="button-field-footer"
											onClick={toggleDatePicker}
											className="font-medium leading-tight underline underline-offset-4"
										>
											Search by month.
										</Button>
									</div>
								}
								hideTime
								disabledDays={{
									before: addDays(new Date(), 3),
									after: HOLIDAY_MAX_DATE ? new Date(HOLIDAY_MAX_DATE) : null,
								}}
							/>
						) : (
							<div className="flex flex-col w-full h-full sm:w-2/3 2xl:w-full">
								<div className="w-full flex flex-col sm:flex-row gap-2 md:gap-2.5 lg:gap-5">
									<FormField
										name="month"
										label="When"
										as="select"
										control={control}
										errors={errors}
										wrapperClassName="w-full lg:min-w-[275px]"
										placeholder="Select"
										options={monthOptions}
										footer={
											<div className="flex flex-row items-center gap-1 sm:hidden">
												Know exact dates?{' '}
												<Button
													variant="button-field-footer"
													onClick={toggleDatePicker}
													className="font-medium leading-tight underline underline-offset-4"
												>
													Use date picker.
												</Button>
											</div>
										}
									/>

									<FormField
										name="duration"
										label="Duration"
										as="select"
										control={control}
										errors={errors}
										wrapperClassName="w-full lg:min-w-[180px] 2xl:w-[180px]"
										placeholder="Select"
										options={HOLIDAY_DURATIONS}
									/>
								</div>

								<div className="flex-row items-center hidden gap-1 mb-1 text-sm font-normal leading-tight text-opacity-75 text-light-black text-body sm:flex">
									Know exact dates?{' '}
									<Button
										variant="button-field-footer"
										onClick={toggleDatePicker}
										className="font-medium leading-tight underline underline-offset-4"
									>
										Use date picker.
									</Button>
								</div>
							</div>
						)}
					</div>

					{/* Tablet/small desktop-only room fields */}
					{showRoomOptions && roomCount?.value && (
						<RoomChoiceFields
							control={control}
							errors={errors}
							roomCount={roomCount}
							handleSubmit={handleSubmit(onSubmit)}
							className="hidden w-full sm:flex 2xl:hidden"
							validateMaxPeopleOfType={validateMaxPeopleOfType}
						/>
					)}

					<SearchFormButton
						onCancel={clear}
						label="Find Holidays"
						hideCancel={hideClearBtn}
						handleSubmit={handleSubmit(onSubmit)}
						hasYouth={watch('who.youth')?.value > 0}
						className="lg:mt-0 2xl:mt-6"
					/>
				</div>

				{/* Desktop-only room fields */}
				{showRoomOptions && roomCount?.value && (
					<RoomChoiceFields
						errors={errors}
						control={control}
						roomCount={roomCount}
						className="hidden 2xl:flex"
						handleSubmit={handleSubmit(onSubmit)}
						validateMaxPeopleOfType={validateMaxPeopleOfType}
					/>
				)}
			</form>
		</FormProvider>
	);
}

HolidaySearchForm.propTypes = {
	clear: PropTypes.func.isRequired,
	params: PropTypes.shape({
		type: PropTypes.string,
		location: PropTypes.string,
		from: PropTypes.string,
		adults: PropTypes.string,
		children: PropTypes.string,
		infants: PropTypes.string,
		month: PropTypes.string,
		duration: PropTypes.string,
		startDate: PropTypes.string,
		endDate: PropTypes.string,
	}),
};

HolidaySearchForm.defaultProps = {
	params: {},
};

export default HolidaySearchForm;

import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import {
	mergeArrayOfObjects,
	FLIGHT_SPECIAL_ASSISTANCE_OPTIONS,
} from '@/lib/utils';
import { useBookingStore } from '@/store';
import { useRemoveQueries } from '@/hooks';
import PassengerDetailsTemplate from '@components/templates/passenger-details';

function FlightsPassengers() {
	const params = useParams();
	const { pathname } = useLocation();
	const { removeQueries } = useRemoveQueries();
	const { setBookingState } = useBookingStore();

	const category = params?.category
		? params?.category
		: pathname && pathname.split('/')[2];

	const state = useBookingStore((store) => store[category]);

	// reset loading state
	useEffect(() => {
		if (state?.isLoading) {
			setBookingState(category, { isLoading: false }, 'RESET_ISLOADING');
		}
	}, [state?.isLoading, category]);

	// run only once on mount
	useEffect(() => {
		// reset terms and conditions & read information
		const checkedConditions =
			state?.passengerDetails?.terms_conditions &&
			state?.passengerDetails?.read_information;

		if (checkedConditions) {
			setBookingState(category, {
				passengerDetails: {
					...state.passengerDetails,
					terms_conditions: false,
					read_information: false,
				},
			});
		}
	}, []); // don't add any dependencies even if it warns

	const handleContinue = ({ passengerDetails, bookingDetails }) => {
		let hasChangedAge = false;
		if (state?.bookingDetails?.pax) {
			// determine if age has changed
			const anyPaxChangedAge = !bookingDetails?.pax?.every((pax) => {
				const prevPax = state.bookingDetails.pax?.find(
					(oldPax) => oldPax?.ref === pax?.ref
				);
				return JSON.stringify(prevPax?.dob) === JSON.stringify(pax?.dob);
			});
			hasChangedAge =
				state?.bookingDetails?.pax?.length !== bookingDetails?.pax?.length ||
				anyPaxChangedAge;
		} else {
			// age has changed if we didn't previously have pax info
			hasChangedAge = true;
		}

		let selected = state?.selected;
		const selectedItems = mergeArrayOfObjects({
			// merge the selected items with the additional params
			toMerge: state?.preview?.additionalParams,
			toBeMergedInto:
				category === 'holidays' ? selected?.items?.[0]?.items : selected?.items,
			filtered: category === 'holidays',
		});

		// NOTE: we merge all parameters on passenger detail page continue (errata is filtered out on all other merges)
		const newState = {
			bookingDetails,
			passengerDetails,
			shouldBuildParams: true,
			usePeople: false,
			selected: {
				...state.selected,
				items:
					category === 'holidays'
						? [
								{
									...selected?.items?.[0],
									items: selectedItems,
								},
						  ]
						: selectedItems,
			},
			baggage: !hasChangedAge ? state?.baggage : undefined,
			optionPlusOptions: !hasChangedAge ? state?.optionPlusOptions : undefined,
			selectedSeats: !hasChangedAge ? state?.selectedSeats : undefined,
		};

		// remove pax info from stored preview as this will need to come from the new preview request
		if (hasChangedAge && state?.preview?.pax) {
			newState.preview = {
				...state.preview,
				pax: undefined,
			};
		}

		// reset holiday flow flight warning indicator
		if (category === 'holidays') {
			newState.showFlightsChangedWarning = false;
		}

		setBookingState(category, newState, 'PASSANGER_WITH_MERGE');

		// reset baggage search results
		if (hasChangedAge) {
			removeQueries(['baggage', 'option-plus', 'seating']);
		}
	};

	return (
		<PassengerDetailsTemplate
			state={state}
			category={category}
			handleContinue={handleContinue}
			assistanceOptions={FLIGHT_SPECIAL_ASSISTANCE_OPTIONS}
		/>
	);
}
export default FlightsPassengers;

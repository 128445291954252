import React, { useState, useEffect, useMemo } from 'react';
import useDimensions from 'react-cool-dimensions';
import useWindowSize from '@/hooks/useWindowSize';
import { useBreakpoint } from '@/hooks';
import { useDebounce } from 'use-debounce';

/**
 * @name useVerticalOverflow Hook
 * @description A hook to determine if an element's height overflows the vertical viewport
 * @example
 * const { observe, isOverflowing } = useVerticalOverflow(true);
 */
function useVerticalOverflow(includeHeaderOffset = false) {
    const [isOverflowing, setIsOverflowing] = useState(null);
    const [windowWidth, windowHeight] = useWindowSize();

    const { observe, height } = useDimensions();
    const [debouncedElHeight] = useDebounce(height, 100);

    const isMd = useBreakpoint('md');
    const headerOffsetPixels = useMemo(() => {
        if (!includeHeaderOffset) return 0;

        // the header causes a 60px rem offset on mobile, 100px on desktop
        return isMd ? 60 : 100;
    }, [isMd]);

    useEffect(() => {
        const compared = debouncedElHeight >= (windowHeight - headerOffsetPixels);
        if (isOverflowing !== compared) {
            setIsOverflowing(debouncedElHeight >= (windowHeight - headerOffsetPixels));
        }
    }, [windowHeight, debouncedElHeight, headerOffsetPixels]);

    return {
        observe,
        isOverflowing,
    };
}

export default useVerticalOverflow;

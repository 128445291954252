import { useEffect, useMemo } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { useBookingStore } from '@/store';
import { cn } from '@/lib/utils';
import { getSteps } from '@/lib/steps';
import { HotelTravelPlanDetails } from '@/components/hotels';
import { CarhireTravelPlanDetails } from '@/components/carhire';
import { FlightTravelPlanDetails } from '@/components/flights';
import HolidayTravelPlanDetails from '@/components/holidays/organisms/travel-plan-details';
import {
	Button,
	Heading,
	PageWrapper,
	PaymentsIframe,
	BookingPageWrapper,
} from '@/components/common';
import { useBackNavigate, useMetaDescription } from '@/hooks';

function PaymentsPage() {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const params = useParams();
	const category = params?.category
		? params.category
		: pathname && pathname.split('/')[2];

	let metaKeyword = '';
	switch (category) {
		case 'flights':
			metaKeyword = 'Flights';
			break;

		case 'hotels':
			metaKeyword = 'Hotels';
			break;

		case 'car-hire':
			metaKeyword = 'Car Hire';
			break;

		case 'holidays':
			metaKeyword = 'Holidays';
			break;
	}

	useMetaDescription(['Payment Details', metaKeyword, 'Canadian Affair']);

	const { setBookingState } = useBookingStore();
	const bookingState = useBookingStore((state) => state[category]);

	const pageSteps = useMemo(() => getSteps(category), [category]);

	const MobileTravelPlan = useMemo(() => {
		const TravelPlan = {
			flights: FlightTravelPlanDetails,
			hotels: HotelTravelPlanDetails,
			holidays: HolidayTravelPlanDetails,
			'car-hire': CarhireTravelPlanDetails,
		};

		if (!category) return () => null;
		if (!TravelPlan[category]) return () => null;
		return TravelPlan[category];
	}, [category]);

	const { previousStep, handleBack } = useBackNavigate(pageSteps);

	// navigate back to previous step if reaching page without booking info, or fetching a payment link
	useEffect(() => {
		if (bookingState?.isLoading || bookingState?.error) return;

		if (!bookingState?.paymentDetails) handleBack();
	}, [
		bookingState?.error,
		bookingState?.paymentDetails,
		bookingState?.isLoading,
	]);

	return (
		<BookingPageWrapper
			hideTravelPlan
			title="Payment"
			steps={pageSteps}
			category={category}
			previousPage={previousStep}
			disablePreviewFetch={true}
			disableBreadcrumb={bookingState?.isLoading}
			disableBackBtn={bookingState?.isLoading}
		>
			<div className="w-full p-5 mb-5 bg-white md:hidden">
				<Heading as="h3" className="mb-4 text-2xl font-bold font-body">
					My Travel Plan
				</Heading>
				<MobileTravelPlan />
			</div>
			<PageWrapper
				error={bookingState?.error}
				loading={bookingState?.isLoading}
				loaderClassName="h-96 border border-lighter-grey"
				errorClassName="border border-lighter-grey"
			>
				<div className="flex flex-col w-full max-w-5xl gap-5">
					{bookingState?.paymentDetails ? (
						<PaymentsIframe
							title={`${category} payment`}
							link={bookingState?.paymentDetails?.paymentLink}
							className={cn('min-h-[40vh] aspect-w-video', {
								'min-h-[70vh]': bookingState?.paymentDetails?.paymentLink,
							})}
						/>
					) : null}

					<div className="flex flex-col items-start justify-between gap-2 lg:flex-row lg:items-center">
						<Button
							variant="unstyled"
							iconName="arrow-left"
							className="flex-row-reverse"
							label={`Back to ${previousStep?.name}`}
							labelClassName="font-normal"
							disableAnimation
							onClick={handleBack}
						/>
					</div>
				</div>
			</PageWrapper>
		</BookingPageWrapper>
	);
}
export default PaymentsPage;

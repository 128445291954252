import { useBookingStore } from '@/store';

/**
 * @typedef {Object} flightErrata
 * @property {Array | null} outErrata
 * @property {Array | null} inErrata
 */

/**
 * @name flightErrata
 * @param {Object} state
 * @param {Array} additionalParams
 * @returns {flightErrata} The errata data
 */

function flightErrata(state, additionalParams) {
	let outErrata = [];
	let inErrata = [];

	if (!additionalParams) return { outErrata, inErrata };

	let items = additionalParams;
	const pax = state?.selected?.bookingDetails?.pax;

	// get the already merged items
	if (pax && pax?.length > 0) {
		items = state?.selected?.items;
	}

	items?.forEach((item) => {
		if (item?.outbound?.errata) {
			outErrata = item?.outbound?.errata;
		}
		if (item?.inbound?.errata) {
			inErrata = item?.inbound?.errata;
		}
	});

	return { outErrata, inErrata };
}

/**
 * @name hotelErrataData
 * @param {Array} additionalParams
 * @returns {Array} The errata data
 */

function hotelErrataData(additionalParams) {
	let hotelErrata = [];

	additionalParams?.forEach((item) => {
		if (!item?.errata?.length) return;

		hotelErrata = [...hotelErrata, ...item.errata];
	});

	return hotelErrata;
}

/**
 * @name holidayFlightErrata
 * @param {Object} state
 * @param {Array} additionalParams
 * @returns {flightErrata} The errata data
 */

function holidayFlightErrata(additionalParams) {
	let outErrata = [];
	let inErrata = [];

	if (!additionalParams) return { outErrata, inErrata };

	additionalParams?.forEach((item) => {
		if (item?.outbound?.errata) {
			outErrata = item?.outbound?.errata;
		}
		if (item?.inbound?.errata) {
			inErrata = item?.inbound?.errata;
		}
	});

	return { outErrata, inErrata };
}

/**
 * @name useErrata
 * @description Get the errata for the selected category
 * @param {"flights" | "hotels"} category The category of the errata
 * @returns {flightErrata | hotelErrataData} The errata data
 * @example
 * import { useErrata } from '@/hooks';
 *
 * const { outErrata, inErrata } = useErrata({ category: 'flights' });
 * const { hotelErrata } = useErrata({ category: 'hotels' });
 */

function useErrata(category) {
	const state = useBookingStore((store) => store[category]);
	const additionalParams = state?.preview?.additionalParams;

	switch (category) {
		case 'flights':
			return flightErrata(state, additionalParams);

		case 'hotels':
			return hotelErrataData(additionalParams);

		case 'holidays':
			const hotel = additionalParams.filter(
				(params) => params?.type === 'accommodation'
			);
			const flights = additionalParams.filter(
				(params) => params?.type === 'flight'
			);

			const hotelErrata = hotelErrataData(hotel);
			const flightsErrata = holidayFlightErrata(flights);

			return { hotelErrata: hotelErrata, ...hotelErrata, ...flightsErrata };
		default:
			return null;
	}
}
export default useErrata;

import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
	Text,
	Button,
	Heading,
	FormField,
	// ContactForm,
	// DriverDetailsForm,
	BookingPageWrapper,
	// PreferredContactForm,
	ToggleButtonGroup,
} from '@/components/common';
import ContactForm from '@/components/common/forms/ContactForm';
import DriverDetailsForm from '@/components/common/forms/DriverDetailsForm';
import PreferredContactForm from '@/components/common/forms/PreferredContactForm';
import { useBookingStore } from '@/store';
import { formatYmd, getNextStep } from '@/lib/utils';
import { driverDetailsSchema } from '@/validationSchemas';
import { getSteps } from '@/lib/steps';
import { useBackNavigate, useMetaDescription } from '@/hooks';

/**
 * @typedef {Object} DriverDetailsProps
 */

/**
 * @name DriverDetails
 * @description Renders the DriverDetails page with the driver details form
 * @param {DriverDetailsProps} props Props containing the steps and the category
 * @returns  {React.JSX.Element}
 * @example
 * <DriverDetails steps={steps} />
 */

function DriverDetails() {
	useMetaDescription(['Driver Details', 'Car Hire', 'Canadian Affair']);
	const params = useParams();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { setBookingState, submitBooking } = useBookingStore();
	const category = params?.category ? params?.category : pathname.split('/')[2];

	const bookingState = useBookingStore((state) => state[category]);

	const questions = bookingState?.questions || [];

	const [answers, setAnswers] = useState(() => {
		if (!questions?.length) return [];

		if (bookingState?.bookingDetails?.questions?.length) {
			return bookingState?.bookingDetails?.questions;
		}

		return questions.map((question) => ({
			id: question?.id,
			question: question?.question,
			optional: question?.optional,
			answer: {
				id: question?.answers[0]?.id,
				answer: question?.answers[0]?.answer,
			},
		}));
	});

	// get session data if present else return default values
	const defaultValues = useMemo(() => {
		if (!bookingState?.driverDetails) return defaultState;
		return bookingState?.driverDetails;
	}, [bookingState?.driverDetails]);

	const methods = useForm({
		defaultValues,
		mode: 'onBlur',
		resolver: yupResolver(driverDetailsSchema),
	});

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = methods;

	const pageSteps = useMemo(() => getSteps(category), [category]);

	const onSubmit = (values) => {
		if (!values) return;

		const dob = formatYmd({
			day: values?.driver?.dob?.day,
			month: values?.driver?.dob?.month,
			year: values?.driver?.dob?.year,
		});
		const title = values?.driver?.title?.value;
		const numberOfPassengers = values?.driver?.number_of_passengers?.value;
		const gender = values?.driver?.gender?.value;

		// get driver details
		const paxDetails = {
			...values?.driver,
			ref: 1, // lead driver is the only passenger (ref #1)
			dob,
			title,
			numberOfPassengers,
			gender,
		};
		// structure our booking data
		const bookingDetails = {
			pax: [paxDetails],
			questions: answers,
			contactDetails: {
				email: values?.contact?.email,
				phone: values?.contact?.phone,
				address1: values?.contact?.address?.address1,
				address2: values?.contact?.address?.address2,
				county: values?.contact?.address?.county,
				postcode: values?.contact?.address?.postcode,
				town: values?.contact?.address?.town,
			},
			marketing: {
				mail: 'false',
				tel: values?.marketing?.phone === 'yes' ? 'true' : 'false',
				email: values?.marketing?.email === 'yes' ? 'true' : 'false',
			},
		};

		setBookingState(category, {
			shouldBuildParams: true,
			driverDetails: values,
			bookingDetails,
			paymentDetails: undefined, // clear previous payment details
		});

		// submit booking data
		submitBooking(category);

		// get next step
		const nextStep = getNextStep(pageSteps, pathname);

		// update booking state with data
		navigate(`${nextStep.to}`);
	};

	const { handleBack, previousStep } = useBackNavigate(pageSteps);

	return (
		<BookingPageWrapper
			steps={pageSteps}
			category={category}
			title="Driver Details"
			previousPage={previousStep}
			onContinue={handleSubmit(onSubmit)}
			continueIsDisabled={
				bookingState?.previewLoading ||
				bookingState?.isLoading ||
				Object.keys(errors).length > 0
			}
			renderFooterContent={() => (
				<FooterContent
					control={control}
					handleBack={handleBack}
					backLabel={previousStep?.name}
					handleSubmit={handleSubmit(onSubmit)}
					isDisabled={
						bookingState?.previewLoading ||
						bookingState?.isLoading ||
						Object.keys(errors).length > 0
					}
				/>
			)}
		>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)} className="px-0 md:px-0">
					<div className="flex flex-col max-w-4xl gap-6">
						<div className="p-8 border border-dark-grey">
							<Text className="my-2 font-bold">Lead Driver</Text>
							<Text className="my-2 text-dark-grey/70">
								It is imperative that your car hire booking is booked under the
								name of the lead (main) driver.
							</Text>
							<Text variant="error" className="font-bold">
								Please note: Canadian and US driving licenses are not accepted,
								except for any additional drivers* (Further information below.)
							</Text>
						</div>
						<div>
							<Heading as="h2" className="my-3 text-3xl font-bold font-body">
								Car Hire Collection
							</Heading>
							<Text className="text-dark-grey/65">
								The named lead driver must present the following three pieces of
								information at time of car hire collection (failure to do so
								will result in you being unable to collect your car hire and
								full loss of monies paid):
							</Text>
							<ol className="mt-3 ml-5 list-decimal">
								<li className="text-dark-grey/65">
									Car voucher - provided at time of booking confirmation.
								</li>
								<li className="text-dark-grey/65">
									Valid credit card in the lead driver's name - must have enough
									credit limit to cover the security deposit.
								</li>
								<li className="text-dark-grey/65">
									A valid driver's license - All driving licenses must be
									written in English or French. If not, you will need an
									International Driver's Permit. Please supply both the original
									and the International Driver's Permit together. Canadian and
									US licenses are not accepted except for any additional
									drivers*.
									<br />
									Your licence must include:
									<ol className="list-[lower-alpha] ml-5">
										<li>The driver(s) details.</li>
										<li>The class of vehicle you are authorized to drive.</li>
										<li>Any restrictions imposed.</li>
									</ol>
								</li>
							</ol>
						</div>
						<div>
							<Heading as="h2" className="my-3 text-3xl font-bold font-body">
								Additional Drivers
							</Heading>
							<Text className="text-dark-grey/65">
								Any additional drivers will also be required to present a valid
								driver&apos;s license and credit card (if the additional driver
								is a spouse or common law partner living at the same address
								then a credit card is not required). Failure to do so will
								result in the supplier no longer being liable to provide this
								service.
							</Text>
						</div>
						<div>
							<Heading as="h2" className="my-3 text-3xl font-bold font-body">
								One-Way Fees
							</Heading>
							<Text className="my-3 text-dark-grey/65">
								If your drop off location is different to the pickup location
								you may be required to pay a one-way fee, this is payable
								locally in $CAD.
							</Text>
							<Text className="font-bold text-dark-grey/65">
								If your one-way fee is showing as $99999.00CAD this may mean
								this one-way route is not allowed by Avis. In the event it is
								allowed we will contact you to advise the correct one-way fee.
							</Text>
						</div>
						<div className="p-8 border border-dark-grey">
							<Text className="my-2 font-bold">Please Note:</Text>
							<Text className="text-dark-grey/70">
								<strong className="text-darker-grey">
									Debit cards will not be accepted
								</strong>
								. A refundable security deposit will be held by the supplier,
								which is payable by credit card only, until the safe return of
								the vehicle. Please ensure you have available credit for this
								holding fee.
							</Text>
							<Text className="my-2 text-dark-grey/70">
								<strong className="text-darker-grey">
									Drivers under the age of 25 years old{' '}
								</strong>{' '}
								must call
								<a href="tel:02076169184" className="font-bold">
									{' '}
									020 7616 9184
								</a>{' '}
								to speak with one of our expert team to assist you with your
								booking.
							</Text>
							<Text variant="error" className="my-2 font-bold">
								Your car hire is on request until you receive the Avis car
								voucher from us.
							</Text>
							<Text className="text-dark-grey/70">
								When you make your booking with us you will receive an automatic
								confirmation, but we still need to verify the availability of
								your car hire. This may mean you receive the final confirmation
								and car voucher between 1-3 days after your booking. In the rare
								instance there is no availability for your selected vehicle we
								will either promptly issue a full refund or discuss alternative
								options with you.
							</Text>
						</div>

						<div className="flex flex-col max-w-3xl gap-5">
							<div className="flex flex-col gap-5">
								<Heading as="h2" className="my-4 text-3xl font-bold font-body">
									Lead Driver Details
								</Heading>
								<DriverDetailsForm />
							</div>
							<div>
								<Heading as="h2" className="my-4 text-3xl font-bold font-body">
									Contact Details
								</Heading>
								<ContactForm />
							</div>

							<PreferredContactForm />

							{Array.isArray(questions) && questions.length > 0 && (
								<div className="w-full md:w-1/2">
									<Heading as="h3" className="my-4 text-xl font-bold font-body">
										Special offers
									</Heading>
									<div className="flex flex-col gap-3">
										{questions.map((question) => (
											<div key={question?.id} className="flex flex-col gap-2">
												<Text as="label">{question?.question}?</Text>
												<ToggleButtonGroup
													key={question?.id}
													instance="formField"
													value={
														answers.find(
															(answer) => answer?.id === question?.id
														)?.answer?.id
													}
													onChange={(value) => {
														// find the question and update the answer and answer id
														if (!value || value === '') return;

														const questionIndex = answers.findIndex(
															(answer) => answer?.id === question?.id
														);
														const newAnswers = [...answers];

														newAnswers[questionIndex].answer.id = value;
														newAnswers[questionIndex].answer.answer =
															question?.answers.find(
																(answer) => answer?.id === value
															)?.answer;

														setAnswers(newAnswers);
													}}
													label={`${question?.question}?`}
													options={question?.answers?.map((answer) => ({
														label: answer?.answer,
														value: answer?.id,
													}))}
												/>
											</div>
										))}
									</div>
								</div>
							)}
							<Text className="mt-4 text-dark-grey/65">
								By providing the details below, we will be able to make your
								emails more personalised and relevant to you. Rest assured that
								your privacy is important to us and your details will not be
								shared with any 3rd party marketing companies. For more
								information, please read our{' '}
								<a
									className="underline underline-offset-4"
									href="/travel-centre/booking-with-confidence/privacy-policy"
									target="_blank"
								>
									Privacy Policy
								</a>
								.
							</Text>
						</div>
					</div>
				</form>
			</FormProvider>
		</BookingPageWrapper>
	);
}

const defaultState = {
	driver: {
		title: null,
		firstName: '',
		middleName: '',
		lastName: '',
		gender: null,
		dob: {
			day: null,
			month: null,
			year: null,
		},
	},
	contact: {
		phone: '',
		email: '',
		address: {
			address1: '',
			address2: '',
			town: '',
			county: '',
			postcode: '',
		},
	},
	marketing: {
		email: 'no',
		phone: 'no',
	},
	terms_conditions: false,
	read_information: false,
};

function FooterContent({
	control,
	backLabel,
	isDisabled,
	handleBack,
	handleSubmit,
}) {
	const { errors } = useFormState({ control });
	return (
		<div className="w-full py-10 space-y-5 lg:max-w-4xl">
			<Heading as="h3" className="text-2xl font-bold font-body">
				Important Information
			</Heading>

			<Text className="my-4 text-dark-grey/65">
				All information collected is intended for Canadian Affair, the data
				controller, for the purposes of processing your bookings or
				subscriptions to our newsletters and/or programs. It may also be used
				for the conduct of marketing and statistical studies, to customise and
				continuously improve your customer experience, to provide you with the
				most suitable offers and to send you targeted advertising. If you have
				consented, it may be used to send you promotional offers. Some of this
				information may also be communicated to third parties, namely to our
				subcontractors, who may be located outside the European Union. In
				accordance with applicable regulation, you have various rights regarding
				the use and protection of your personal information. When the basis for
				processing is your consent, you may withdraw that consent at any time.
				To learn more about your rights, the use and protection of your personal
				information, please refer to our{' '}
				<a
					className="underline underline-offset-4"
					href="/travel-centre/booking-with-confidence/privacy-policy"
					target="_blank"
				>
					Privacy Policy
				</a>
				. If you wish to exercise one of these rights or contact our Data
				Protection Officer, please{' '}
				<a
					className="underline underline-offset-4"
					href="/about-us/contact-us"
				>
					Contact Us
				</a>
				.
			</Text>
			<div className="flex flex-col gap-3 my-4 lg:gap-4">
				{errors?.terms_conditions?.message ||
				errors?.read_information?.message ? (
					<Text as="p" className="text-core-red">
						* Please confirm that you have read the information above and the
						terms & conditions
					</Text>
				) : null}
				<div className="flex items-start justify-start gap-2 lg:items-center">
					<FormField
						as="checkbox"
						control={control}
						id="terms_conditions"
						name="terms_conditions"
						wrapperClassName="w-fit mt-1"
					/>
					<Text as="label" htmlFor="terms_conditions">
						Please check this box to confirm that you have read and accepted the{' '}
						<a
							className="underline underline-offset-4"
							href="/travel-centre/terms-and-conditions"
							target="_blank"
							rel="noreferrer noopener"
						>
							Canadian Affair terms & conditions
						</a>{' '}
						and{' '}
						<a
							className="underline underline-offset-4"
							href="/travel-centre/terms-and-conditions/car-hire"
							target="_blank"
							rel="noreferrer noopener"
						>
							Avis terms and conditions
						</a>
					</Text>
				</div>
				<div className="flex items-start justify-start gap-2 lg:items-center">
					<FormField
						as="checkbox"
						control={control}
						id="read_information"
						name="read_information"
						wrapperClassName="w-fit mt-1"
					/>
					<Text as="label" htmlFor="read_information">
						Please confirm that you have read the important information above.
					</Text>
				</div>
			</div>
			<div className="flex flex-col items-start justify-between gap-2 lg:flex-row lg:items-center lg:mt-5">
				<Button
					type="submit"
					label="Agree & Continue"
					onClick={handleSubmit}
					variant="supporting-yellow"
					disabled={isDisabled}
					className="justify-between hidden md:flex w-fit"
				/>
				<Button
					type="button"
					disableAnimation
					variant="unstyled"
					onClick={handleBack}
					iconName="arrow-left"
					className="flex-row-reverse"
					label={`Back to ${backLabel}`}
					labelClassName="font-normal"
				/>
			</div>
		</div>
	);
}

FooterContent.propTypes = {
	errors: PropTypes.object,
	control: PropTypes.object,
	backLabel: PropTypes.string,
	handleBack: PropTypes.func,
	handleSubmit: PropTypes.func,
};

export default DriverDetails;

import React from 'react';
import { addDays, addMonths, format } from 'date-fns';

import { withPortal } from '@/HOCs/withPortal';
import { HotelsList, HotelsMap } from '@/components/hotels';
import { cn, buildFacetFilters, SORT_OPTIONS, PAGE_OPTIONS } from '@/lib/utils';
import { useMetaDescription } from '@/hooks';
import { SearchResultsPage, SearchWidget } from '@/components/common';
import useVerticalOverflow from '@/hooks/useVerticalOverflow';

/**
 * @name HotelSearch Page
 * @description Page to display hotel search results
 * @returns {React.JSX.Element} HotelSearch
 */
function HotelSearch({ portalData }) {
	const { observe, isOverflowing } = useVerticalOverflow(true);

	if (!portalData?.location) {
		useMetaDescription(['Search Results', 'Hotels', 'Canadian Affair']);
	}

	return (
		<>
			{!portalData?.location && (
				<SearchWidget
					ref={observe}
					category="hotels"
					className={cn(
						portalData?.withSiteHeader
							? 'fixed z-50 w-full top-15 md:top-25 drop-shadow-3xl max-h-[calc(100vh-3.75rem)] md:max-h-[calc(100vh-6.25rem)]'
							: 'max-h-screen',
						isOverflowing ? 'overflow-y-auto' : ''
					)}
				/>
			)}
			<SearchResultsPage
				paramsKey="hotel_filters"
				queryKey="hotel-search"
				sortOptions={SORT_OPTIONS}
				pageOptions={PAGE_OPTIONS}
				pageTitle={!portalData?.location ? 'Hotel Search Results' : null}
				filterLabel="Filter Hotels By"
				buildSearchParams={(params) => {
					// use default parameters for location hotel landing pages
					if (portalData?.location) {
						const startDate = addMonths(new Date(), 1);

						return {
							startDate: format(startDate, 'yyyy-MM-dd'),
							endDate: format(addDays(startDate, 3), 'yyyy-MM-dd'),
							location: portalData.location,
							rooms: [{ adults: 2, children: 0, infants: 0 }],
						};
					}

					// parse search params from query string
					const { location, startDate, endDate, rooms, who } = params.default;

					let formattedRooms = null;
					if (rooms?.length) {
						formattedRooms = rooms.map((room) => {
							const acc = {
								adults: 0,
								children: 0,
								infants: 0,
							};

							Object.keys(room).map((type) => {
								const value = room[type];
								acc[type] = parseInt(value);
							}, {});
							return acc;
						});
					}

					return {
						startDate,
						endDate,
						location:
							location && typeof location === 'string'
								? location.split(':')[0]
								: '',
						rooms: formattedRooms,
					};
				}}
				buildFilters={(distribution, icons, stats) => {
					const customFilters = {
						price: {},
					};

					// represent price as a budget range filter
					if (
						typeof stats?.price?.min !== 'undefined' &&
						typeof stats?.price?.max !== 'undefined' &&
						stats?.price?.max > 0
					)
						customFilters.price = {
							type: 'budget',
							min: 0,
							max: parseFloat(stats.price.max),
							name: 'budget',
							title: 'Budget',
							defaultToZero: true, // default to zero when resetting (as we don't know the future max price value)
						};

					return buildFacetFilters({
						distribution: distribution,
						icons: icons,
						customFilters: customFilters,
					});
				}}
				MapResultsComponent={HotelsMap}
				ListResultsComponent={HotelsList}
				className={portalData?.withSiteHeader && '!pt-18'}
				resultsContainerClassName="lg:flex lg:items-start lg:relative"
				filterClassName={cn(
					'lg:sticky lg:w-1/4 lg:top-25',
					portalData?.withSiteHeader && 'lg:top-50.5'
				)}
			/>
		</>
	);
}

// portaled to booking-hotels-search-page
export default withPortal(HotelSearch, 'booking-hotels-search-page');

import * as yup from 'yup';

export const holidayBookingSchema = yup.object().shape({
    duration: yup
        .object().nullable()
        .shape({
            value: yup.string().required('Required'),
            label: yup.string(),
        })
        .required('Required'),

    when: yup.object().shape({
        from: yup.date().required('Please select a check-in date'),
    }),
    rooms: yup.array().of(
        yup.object().shape({
            room: yup
                .object()
                .shape({
                    label: yup.string(),
                    value: yup.string(),
                })
                .required('Please select a room'),
            adults: yup
                .object()
                .shape({
                    label: yup.string(),
                    value: yup
                        .number()
                        .typeError('Adults should be a number')
                        .min(1, 'At least 1 adult is required.')
                        .max(9, 'Only 9 adults allowed'),
                })
                .required('Please select the number of adults'),
            children: yup
                .object()
                .shape({
                    label: yup.string(),
                    value: yup
                        .number()
                        .typeError('Children should be a number')
                        .min(0, 'At least 0 children are required.')
                        .max(9, 'Only 9 children allowed'),
                })
                .nullable(),
            infants: yup
                .object()
                .shape({
                    label: yup.string(),
                    value: yup
                        .number()
                        .typeError('Infants should be a number')
                        .min(0, 'At least 0 infants are required.')
                        .max(9, 'Only 9 infants allowed'),
                })
                .nullable(),
        })
    ),
});

import PropTypes from 'prop-types';
import { useMemo, useRef } from 'react';

import BlockLayout from '@components/holidays/molecules/block-layout';
import HotelSummaryCard from '@/components/hotels/organisms/summary-card';
import useDisclosure from '@/hooks/useDisclosure';
import { Dialog, Badge, Button, Heading } from '@/components/common';
import HotelOptionList from '@/components/holidays/molecules/hotel-option-list';
import RoomOptionsList from '@/components/holidays/molecules/room-option-list';
import { currencyFormatter } from '@/lib/utils/currency';
import useBookingStore from '@/store/useBookingStore';
import useBreakpoint from '@/hooks/useBreakPoint';
import pluralize from 'pluralize';

/**
 * @typedef {Object} HotelBlockProps
 * @property {string} category
 * @property {number|string} holidayIdx
 * @property {number|string} itemParamIdx
 * @property {Object} hotel
 * @property {Object} hotelOptions
 * @property {string} title
 * @property {() => React.ReactNode} renderStep
 * @property {(itemParamIdx, newItem, label, showLoader, onUpdatedState) => void} updateBookingState
 * @property {boolean} isChangeDisabled
 * @property {boolean} loading
 */

/**
 * @name HotelBlock
 * @description Renders a travel plan block with the hotel details
 * @param {HotelBlockProps} props
 * @returns
 */
function HotelBlock({
	category,
	holidayIdx,
	itemParamIdx,
	hotel,
	hotelOptions,
	title,
	headerTitle,
	renderStep,
	updateBookingState,
	isChangeDisabled,
	loading,
}) {
	const containerRef = useRef(null);
	const isSmallDevice = useBreakpoint('md');
	const state = useBookingStore((store) => store[category]);
	const {
		isOpen: isChangeHotelOpen,
		onOpen: onChangeHotelOpen,
		onClose: onChangeHotelClose,
	} = useDisclosure(false);
	const {
		isOpen: isChangeRoomOpen,
		onOpen: onChangeRoomOpen,
		onClose: onChangeRoomClose,
	} = useDisclosure(false);
	const { all: altHotels, cheapest } = hotelOptions;

	const nextHotelUpgrade = useMemo(
		() => altHotels?.find((altHotel) => altHotel?.isNextUpgrade === true),
		[altHotels]
	);

	const closeUpgradePanels = () => {
		// close any open upgrade panels
		if (isChangeHotelOpen) onChangeHotelClose();
		if (isChangeRoomOpen) onChangeRoomClose();
	};

	const handleHotelChange = (newHotel) => {
		if (!newHotel?.bookingItem) return;

		// do not update params if selecting the current hotel
		if (newHotel?.code === hotel?.code) {
			onChangeHotelClose();
			return;
		}

		// update booking state
		const newItem = {
			type: 'accommodation',
			...newHotel.bookingItem,
		};
		updateBookingState(itemParamIdx, newItem, 'SET_HOTEL_UPGRADE', true, () => {
			closeUpgradePanels();
		});
	};

	const handleRoomChange = (newRooms) => {
		if (!state?.selected?.items?.[holidayIdx]?.items?.[itemParamIdx]) return;

		// do not update params if selecting the same rooms
		const newRoomParams = newRooms?.map((room) => ({
			roomCode: room?.roomCode,
			name: room?.name,
			adults: room?.adults,
			children: room?.children,
			infants: room?.infants,
		}));

		// do not update params if selecting the same room options
		const isSameSelection = hotel?.rooms?.every(
			(room, idx) => room?.roomCode === newRoomParams?.[idx]?.roomCode
		);
		if (isSameSelection) {
			onChangeRoomClose();
			return;
		}

		// update booking state
		const newItem = JSON.parse(
			JSON.stringify(state.selected.items[holidayIdx].items[itemParamIdx])
		);
		newItem.rooms = newRoomParams;
		updateBookingState(itemParamIdx, newItem, 'SET_ROOM_UPGRADE', false, () => {
			closeUpgradePanels();
		});
	};

	const mobileRoomChangeDialogComponent = (
		<Dialog
			as="drawer"
			isDialogOpen={isChangeRoomOpen}
			hideCloseBtn
			position="bottom"
			contentClassName="w-full min-h-[100dvh] max-h-[100dvh] overflow-y-auto p-0"
			renderTrigger={({ DialogTrigger, onOpen }) => (
				<DialogTrigger asChild className="p-4">
					<Button
						variant="unstyled"
						className="inline text-base font-bold hover:opacity-75 leading-[1.3] tracking-less-tight underline underline-offset-4 p-0"
						disabled={isChangeDisabled}
						onClick={() => {
							onOpen();
							if (isChangeHotelOpen) onChangeHotelClose();
							onChangeRoomOpen();
						}}
					>
						{`(change)`}
					</Button>
				</DialogTrigger>
			)}
			onEscapeKeyDown={() => {
				closeUpgradePanels();
			}}
			onInteractOutside={() => {
				closeUpgradePanels();
			}}
		>
			{({ CloseButton, onClose }) => (
				<div>
					<div className="sticky top-0 left-0 z-10 flex items-center justify-between p-5 py-3 bg-white">
						<Heading as="h3" className="text-3xl font-body">
							Select your room
						</Heading>
						<CloseButton
							className="relative top-0 right-0 w-12 h-12 border-none"
							onClick={() => {
								closeUpgradePanels();
							}}
						/>
					</div>
					<RoomOptionsList
						hotel={hotel}
						handleClose={onChangeRoomClose}
						handleChange={(updatedSelection) => {
							handleRoomChange(updatedSelection);
							onClose();
						}}
						disabled={isChangeDisabled}
						scrollToContainer={scrollToContainer}
					/>
				</div>
			)}
		</Dialog>
	);

	const mobilehotelChangeDialogComponent = (
		<Dialog
			as="drawer"
			isDialogOpen={isChangeHotelOpen}
			hideCloseBtn
			position="bottom"
			contentClassName="w-full min-h-[100dvh] max-h-[100dvh] overflow-y-auto p-0"
			renderTrigger={({ DialogTrigger, onOpen }) => (
				<DialogTrigger asChild className="p-4">
					<Button
						label={`${altHotels.length - 1} other ${pluralize(
							'hotel',
							altHotels.length - 1
						)} available${
							nextHotelUpgrade
								? ` from ${
										(nextHotelUpgrade?.priceDiffPerPersonPerNight || 0) >= 0
											? '+'
											: ''
								  }${currencyFormatter({
										amount: nextHotelUpgrade?.priceDiffPerPersonPerNight || 0,
								  })}*`
								: ''
						}`}
						variant="unstyled"
						className="px-0 pt-4 pb-0 text-base leading-snug tracking-tighter underline underline-offset-4"
						hideIcon
						disabled={isChangeDisabled}
						onClick={() => {
							onOpen();
							if (isChangeRoomOpen) onChangeRoomClose();
							onChangeHotelOpen();
						}}
					/>
				</DialogTrigger>
			)}
			onEscapeKeyDown={() => {
				closeUpgradePanels();
			}}
			onInteractOutside={() => {
				closeUpgradePanels();
			}}
		>
			{({ CloseButton, onClose }) => (
				<div>
					<div className="sticky top-0 left-0 z-10 flex items-center justify-between p-5 py-3 bg-white">
						<Heading as="h3" className="text-3xl font-body">
							Select your hotel
						</Heading>
						<CloseButton
							className="relative top-0 right-0 w-12 h-12 border-none"
							onClick={() => {
								closeUpgradePanels();
							}}
						/>
					</div>
					<HotelOptionList
						hotel={hotel}
						altHotels={altHotels}
						handleChange={handleHotelChange}
						disabled={isChangeDisabled}
						scrollToContainer={scrollToContainer}
					/>
				</div>
			)}
		</Dialog>
	);

	if (!hotel?.startDate) return null;

	const scrollToContainer = () => containerRef.current.scrollIntoView();

	return (
		<BlockLayout
			ref={containerRef}
			loading={loading && !state?.error}
			title={title}
			headerTitle={headerTitle}
			date={hotel.startDate}
			renderStep={renderStep}
			iconName="accommodation"
		>
			<HotelSummaryCard
				hotel={hotel}
				hideChange={
					!isSmallDevice
						? isChangeHotelOpen || isChangeRoomOpen
						: isChangeHotelOpen
				}
				disableChange={isChangeDisabled}
				onChangeRoomClick={() => {
					if (isChangeHotelOpen) onChangeHotelClose();
					onChangeRoomOpen();
				}}
				// badge={
				// 	hotel?.code && cheapest?.code && hotel?.code !== cheapest?.code ? (
				// 		<Badge text="Upgraded" variant="supporting-coral" />
				// 	) : null
				// }
				mobileRoomChangeDialogComponent={mobileRoomChangeDialogComponent}
			>
				{!isChangeRoomOpen &&
					altHotels?.length > 1 &&
					(isSmallDevice ? (
						mobilehotelChangeDialogComponent
					) : (
						<div className="mt-4 lg:mt-5">
							{!isChangeHotelOpen ? (
								<Button
									label={`${altHotels.length - 1} other ${pluralize(
										'hotel',
										altHotels.length - 1
									)} available${
										nextHotelUpgrade
											? ` from ${
													(nextHotelUpgrade?.priceDiffPerPersonPerNight || 0) >=
													0
														? '+'
														: ''
											  }${currencyFormatter({
													amount: nextHotelUpgrade?.priceDiffPerPersonPerNight,
											  })}*`
											: ''
									}`}
									variant="unstyled"
									className="underline underline-offset-4"
									hideIcon
									disabled={isChangeDisabled}
									onClick={() => {
										if (isChangeRoomOpen) onChangeRoomClose();

										onChangeHotelOpen();
									}}
								/>
							) : (
								<Button
									label="Cancel"
									variant="unstyled"
									hideIcon
									className="underline underline-offset-4"
									onClick={closeUpgradePanels}
								/>
							)}
						</div>
					))}
			</HotelSummaryCard>

			{!isSmallDevice && (isChangeHotelOpen || isChangeRoomOpen) && (
				<div className="py-3 bg-white border-t-4 border-core-blue md:py-5">
					<div className="flex flex-col w-full h-auto gap-2">
						{isChangeHotelOpen && (
							<HotelOptionList
								hotel={hotel}
								altHotels={altHotels}
								handleChange={handleHotelChange}
								disabled={isChangeDisabled}
								scrollToContainer={scrollToContainer}
							/>
						)}
						{isChangeRoomOpen && (
							<RoomOptionsList
								hotel={hotel}
								handleClose={onChangeRoomClose}
								handleChange={handleRoomChange}
								disabled={isChangeDisabled}
								scrollToContainer={scrollToContainer}
							/>
						)}
					</div>
				</div>
			)}
		</BlockLayout>
	);
}

HotelBlock.propTypes = {
	category: PropTypes.string,
	holidayIdx: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
		.isRequired,
	itemParamIdx: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
		.isRequired,
	hotel: PropTypes.object,
	hotelOptions: PropTypes.shape({
		all: PropTypes.array,
		cheapest: PropTypes.object,
	}),
	title: PropTypes.string,
	renderStep: PropTypes.func,
	updateBookingState: PropTypes.func.isRequired,
	isChangeDisabled: PropTypes.bool,
	loading: PropTypes.bool,
};

HotelBlock.defaultProps = {
	hotel: {},
	hotelOptions: {
		all: [],
		cheapest: {},
	},
	title: 'Accommodation',
	isChangeDisabled: false,
	loading: false,
};

export default HotelBlock;

import PropTypes from 'prop-types';
import Heading from '@/components/common/atoms/heading';
import Text from '@/components/common/atoms/text';
import cn from '@/lib/utils/tailwind';
import VisaBookingNotice from '@/components/common/molecules/visa-booking-notice';

/**
 * @name HolidayBookingTravelDetails
 * @description A summary of the holiday travel notice details
 * @returns {React.JSX.Element}
 * @example
 * <HolidayBookingTravelDetails hasErrata={false} />
 * */

function HolidayBookingTravelDetails({ hasErrata = false }) {
    return (
        <>
            <div className="border-b border-lighter-grey">
                <Heading
                    as="h3"
                    className="text-base font-bold uppercase w-fit font-body"
                >
                    Travel Documents
                </Heading>
                <Text className="pt-2 pb-4 text-dark-grey/65">
                    You MUST present at check-in your Canadian Affair voucher and Flight E-Ticket. No other form of printed documentation will be accepted.
                </Text>
            </div>
            <VisaBookingNotice
                className="border-b border-lighter-grey"
            />
            <div
                className={cn(
                    hasErrata && 'border-b border-lighter-grey'
                )}
            >
                <Heading
                    as="h3"
                    className="text-base font-bold uppercase w-fit font-body"
                >
                    Checking in
                </Heading>
                <Text className="pt-2 text-dark-grey/65">
                    We recommend all passengers check-in 24 hours before
                    departure via the Air Transat website or app. If you are
                    unable to check-in online you can do so at the airport
                    up to 4 hours prior to departure.
                </Text>
                <Text
                    variant="bold"
                    className={cn(
                        'pt-2 pb-4 text-dark-grey',
                        !hasErrata && 'pb-0'
                    )}
                >
                    Check-in closes strictly 1 hour before departure there
                    are no exceptions.
                </Text>
            </div>
        </>
    );

};

HolidayBookingTravelDetails.propTypes = {
    hasErrata: PropTypes.bool,
}

export default HolidayBookingTravelDetails;

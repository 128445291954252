import qs from 'qs';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import {
	addDays,
	differenceInDays,
	format,
	isDate,
	isValid,
	startOfDay,
} from 'date-fns';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';

import {
	WhoForm,
	Checkbox,
	FormField,
	SearchFormButton,
	ToggleButtonGroup,
	DatePicker,
} from '@/components/common';
import {
	navigate,
	WHO_OPTIONS,
	FLIGHT_ONLY_AIRPORT_OPTIONS,
} from '@/lib/utils';
import { useSearchSave } from '@/hooks';
import { useBookingStore } from '@/store';
import { staticFlightSearchSchemaConfig } from '@/validationSchemas';
import useDateRestrictions from '@/hooks/useDateRestrictions';

function FlightSearchForm({ clear, params, hideClearBtn }) {
	const { getSearch } = useSearchSave();

	const category = 'flights';
	const savedSearch = getSearch(category);
	const { flightOnlyMaxDate } = useDateRestrictions();

	const { setBookingState } = useBookingStore();

	const defaultParams = savedSearch && !params ? qs.parse(savedSearch) : params;

	const [differentReturn, setDifferentReturn] = useState(
		defaultParams?.outboundArrive &&
			defaultParams?.inboundDepart &&
			defaultParams?.inboundArrive &&
			defaultParams?.outboundDepart &&
			(defaultParams?.inboundDepart !== defaultParams?.outboundArrive ||
				defaultParams?.inboundArrive !== defaultParams?.outboundDepart)
	);
	const [isOneWay, setIsOneWay] = useState(
		defaultParams?.outboundArrive &&
			defaultParams?.outboundDepart &&
			!(defaultParams?.inboundDepart || defaultParams?.inboundArrive)
	);

	const emptyParams = {
		outboundArrive: null,
		inboundDepart: null,
		outboundDepart: null,
		inboundArrive: null,
		who: WHO_OPTIONS.reduce((acc, option) => {
			const entry = { value: 0, label: '0' };
			acc[option.value] =
				option.value === 'adults' ? { value: 1, label: '1' } : entry;
			return acc;
		}, {}),

		when: {
			from: addDays(new Date(), 1),
			...(!isOneWay && { to: addDays(new Date(), 6) }),
		},
	};

	const defaultValues = useMemo(() => {
		if (!defaultParams) return emptyParams;
		const outboundArrive = defaultParams?.outboundArrive
			? defaultParams.outboundArrive.split(':')
			: [];
		const inboundDepart = defaultParams?.inboundDepart
			? defaultParams.inboundDepart.split(':')
			: [];
		const outboundDepart = defaultParams?.outboundDepart
			? defaultParams.outboundDepart.split(':')
			: [];
		const inboundArrive = defaultParams?.inboundArrive
			? defaultParams.inboundArrive.split(':')
			: [];

		const formatedWho = Object.keys(emptyParams?.who).reduce((acc, type) => {
			const value = defaultParams?.who?.[type];
			if (value) {
				const [val, label] = value.split(':');
				acc[type] = {
					value: parseInt(val),
					label,
				};
			} else {
				const entry = {
					value: 0,
					label: '0',
				};

				acc[type] = type === 'adult' ? { value: 1, label: '1' } : entry;
			}
			return acc;
		}, {});

		const who = defaultParams?.who ? formatedWho : emptyParams.who;

		const startDate =
			defaultParams?.startDate && new Date(defaultParams.startDate);
		const endDate = defaultParams?.endDate && new Date(defaultParams.endDate);

		const hasMaxDate = flightOnlyMaxDate && isValid(new Date(flightOnlyMaxDate));

		// only pre-fill if at least 1 day from today
		const fromDate =
			isDate(startDate) &&
			differenceInDays(startOfDay(startDate), startOfDay(new Date())) >= 1 &&
			(!hasMaxDate || differenceInDays(
				startOfDay(startDate),
				startOfDay(new Date(flightOnlyMaxDate))
			) <= 0)
				? startDate
				: addDays(new Date(), 1);

		// only pre-fill if after the from date
		const defaultEndDate = !hasMaxDate ||
			differenceInDays(
				addDays(fromDate, 6),
				startOfDay(new Date(flightOnlyMaxDate))
			) <= 0
				? addDays(fromDate, 6)
				: new Date(flightOnlyMaxDate);
		const toDate =
			isDate(endDate) &&
			differenceInDays(startOfDay(endDate), startOfDay(fromDate)) >= 0 &&
			(!hasMaxDate || differenceInDays(
				startOfDay(endDate),
				startOfDay(new Date(flightOnlyMaxDate))
			) <= 0)
				? endDate
				: defaultEndDate;

		return {
			outboundArrive: outboundArrive[0]
				? {
						value: outboundArrive[0],
						label: outboundArrive[1] || '',
				  }
				: null,
			inboundDepart: inboundDepart[0]
				? {
						value: inboundDepart[0],
						label: inboundDepart[1] || '',
				  }
				: null,
			outboundDepart: outboundDepart[0]
				? {
						value: outboundDepart[0],
						label: outboundDepart[1] || '',
				  }
				: null,
			inboundArrive: inboundArrive[0]
				? {
						value: inboundArrive[0],
						label: inboundArrive[1] || '',
				  }
				: null,
			who,
			when: {
				from: fromDate,
				...(!isOneWay && { to: toDate }),
			},
		};
	}, [defaultParams, emptyParams, flightOnlyMaxDate]);

	const flightSearchSchema = yup.object().shape({
		...staticFlightSearchSchemaConfig,
		inboundDepart: yup
			.object()
			.nullable()
			.when([], {
				is: () => differentReturn && !isOneWay,
				then: (schema) =>
					schema
						.nullable()
						.shape({
							value: yup.string().required('Required'),
							label: yup.string(),
						})
						.required('Required'),
				otherwise: (schema) =>
					schema.nullable().shape({
						value: yup.string(),
						label: yup.string(),
					}),
			}),
		inboundArrive: yup
			.object()
			.nullable()
			.when([], {
				is: () => differentReturn && !isOneWay,
				then: (schema) =>
					schema
						.nullable()
						.shape({
							value: yup.string().required('Required'),
							label: yup.string(),
						})
						.required('Required'),
				otherwise: (schema) =>
					schema.nullable().shape({
						value: yup.string(),
						label: yup.string(),
					}),
			}),
		when: yup.object().when([], {
			is: () => isOneWay,
			then: (schema) =>
				schema.shape({
					from: yup.date().required('Required'),
				}),
			otherwise: (schema) =>
				schema.shape({
					from: yup.date().required('Required'),
					to: yup.date().required('Required'),
				}),
		}),
	});

	const methods = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(flightSearchSchema),
	});

	const {
		handleSubmit,
		watch,
		setValue,
		control,
		clearErrors,
		getValues,
		formState: { errors },
	} = methods;

	const outboundArrive = watch('outboundArrive');
	const outboundDepart = watch('outboundDepart');

	const formStartDate = watch('when.from');
	const formEndDate = watch('when.to');
	useEffect(() => {
		if (!flightOnlyMaxDate) return;
		if (!formStartDate) return;
		if (!isOneWay && !formEndDate) return;

		// reset form value if not valid with max date restriction
		const maxDate = new Date(flightOnlyMaxDate);

		// one way fields
		if (isOneWay && differenceInDays(new Date(formStartDate), maxDate) > 0) {
			setValue('when.from', undefined);
			return;
		}

		// return flight fields
		if (
			differenceInDays(new Date(formStartDate), maxDate) > 0 ||
			differenceInDays(new Date(formEndDate), maxDate) > 0
		) {
			setValue('when', {});
			return;
		}
	}, [flightOnlyMaxDate, formStartDate, formEndDate, isOneWay]);

	const onSubmit = async (data) => {
		const { who, when, outboundArrive, outboundDepart } = data;

		// default to outbound if an inbound isn't specified
		let inboundDepart = data.inboundDepart?.value
			? data.inboundDepart
			: outboundArrive;
		let inboundArrive = data.inboundArrive?.value
			? data.inboundArrive
			: outboundDepart;

		// remove inbound fields for one-way flights
		if (isOneWay) {
			inboundDepart = null;
			inboundArrive = null;
		}

		const formattedWho = Object.keys(who).reduce((acc, type) => {
			acc[type] = who[type]?.value
				? `${who[type].value}:${who[type].label}`
				: '';
			return acc;
		}, {});

		const queryString = qs.stringify({
			outboundArrive: `${outboundArrive.value}:${outboundArrive.label}`,
			inboundDepart: inboundDepart?.value
				? `${inboundDepart.value}:${inboundDepart.label}`
				: '',
			outboundDepart: `${outboundDepart.value}:${outboundDepart.label}`,
			inboundArrive: inboundArrive?.value
				? `${inboundArrive.value}:${inboundArrive.label}`
				: '',
			who: formattedWho,
			startDate: when?.from ? format(when.from, 'yyyy-MM-dd') : '',
			...(!isOneWay && {
				endDate: when?.to ? format(when.to, 'yyyy-MM-dd') : '',
			}),
			category: 'flights',
		});

		// if is one way set state to one way
		const newState = { isOneWay };
		setBookingState(category, newState, 'SET_ONEWAY');

		// navigate to the search page
		navigate(`/search/flights?${queryString}`);
	};

	const toggleDifferentReturn = (e) => {
		// reset inbound inputs
		const newInboundDep =
			differentReturn && outboundArrive?.value
				? {
						value: outboundArrive?.value,
						label: outboundArrive?.label,
				  }
				: null;
		setValue('inboundDepart', newInboundDep);

		const newInboundArr =
			differentReturn && outboundDepart?.value
				? {
						value: outboundDepart?.value,
						label: outboundDepart?.label,
				  }
				: null;
		setValue('inboundArrive', newInboundArr);

		setDifferentReturn(!differentReturn);

		// reset validation errors
		clearErrors();
	};

	const toggleOneWay = (value) => {
		// remove inbound values
		const valueIsOneWay = value === 'oneWay';
		if (valueIsOneWay) {
			setValue('inboundDepart', null);
			setValue('inboundArrive', null);

			// remove when.to value
			setValue('when.to', undefined);

			setDifferentReturn(false);
		}

		const fromDate = getValues('when.from');
		const hasMaxDate = flightOnlyMaxDate && isValid(new Date(flightOnlyMaxDate));

		// when switching back to return and the to date is empty, set it to 6 days from the from date
		if (!valueIsOneWay && !getValues('when.to') && isValid(fromDate)) {
			const fromDatePlusDays = addDays(fromDate, 6);

			// do not exceed max date
			const toDate = !hasMaxDate ||
				differenceInDays(
					fromDatePlusDays,
					startOfDay(new Date(flightOnlyMaxDate))
				) <= 0
					? fromDatePlusDays
					: new Date(flightOnlyMaxDate);

			setValue('when.to', toDate);
		}

		// if we have a saved search, set the when.to value to the saved value
		if (savedSearch && !valueIsOneWay) {
			const savedParams = qs.parse(savedSearch);

			// if the from date is the same as the saved end date, set the to date to 6 days from the from date else set it to the saved end date
			let newToDate = null;
			const isSameDate =
				savedParams?.endDate && isValid(fromDate)
					? format(new Date(fromDate), 'yyyy-MM-dd') === savedParams?.endDate
					: false;
			if (isSameDate || !savedParams?.endDate) {
				newToDate = isValid(fromDate) ? addDays(fromDate, 6) : null;
			} else {
				newToDate = savedParams?.endDate
					? new Date(savedParams?.endDate)
					: null;
			}

			// only pre-fill if valid date
			const isNewToDateValid =
				newToDate &&
				isValid(newToDate) &&
				differenceInDays(startOfDay(newToDate), startOfDay(fromDate)) >= 0 &&
				(!hasMaxDate || differenceInDays(
					startOfDay(newToDate),
					startOfDay(new Date(flightOnlyMaxDate))
				) <= 0);
			if (isNewToDateValid) {
				setValue('when.to', newToDate);
			}
		}

		setIsOneWay(valueIsOneWay ? true : false);

		// reset validation errors from one way/non-one way options
		clearErrors();
	};

	const getFlightOptions = (selectedValue, sameCountryAsSelected = false) => {
		let opts = FLIGHT_ONLY_AIRPORT_OPTIONS;
		if (selectedValue) {
			const countryCode = FLIGHT_ONLY_AIRPORT_OPTIONS.find(
				(opt) => opt?.value === selectedValue
			)?.country;

			if (countryCode) {
				opts = FLIGHT_ONLY_AIRPORT_OPTIONS.filter((opt) => {
					if (sameCountryAsSelected) {
						// only allow options with the same country as the selected option
						return opt?.country === countryCode;
					}

					// otherwise only allow different country to the selected option
					return opt?.country !== countryCode;
				});
			}
		}

		return opts.map((opt) => ({ label: opt?.label, value: opt?.value }));
	};

	const departingFromOptions = useMemo(
		() =>
			FLIGHT_ONLY_AIRPORT_OPTIONS.map((opt) => ({
				label: opt?.label,
				value: opt?.value,
			})),
		[FLIGHT_ONLY_AIRPORT_OPTIONS]
	);

	const goingToOptions = useMemo(
		() => getFlightOptions(outboundDepart?.value, false),
		[FLIGHT_ONLY_AIRPORT_OPTIONS, outboundDepart?.value]
	);

	const returningFromOptions = useMemo(
		() => getFlightOptions(outboundArrive?.value, true),
		[FLIGHT_ONLY_AIRPORT_OPTIONS, outboundArrive?.value]
	);

	const returningToOptions = useMemo(
		() => getFlightOptions(outboundDepart?.value, true),
		[FLIGHT_ONLY_AIRPORT_OPTIONS, outboundDepart?.value]
	);

	const handleOutboundDeselect = () => {
		// reset different return journey values
		if (differentReturn) {
			setValue('inboundDepart', null);
			setValue('inboundArrive', null);

			// reset errors
			clearErrors('inboundDepart');
			clearErrors('inboundArrive');
		}
	};

	const getSelectedCountry = (value) => {
		if (!value?.value) return undefined;

		return FLIGHT_ONLY_AIRPORT_OPTIONS.find(
			(opt) => opt?.value === value?.value
		)?.country;
	};

	return (
		<FormProvider {...methods}>
			<div className="flex flex-col gap-3 mb-5 lg:items-center lg:flex-row lg:gap-5">
				<ToggleButtonGroup
					onChange={toggleOneWay}
					itemClassName="w-full lg:w-auto"
					value={isOneWay ? 'oneWay' : 'return'}
					options={[
						{
							label: 'Return',
							value: 'return',
						},
						{
							label: 'One way',
							value: 'oneWay',
						},
					]}
				/>

				<fieldset
					disabled={isOneWay}
					className="flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
				>
					<Checkbox
						id="toggleOneWay"
						disabled={isOneWay}
						checked={differentReturn}
						onChange={toggleDifferentReturn}
					/>
					<label htmlFor="toggleOneWay">Different return journey?</label>
				</fieldset>
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="mx-auto flex flex-col xl:flex-row h-auto w-full items-center justify-center xl:items-start gap-2 md:gap-2.5 lg:gap-5"
			>
				<div className="flex flex-col flex-wrap md:flex-row w-full items-start justify-start gap-2 md:gap-2.5 lg:gap-5 lg:min-w-[270px]">
					<FormField
						name="outboundDepart"
						label="Departing from"
						as="select"
						control={control}
						errors={errors}
						placeholder="Select"
						wrapperClassName="md:flex-[1_0_45%]"
						options={departingFromOptions}
						allowDeselect
						onDeselect={handleOutboundDeselect}
						onChange={(field, value) => {
							// clear other arrival/departure fields if changing country
							const newCountry = getSelectedCountry(value);
							const oldCountry = getSelectedCountry(outboundDepart);
							if (newCountry !== oldCountry) {
								// clear fields
								setValue('outboundArrive', null);
								setValue('inboundDepart', null);
								setValue('inboundArrive', null);

								// reset errors
								clearErrors('outboundArrive');
								clearErrors('inboundDepart');
								clearErrors('inboundArrive');
							}

							if (!differentReturn) {
								// also set inbound arrival if same return journey
								setValue('inboundArrive', value);
							}

							// trigger field change
							field.onChange(value);
						}}
					/>

					<FormField
						name="outboundArrive"
						label="Going to"
						as="select"
						control={control}
						errors={errors}
						placeholder="Select"
						wrapperClassName="md:flex-[1_0_45%]"
						options={goingToOptions}
						disabled={!outboundDepart?.value}
						allowDeselect
						onDeselect={handleOutboundDeselect}
						onChange={(field, value) => {
							if (!differentReturn) {
								// also set inbound departure if same return journey
								setValue('inboundDepart', value);
							}

							field.onChange(value);
						}}
					/>

					{differentReturn && [
						<FormField
							key="inboundDepart"
							name="inboundDepart"
							label="Returning from"
							as="select"
							control={control}
							errors={errors}
							options={returningFromOptions}
							placeholder="Select"
							wrapperClassName="md:flex-[1_0_45%]"
							disabled={!(outboundDepart?.value && outboundArrive?.value)}
							allowDeselect
						/>,
						<FormField
							key="inboundArrive"
							name="inboundArrive"
							label="Returning to"
							as="select"
							control={control}
							errors={errors}
							options={returningToOptions}
							placeholder="Select"
							wrapperClassName="md:flex-[1_0_45%]"
							disabled={!(outboundDepart?.value && outboundArrive?.value)}
							allowDeselect
						/>,
					]}
				</div>

				<div className="grid grid-cols-1 w-full items-end md:items-start xl:items-end justify-start gap-2 md:grid-cols-2 md:gap-2.5 lg:gap-5 xl:flex 2xl:grid">
					<WhoForm name="who" control={control} />

					{isOneWay ? (
						<DatePicker
							name="when.from"
							label="When"
							selected={watch('when.from')}
							onChange={(val) => {
								setValue('when.from', val);
							}}
							wrapperClassName="h-auto xl:h-full w-full"
							disabled={{
								before: addDays(new Date(), 1),
								after: flightOnlyMaxDate ? new Date(flightOnlyMaxDate) : null,
							}}
						/>
					) : (
						<FormField
							name="when"
							label="When"
							as="date-range"
							control={control}
							errors={errors}
							wrapperClassName="h-auto xl:h-full w-full"
							hideTime
							disabledDays={{
								before: addDays(new Date(), 1),
								after: flightOnlyMaxDate ? new Date(flightOnlyMaxDate) : null,
							}}
						/>
					)}
				</div>

				<SearchFormButton
					onCancel={clear}
					label="Find Flights"
					hideCancel={hideClearBtn}
					className="lg:mt-0 xl:mt-6"
					handleSubmit={handleSubmit(onSubmit)}
					hasYouth={watch('who.youth')?.value > 0}
					hasErrors={Object.keys(errors).length > 0}
				/>
			</form>
		</FormProvider>
	);
}

FlightSearchForm.propTypes = {
	clear: PropTypes.func.isRequired,
	params: PropTypes.shape({
		outboundArrive: PropTypes.string,
		inboundDepart: PropTypes.string,
		outboundDepart: PropTypes.string,
		inboundArrive: PropTypes.string,
		adults: PropTypes.string,
		children: PropTypes.string,
		infants: PropTypes.string,
		startDate: PropTypes.string,
		endDate: PropTypes.string,
	}),
};

FlightSearchForm.defaultProps = {
	params: {},
};

export default FlightSearchForm;

import PropTypes from 'prop-types';
import {
	Heading,
	Text,
	Icon,
	Button,
	CheckButton,
	Image,
} from '@/components/common';
import cn from '@/lib/utils/tailwind';
import { currencyFormatter } from '@/lib/utils/currency';
import { useBreakpoint } from '@/hooks';
import SelectedTick from '@/components/common/molecules/selected-tick';

function RoomOptionCard({
	roomIdx,
	selectedRooms,
	altRooms,
	handleSelect,
	disabled,
}) {
	const canSelectOptions = roomIdx === 0 || roomIdx <= selectedRooms.length;
	const selectedRoom = altRooms.find(
		(altRoom) => altRoom?.value === selectedRooms?.[roomIdx]?.roomCode
	);
	const isSmallDevice = useBreakpoint('md');

	return (
		<div
			className={cn(
				'flex flex-col xl:gap-4 border-b border-b-dark-grey/10 last:border-b-0 xl:py-5 first:pt-0 last:xl:pb-0',
				!canSelectOptions && 'opacity-50'
			)}
		>
			<Heading
				as="h4"
				className="px-5 pt-5 text-lg font-bold leading-snug tracking-tighter font-body text-dark-grey xl:pt-0"
			>
				Select Room {roomIdx + 1}
			</Heading>

			{canSelectOptions ? (
				<div className="flex flex-col xl:gap-4">
					{altRooms.map((altRoom) => {
						const isSelected = altRoom?.value === selectedRoom?.value;

						// show price difference if we have a selected room
						let price = altRoom?.pricePerPersonPerNight;
						if (selectedRoom?.value) {
							price = isSelected
								? 0
								: altRoom?.pricePerPersonPerNight -
								  selectedRoom?.pricePerPersonPerNight;
						}

						return (
							<div
								key={altRoom?.value}
								className="flex flex-col items-start gap-2.5 xl:flex-row xl:items-center even:bg-light-grey xl:even:bg-transparent p-5 xl:py-0"
							>
								<div className="flex flex-row items-start gap-5 xl:items-center xl:gap-4 xl:grow">
									<div className="relative flex shrink-0 flex-col justify-start w-[7.5rem] lg:w-40 h-[7.5rem] lg:h-40 overflow-hidden bg-gray-50">
										{!!altRoom?.image && (
											<Image
												width="100%"
												height="100%"
												className="block object-cover w-full h-full"
												src={altRoom.image}
												alt={altRoom.label}
											/>
										)}
									</div>
									<div className="flex flex-col gap-1 xl:items-center xl:flex-row xl:gap-4 grow">
										<div className="flex flex-col gap-1 xl:grow">
											<Text
												as="p"
												className="font-semibold leading-snug tracking-tighter font-body text-dark-grey"
											>
												{altRoom?.label}
											</Text>
											<Text
												as="p"
												className="text-sm font-normal leading-snug tracking-tighter font-body text-dark-grey"
											>
												*average price per person per night
											</Text>
										</div>

										<Text
											as="p"
											className="font-semibold leading-snug tracking-tighter font-body text-dark-grey whitespace-nowrap"
										>
											{selectedRoom ? (
												<span>{(price || 0) >= 0 ? '+' : ''}</span>
											) : null}
											{currencyFormatter({ amount: price || 0 })}*
										</Text>

										{!isSmallDevice && (
											<CheckButton
												isDisabled={disabled}
												checked={isSelected}
												onChange={() => {
													handleSelect(roomIdx, isSelected ? null : altRoom);
												}}
												className="w-40 gap-2 mt-2"
												renderText={(checked) => (
													<Text
														as="span"
														className="text-lg leading-snug tracking-tighter"
													>
														{checked ? 'Selected' : 'Select'}
													</Text>
												)}
											></CheckButton>
										)}
									</div>{' '}
								</div>

								{isSmallDevice && (
									<div className="flex items-center justify-between w-full">
										<Button
											label="Select"
											variant="supporting-yellow"
											onClick={() => {
												handleSelect(roomIdx, isSelected ? null : altRoom);
											}}
											disabled={disabled}
										/>
										<SelectedTick isSelected={isSelected} />
									</div>
								)}
							</div>
						);
					})}
				</div>
			) : (
				<Text
					as="span"
					className="flex px-6 pb-5 md:pb-0 font-medium leading-snug tracking-tighter text-dark-grey"
				>
					Please select room {selectedRooms.length + 1} to see more options.
				</Text>
			)}
		</div>
	);
}

RoomOptionCard.propTypes = {
	roomIdx: PropTypes.number.isRequired,
	selectedRooms: PropTypes.array,
	altRooms: PropTypes.array,
	handleSelect: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

RoomOptionCard.defaultProps = {
	selectedRooms: [],
	altRooms: [],
	disabled: false,
};

export default RoomOptionCard;

import PropTypes from 'prop-types';
import Heading from '@/components/common/atoms/heading';
import Text from '@/components/common/atoms/text';
import cn from '@/lib/utils/tailwind';

/**
 * @name VisaBookingNotice
 * @description A summary of the via travel notice details
 * @returns {React.JSX.Element}
 * @example
 * <VisaBookingNotice />
 * */

function VisaBookingNotice({ className, textClassName }) {
    return (
        <div
            className={className}
        >
            <Heading
                as="h3"
                className="text-base font-bold uppercase w-fit font-body"
            >
                Visas
            </Heading>
            <Text
                className={cn(
                    'pt-2 pb-4 text-dark-grey/65',
                    textClassName
                )}
            >
                It is YOUR responsibility to ensure that you have a valid
                passport and relevant visa(s) for your trip. British
                CITIZENS, British OVERSEAS CITIZENS who are re-admissible
                to the UK, and British DEPENDANT TERRITORIES CITIZENS,
                with the unrestricted right of permanent abode in the UK,
                will be exempt from requiring a visa and can enter Canada
                with an Electronic Travel Authorisation (ETA). It is
                mandatory for all visa exempt foreign nationals to have a
                pre-approved Electronic Travel Authorisation (ETA) to
                enter Canada by air. Applications must be made online at:{' '}
                <a
                    href="https://www.canada.ca/en/immigration-refugees-citizenship/services/visit-canada/eta.html?utm_source=slash-eta_ave&utm_medium=short-url-en&utm_campaign=eta"
                    title="Canada Electronic travel authorization"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline underline-offset-4 hover:opacity-75"
                >
                    www.Canada.ca/eta
                </a>{' '}
                Please note: If you are a British Subject where you are
                subject to control under the immigration Act of 1971 you
                will still require a visa to enter Canada (subject to
                change).
            </Text>
        </div>
    )
}

VisaBookingNotice.propTypes = {
    className: PropTypes.string,
    textClassName: PropTypes.string,
}

export default VisaBookingNotice;

import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { cn, formatLocalDate } from '@/lib/utils';
import Icon from '@/components/common/atoms/icon';
import Text from '@/components/common/atoms/text';
import Heading from '@/components/common/atoms/heading';
import PageWrapper from '@/components/common/organisms/page-wrapper';

/**
 * @typedef {Object} BlockLayoutProps
 * @property {string} date
 * @property {string} title
 * @property {import("@components/common/atoms/icon").IconProps["name"]} iconName
 * @property {React.ReactNode} children
 * @property {string} className
 * @property {() => React.ReactNode} renderStep
 *
 */

/**
 * @name BlockLayout
 * @description Renders a block layout with a date, title, icon, and children
 * @param {BlockLayoutProps} props
 * @returns {JSX.Element | null}
 * @example
 * <BlockLayout
 *  date="2024-06-14T00:00:00+00:00"
 *  title="Outbound Flight"
 *  iconName="plane"
 * >
 * 	<div className="flex flex-col p-5">
 * 		<Route iconName="plane-right" to={flight.to} from={flight.from} />
 * 	</div>
 * </BlockLayout>
 */
const BlockLayout = forwardRef(
	(
		{
			date,
			title,
			headerTitle,
			iconName,
			children,
			className,
			renderStep,
			loading,
			error,
		},
		ref
	) => {
		return (
			<div ref={ref} className="flex flex-col gap-2.5 md:gap-5 scroll-mt-32">
				{!!headerTitle && (
					<Heading
						as="h2"
						className="ml-5 md:ml-0 lg:ml-[4.375rem] 2xl:ml-0 text-3xl font-bold font-body"
					>
						{headerTitle}
					</Heading>
				)}
				<section className={cn('w-full relative flex items-start', className)}>
					<div
						className={cn(
							'flex-col items-center hidden w-17.5 h-auto px-5 py-3 -space-y-1 text-white rounded-l-xl lg:flex 2xl:-left-16 2xl:absolute',
							date ? 'bg-lightest-grey' : ''
						)}
					>
						{date ? (
							<>
								<Text as="span" className="text-sm font-normal">
									{formatLocalDate(date, 'EE')}
								</Text>
								<Text as="span" className="text-3xl font-bold">
									{formatLocalDate(date, 'dd')}
								</Text>
								<Text as="span" className="text-sm font-normal">
									{formatLocalDate(date, 'MMM')}
								</Text>
							</>
						) : null}
					</div>
					<div className="relative flex-1 lg:max-w-[calc(100%-4.375rem)] 2xl:max-w-none w-full bg-white md:border md:shadow-md md:border-lighter-grey">
						<div className="flex items-end justify-between w-full px-5 py-4 text-white md:px-6 bg-core-blue lg:items-center">
							<div>
								{date ? (
									<Text className="flex items-center gap-2 mb-2.5 lg:hidden text-light-blue">
										<Icon name="calendar" className="w-4 h-3.5" />
										<Text
											as="span"
											className="text-sm font-bold leading-snug tracking-wide uppercase"
										>
											{formatLocalDate(date, 'EE dd MMM')}
										</Text>
									</Text>
								) : null}
								<Text className="flex items-center gap-3">
									{!iconName ? null : (
										<Icon name={iconName} className="w-4 h-4 text-white" />
									)}
									<Text
										as="span"
										className="text-base font-bold leading-snug tracking-tighter capitalize"
									>
										{title}
									</Text>
								</Text>
							</div>

							{typeof renderStep === 'function' ? renderStep() : null}
						</div>
						<PageWrapper
							error={error}
							loading={loading}
							loaderClassName="h-96"
							errorClassName="min-h-120 md:min-h-96.25"
							loadingText=""
						>
							{children}
						</PageWrapper>
					</div>
				</section>
			</div>
		);
	}
);

BlockLayout.propTypes = {
	date: PropTypes.string,
	title: PropTypes.string,
	iconName: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	totalSteps: PropTypes.number,
	currentStep: PropTypes.number,
	loading: PropTypes.bool,
	error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

BlockLayout.defaultProps = {
	date: '',
	title: '',
	iconName: '',
	children: null,
	className: '',
	// renderStep: () => <p>Step 1 of 2</p>,
	loading: false,
	error: null,
};

export default BlockLayout;

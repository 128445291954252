import React, { useLayoutEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

function useWindowSize() {
	const [size, setSize] = useState([0, 0]);

	// debounce resize
	const updateSize = useDebouncedCallback(() => {
		setSize([window.innerWidth, window.innerHeight]);
	}, 100);

	useLayoutEffect(() => {
		window.addEventListener('resize', updateSize);
		updateSize();

		return () => window.removeEventListener('resize', updateSize);
	}, []);

	return size;
}

export default useWindowSize;

import { currencyFormatter } from '@/lib/utils';
import {
	Text,
	AccordionItem,
	AccordionContent,
	AccordionTrigger,
	NullTerniaryWrapper,
} from '@components/common';

const Extras = ({ option }) => {
	return (
		<NullTerniaryWrapper condition={option ? true : false}>
			<AccordionItem value="optionPlus" className="mb-2">
				<AccordionTrigger
					asChild
					className="flex-row-reverse items-start justify-end w-full gap-2 tracking-normal cursor-pointer"
				>
					<Text
						as="p"
						className="flex items-center justify-between w-full font-bold"
					>
						<Text as="span" className="font-bold">
							Extras
						</Text>
						<Text as="span" className="font-bold">
							{currencyFormatter({
								amount: option?.total ?? 0,
							})}
						</Text>
					</Text>
				</AccordionTrigger>
				<AccordionContent value="optionPlus">
					{option?.breakdown && (
						<div className="flex flex-col gap-4 my-2">
							{option?.breakdown?.map((opt) => (
								<div
									key={opt?.name}
									className="flex items-center justify-between"
								>
									<Text as="span" className="text-sm font-bold capitalize">
										{opt?.name}
									</Text>
									<Text as="span" className="text-sm">
										{currencyFormatter({
											amount: opt?.total ?? 0,
										})}
									</Text>
								</div>
							))}
						</div>
					)}
				</AccordionContent>
			</AccordionItem>
		</NullTerniaryWrapper>
	);
};

export default Extras;

import cn from '@/lib/utils/tailwind';
import { Alert as CNAlert, AlertDescription, AlertTitle } from './components';
import { Text } from '@/components/common';
/**
 * @typedef {Object} AlertProps
 * @property {string} title
 * @property {string} className
 * @property {"default" | "destructive"} variant
 * @property {React.ReactNode | string} children
 *
 */

/**
 * @name Alert
 * @description A simple alert component
 * @param {AlertProps} props
 * @returns {React.JSX.Element}
 * @example
 * <Alert variant="destructive" title="Error">
 *     There was an error
 * </Alert>
 */
function Alert({
	variant,
	className,
	subtitleClassName,
	title,
	subtitle,
	children,
	showHtmlTitle,
	showHtmlSubtitle,
}) {
	return (
		<CNAlert variant={variant} className={className}>
			{title &&
				(showHtmlTitle ? (
					<AlertTitle
						className="text-lg font-bold"
						dangerouslySetInnerHTML={{ __html: title }}
					></AlertTitle>
				) : (
					<AlertTitle className="text-lg font-bold">{title}</AlertTitle>
				))}
			{subtitle &&
				(showHtmlSubtitle ? (
					<Text
						className={cn(
							'mb-2 font-medium text-sm [&_p]:leading-relaxed',
							subtitleClassName
						)}
						dangerouslySetInnerHTML={{ __html: subtitle }}
					></Text>
				) : (
					<Text className={cn(
						'mb-2 font-medium text-sm [&_p]:leading-relaxed',
						subtitleClassName
					)}>
						{subtitle}
					</Text>
				))}
			<AlertDescription>{children}</AlertDescription>
		</CNAlert>
	);
}
export default Alert;

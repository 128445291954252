import PropTypes from 'prop-types';
import { Button, Icon, Text } from '@/components/common';
import { cn } from '@/lib/utils';

function QueryItem({ label, value, icon, className }) {
	return (
		<div
			className={cn(
				'flex flex-col items-start h-fit grow shrink justify-start gap-1 text-core-light-blue md:py-4 lg:h-full',
				className
			)}
		>
			{label && (
				<Text className="hidden font-semibold leading-tight text-dark-grey lg:block">
					{label}
				</Text>
			)}
			{icon && <Icon name={icon} className="text-dark-grey" />}
			{typeof value === 'function' ? value() : <Text>{value}</Text>}
		</div>
	);
}

QueryItem.propTypes = {
	label: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.func,
		PropTypes.element,
		PropTypes.number,
	]),
};

QueryItem.defaultProps = {
	label: '',
	value: '',
};

function QueryWidget({ category, changeCategory, children }) {
	return (
		<div className="sticky top-0 left-0 flex items-start justify-start w-full gap-2 text-white bg-white shadow h-18">
			<Button
				onClick={changeCategory}
				className="flex flex-col items-center self-stretch justify-center max-w-sm gap-2 px-6 rounded-none w-fit bg-core-blue lg:w-1/4 lg:flex-row lg:gap-2"
			>
				<Icon name={category} className="w-6 h-6 lg:w-8 lg:h-8 shrink-0" />

				<div className="flex flex-col items-start justify-start h-auto">
					<Text className="text-sm font-medium leading-tight capitalize truncate lg:text-base">
						{category.replace('-', ' ')}
					</Text>
					<Text className="hidden text-base font-semibold leading-tight underline underline-offset-4 lg:block">
						Change search
					</Text>
				</div>
			</Button>
			<div className="flex items-center flex-1 h-full">{children}</div>
		</div>
	);
}

QueryWidget.propTypes = {
	view: PropTypes.oneOf([
		'car-hire',
		'motorhome-hire',
		'hotels',
		'flights',
		'holidays',
	]).isRequired,
	children: PropTypes.node.isRequired,
};

QueryWidget.defaultProps = {
	view: 'flights',
	children: null,
};

export default Object.assign(QueryWidget, { Item: QueryItem });

import qs from 'qs';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useState, useEffect, useMemo } from 'react';

/**
 * @typedef {Object} returnObject
 * @property {{
 * 		default: unknown | null,
 * 		[paramsKey]?: unknown | null,
 * }} params
 * @property {() => void} clearParams
 * @property {(newParams: object) => void} updateParams
 * @property {number} paramsKeySize
 * @property {number} defaultParamSize
 */

/**
 * @name useUrlParams
 * @description Custom hook to manage url params
 * @param {string?} paramsKey An optional Key to keep track of specific page params eg 'hotel_filters'
 * @returns {returnObject} params, clearParams, updateParams, paramsKeySize, defaultParamSize
 * @example
 * const { params, clearParams, updateParams, paramsKeySize, defaultParamSize, parsedInitialParams } = useUrlParams('hotel_filters');
 **/

function useUrlParams(paramsKey) {
	const [params, setParams] = useState({
		[paramsKey]: null,
		default: null,
	});
	const [searchParams, setSearchParams] = useSearchParams();
	const [parsedInitialParams, setParsedInitialParams] = useState(false);
	const search = useMemo(() => searchParams.toString(), [searchParams]);

	// get all params from the search and set them in the state
	const getParams = useCallback(() => {
		const qsParams = qs.parse(search, {
			ignoreQueryPrefix: true,
		});

		if (paramsKey) {
			setParams((prevParams) => ({
				...prevParams,
				[paramsKey]: qsParams[paramsKey] || {},
			}));
		}

		// ignore the params with the key from the search
		const { [paramsKey]: _, ...newDefaultParams } = qsParams;
		setParams((prevParams) => ({
			...prevParams,
			default: newDefaultParams,
		}));

		// indicate initial url params have been parsed
		if (!parsedInitialParams) setParsedInitialParams(true);
	}, [paramsKey, search]);

	useEffect(() => {
		getParams();
	}, [getParams]);

	// function to update filter params in the url
	const updateParams = useCallback(
		(newParams) => {
			if (paramsKey) {
				const newSearch = qs.stringify({
					...params.default,
					[paramsKey]: newParams,
				});
				setSearchParams(newSearch);
			} else {
				const newSearch = qs.stringify({ ...params.default, ...newParams });
				setSearchParams(newSearch);
			}
		},
		[JSON.stringify(params), paramsKey]
	);

	// function to clear params[paramsKey] params from the url
	const clearParams = useCallback(() => {
		const newParams = { ...params };

		if (newParams[paramsKey]) {
			delete newParams[paramsKey];
		}
		const newSearch = qs.stringify({
			...newParams.default,
		});

		setParams((prevParams) => ({
			...prevParams,
			[paramsKey]: null,
		}));

		setSearchParams(newSearch);
	}, [JSON.stringify(params), paramsKey]);

	// get the number of paramsKey params
	const paramsKeySize = useMemo(() => {
		if (params[paramsKey]) {
			return Object.keys(params[paramsKey]).length;
		}
		return 0;
	}, [JSON.stringify(params), paramsKey]);

	// get the number of default params
	const defaultParamSize = useMemo(() => {
		if (params.default) {
			return Object.keys(params.default).length;
		}
		return 0;
	}, [JSON.stringify(params.default)]);

	return {
		params,
		clearParams,
		updateParams,
		paramsKeySize,
		defaultParamSize,
		parsedInitialParams,
	};
}
export default useUrlParams;

/**
 * @typedef {Object} step
 * @property {string} name
 * @property {string} to
 * @property {string} [search]
 */

/**
 * @name flightSteps
 * @type{step[]} flightSteps
 **/

export const flightSteps = [
    {
        name: 'Select your flights',
        to: '/search/flights',
    },
    {
        name: 'Details',
        to: '/booking/flights/passenger-details',
    },
    {
        name: 'Option Plus',
        to: '/booking/flights/option-plus',
    },
    {
        name: 'Baggage',
        to: '/booking/flights/baggage',
    },
    {
        name: 'Seats',
        to: '/booking/flights/seats',
    },
    {
        name: 'Payment',
        to: '/booking/flights/payment',
    },
    {
        name: 'Confirm',
        to: '/booking/flights/booking-complete',
    },
];

/**
 * @name holidaySteps
 * @type{step[]} holidaySteps
 **/
export const holidaySteps = [
    {
        name: 'Holidays',
        to: '/search/holidays',
    },
    {
        name: 'Hotel',
        to: '/destinations',
    },
    {
        name: 'Travel Plan',
        to: '/booking/holidays/travel-plan',
    },
    {
        name: 'Details',
        to: '/booking/holidays/passenger-details',
    },
    {
        name: 'Option Plus',
        to: '/booking/holidays/option-plus',
    },
    {
        name: 'Baggage',
        to: '/booking/holidays/baggage',
    },
    {
        name: 'Seats',
        to: '/booking/holidays/seats',
    },
    {
        name: 'Payment',
        to: '/booking/holidays/payment',
    },
    {
        name: 'Confirm',
        to: '/booking/holidays/booking-complete',
    },
];

/**
 * @name holidayMultiSteps
 * @type{step[]} holidayMultiSteps
 **/
export const holidayMultiSteps = [
    {
        name: 'Holidays',
        to: '/search/holidays',
    },
    {
        name: 'Travel Plan',
        to: '/booking/holidays-multistep/travel-plan',
    },
    {
        name: 'Details',
        to: '/booking/holidays-multistep/passenger-details',
    },
    {
        name: 'Payment',
        to: '/booking/holidays-multistep/payment',
    },
    {
        name: 'Confirm',
        to: '/booking/holidays-multistep/booking-complete',
    },
];

/**
 * @name hotelsSteps
 * @type{step[]} hotelsSteps
 **/
export const hotelsSteps = [
    {
        name: 'Hotel',
        to: '/search/hotels',
    },
    {
        name: 'Details',
        to: '/booking/hotels/passenger-details',
    },
    {
        name: 'Payment',
        to: '/booking/hotels/payment',
    },
    {
        name: 'Confirm',
        to: '/booking/hotels/booking-complete',
    },
];

/**
 * @name motorhomeSteps
 * @type{step[]} motorhomeSteps
 **/
export const motorhomeSteps = [
    {
        name: 'Search Results',
        to: '/search/motorhome-hire',
    },
    {
        name: 'Motorhome Hire Extras',
        to: '/booking/motorhome-hire/extras',
    },
    {
        name: 'Details',
        to: '/booking/motorhome-hire/driver-details',
    },
    {
        name: 'Payment',
        to: '/booking/motorhome-hire/payment',
    },
    {
        name: 'Confirm',
        to: '/booking/motorhome-hire/booking-complete',
    },
];

/**
 * @name carhireSteps
 * @type{step[]} carhireSteps
 **/
export const carhireSteps = [
    {
        name: 'Search',
        to: '/search/car-hire',
    },
    {
        name: 'Car Hire Extras',
        to: '/booking/car-hire/extras',
    },
    {
        name: 'Details',
        to: '/booking/car-hire/driver-details',
    },
    {
        name: 'Payment',
        to: '/booking/car-hire/payment',
    },
    {
        name: 'Confirm',
        to: '/booking/car-hire/booking-complete',
    },
];


/**
 * @typedef {Object} stepCategoriesType
 * @property {step[]} flight
 * @property {step[]} holiday
 * @property {step[]} hotels
 * @property {step[]} motorhome-hire
 * @property {step[]} car-hire
 * @property {step[]} multiHoliday
 * */

/**
 * @typedef {"flights" | "holiday" | "hotels" | "motorhome-hire" | "car-hire" | "multiHoliday"} category
 */

/**
 * @name stepCategories
 * @description An object containing all the steps for each category
 * @type{stepCategoriesType} stepCategories
 * */
export const stepCategories = {
    flights: flightSteps,
    holiday: holidaySteps,
    holidays: holidaySteps,
    multiHoliday: holidayMultiSteps,
    hotels: hotelsSteps,
    'motorhome-hire': motorhomeSteps,
    'car-hire': carhireSteps,
}

/**
 * @name getSteps
 * @description Get the steps for a specific category
 * @param {category} category
 * @returns {step[] | null}
 */

export const getSteps = (category) => {
    if (!category) return null;
    if (!stepCategories[category]) return null;
    return stepCategories[category];
}

import qs from 'qs';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import {
	addDays,
	differenceInDays,
	format,
	isDate,
	isValid,
	startOfDay,
} from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
	Button,
	WhoForm,
	FormField,
	SearchFormButton,
} from '@/components/common';
import { useSearchSave } from '@/hooks';
import RoomChoiceFields from './RoomChoiceFields';
import { HotelSearchSchema } from '@/validationSchemas';
import {
	Required,
	HOTEL_WHO_OPTIONS,
} from '@/lib/utils';
import useDateRestrictions from '@/hooks/useDateRestrictions';

function HotelSearchForm({ clear, params, hideClearBtn }) {
	// const navigate = useNavigate();
	const navigate = (url) => (window.location.href = url);
	const [showRoomOptions, setShowRoomOptions] = useState(
		params?.rooms?.length >= 1
	);

	const { saveSearch, getSearch } = useSearchSave();
	const { hotelOnlyMaxDate } = useDateRestrictions();

	const previousSearch = getSearch('hotels');

	let defaultParams = null;
	if (previousSearch && !params) {
		defaultParams = qs.parse(previousSearch);
	} else {
		defaultParams = params;
	}

	const emptyDefaults = {
		location: null,
		roomCount: null,
		rooms: [],
		who: HOTEL_WHO_OPTIONS.reduce((acc, type) => {
			acc[type.value] = null;
			return acc;
		}, {}),

		when: {
			from: addDays(new Date(), 3),
			to: addDays(new Date(), 8),
		},
	};

	const defaultValues = useMemo(() => {
		if (!defaultParams) return emptyDefaults;
		const location = defaultParams?.location
			? defaultParams.location.split(':')
			: [];
		const roomCount = defaultParams?.roomCount
			? defaultParams.roomCount.split(':')
			: [];

		const startDate =
			defaultParams?.startDate && new Date(defaultParams.startDate);

		const endDate = defaultParams?.endDate && new Date(defaultParams.endDate);

		const roomdefaultParams = defaultParams?.rooms?.length
			? defaultParams.rooms
			: [];

		const rooms = roomdefaultParams.map((room) => {
			const acc = {};

			Object.keys(room).map((type) => {
				const value = room[type];
				acc[type] = {
					value,
					label: value.toString(),
				};
			}, {});

			return acc;
		});

		const hasMaxDate = hotelOnlyMaxDate && isValid(new Date(hotelOnlyMaxDate));

		// only pre-fill if at least 3 days from today & before max date
		const fromDate =
			isDate(startDate) &&
			differenceInDays(startOfDay(startDate), startOfDay(new Date())) >= 3 &&
			(!hasMaxDate || differenceInDays(
				startOfDay(startDate),
				startOfDay(new Date(hotelOnlyMaxDate))
			) <= 0)
				? startDate
				: addDays(new Date(), 3);

		// only pre-fill if after the from date & before max date
		const defaultEndDate = !hasMaxDate ||
			differenceInDays(
				addDays(fromDate, 8),
				startOfDay(new Date(hotelOnlyMaxDate))
			) <= 0
				? addDays(fromDate, 8)
				: new Date(hotelOnlyMaxDate);
		const toDate =
			isDate(endDate) &&
			differenceInDays(startOfDay(endDate), startOfDay(fromDate)) >= 0 &&
			(!hasMaxDate || differenceInDays(
				startOfDay(endDate),
				startOfDay(new Date(hotelOnlyMaxDate))
			) <= 0)
				? endDate
				: defaultEndDate;

		return {
			location: location[0]
				? {
						value: location[0] || '',
						label: location[1] || '',
				  }
				: null,
			roomCount: roomCount[0]
				? {
						value: roomCount[0],
						label: roomCount[1] || '',
				  }
				: null,
			rooms: rooms,
			when: {
				from: fromDate,
				to: toDate,
			},
		};
	}, [defaultParams, emptyDefaults]);

	const methods = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(HotelSearchSchema),
	});

	const {
		watch,
		control,
		handleSubmit,
		clearErrors,
		setValue,
		formState: { errors },
	} = methods;

	const roomCount = watch('roomCount');

	useEffect(() => {
		if (roomCount?.value) {
			setShowRoomOptions(true);
		}
	}, [roomCount]);

	const formStartDate = watch('when.from');
	const formEndDate = watch('when.to');
	useEffect(() => {
		if (!hotelOnlyMaxDate) return;
		if (!(formStartDate && formEndDate)) return;

		// reset form value if not valid with max date restriction
		const maxDate = new Date(hotelOnlyMaxDate);
		if (
			differenceInDays(new Date(formStartDate), maxDate) > 0 ||
			differenceInDays(new Date(formEndDate), maxDate) > 0
		) {
			setValue('when', {});
		}
	}, [hotelOnlyMaxDate, formStartDate, formEndDate]);

	const validateMaxPeopleOfType = useCallback((type) => (value, formValues) => {
		const whoKeys = HOTEL_WHO_OPTIONS.map((option) => option.value);
		if (!whoKeys.includes(type)) return true;

		// prevent more than the entered number of adults/children/infants being searched for
		const rooms = formValues.rooms || [];
		const maxPeople = formValues?.who[type]?.value
			? parseInt(formValues?.who[type].value)
			: 0;

		let total = 0;
		rooms.forEach(
			(room) => (total += room[type]?.value ? parseInt(room[type]?.value) : 0)
		);
		if (total <= maxPeople || !parseInt(value?.value)) return true; // hide message if valid, or an empty field

		return maxPeople <= 0
			? `You have not added any ${type}.`
			: `You have only added ${maxPeople} ${pluralize(type, maxPeople)}`;
	});

	const onSubmit = async (data) => {
		const { location, roomCount, rooms, who, when } = data;

		// remove additional rooms and format room data
		const formattedRooms = [];
		const totalRooms = roomCount?.value ? parseInt(roomCount.value) : 1;
		if (rooms?.length) {
			rooms.forEach((room, i) => {
				if (i + 1 > totalRooms) return;

				const roomData = {
					adults: 0,
					children: 0,
					infants: 0,
				};

				Object.keys(room).map((type) => {
					const roomInfo = room[type];
					if (!roomInfo) return;

					roomData[type] = roomInfo.value;
				});
				formattedRooms.push(roomData);
			});
		}

		const params = qs.stringify({
			location: location?.value ? `${location.value}:${location.label}` : '',
			roomCount: roomCount?.value
				? `${roomCount.value}:${roomCount.label}`
				: '',
			rooms: formattedRooms,
			startDate: when?.from ? format(when.from, 'yyyy-MM-dd') : '',
			endDate: when?.to ? format(when.to, 'yyyy-MM-dd') : '',
			category: 'hotels',
		});

		saveSearch(params, 'hotels');
		navigate(`/search/hotels?${params}`);
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="mx-auto flex flex-col xl:flex-row h-auto w-full items-center justify-center xl:items-start gap-2 md:gap-2.5 lg:gap-5">
					<div className="flex flex-col md:flex-row w-full items-start justify-start gap-2 md:gap-2.5 lg:gap-5 xl:w-2/3">
						<FormField
							name="location"
							label="Location"
							as="select"
							control={control}
							errors={errors}
							validation={[Required]}
							wrapperClassName="xl:min-w-[100px]"
							placeholder="Select"
							endpoint="hotel/locations"
							queryKey="hotel-locations"
							isAsync
						/>

						<FormField
							name="roomCount"
							label="Rooms"
							as="select"
							options={[
								{ value: 1, label: '1' },
								{ value: 2, label: '2' },
								{ value: 3, label: '3' },
							]}
							onChange={(field, value) => {
								field.onChange(value);

								// remove additional rooms from the form values
								const newTotal = parseInt(value?.value);
								const currentRooms = watch('rooms');
								if (newTotal && currentRooms?.length && currentRooms?.length > newTotal) {
									const newRooms = [...currentRooms];
									while (newRooms?.length && newRooms?.length > newTotal) {
										newRooms.pop();
									}

									// update the rooms field
									setValue('rooms', newRooms);
								}

								// reset errors from previous rooms
								clearErrors();
							}}
							control={control}
							errors={errors}
							validation={[Required]}
							wrapperClassName="xl:min-w-[100px]"
							placeholder="Select"
						/>
					</div>
					<div className="w-full flex flex-col md:flex-row items-start justify-start gap-2 md:gap-2.5 lg:gap-5 xl:w-1/3">
						{/* Mobile-only room fields */}
						{showRoomOptions && roomCount?.value && (
							<RoomChoiceFields
								errors={errors}
								control={control}
								roomCount={roomCount}
								className="w-full md:hidden"
								handleSubmit={handleSubmit(onSubmit)}
								validateMaxPeopleOfType={validateMaxPeopleOfType}
							/>
						)}

						<FormField
							name="when"
							label="When"
							as="date-range"
							control={control}
							errors={errors}
							validation={[Required]}
							wrapperClassName="h-full w-full"
							hideTime
							disabledDays={{
								before: addDays(new Date(), 3),
								after: hotelOnlyMaxDate ? new Date(hotelOnlyMaxDate) : null,
							}}
						/>
					</div>
					{/* Duplicated on mobile */}
					{/* Tablet/small desktop-only room fields */}
					{showRoomOptions && roomCount?.value && (
						<RoomChoiceFields
							control={control}
							errors={errors}
							roomCount={roomCount}
							handleSubmit={handleSubmit(onSubmit)}
							className="hidden w-full md:flex xl:hidden"
							validateMaxPeopleOfType={validateMaxPeopleOfType}
						/>
					)}

					<SearchFormButton
						onCancel={clear}
						label="Find Hotels"
						hideCancel={hideClearBtn}
						handleSubmit={handleSubmit(onSubmit)}
						hasYouth={false}
						className="pb-5 md:px-7.5 lg:px-14 md:pb-8 xl:p-0 lg:mt-0 xl:mt-6"
					/>
				</div>

				{/* Desktop-only room fields */}
				{showRoomOptions && roomCount?.value && (
					<RoomChoiceFields
						errors={errors}
						control={control}
						roomCount={roomCount}
						className="hidden xl:flex"
						handleSubmit={handleSubmit(onSubmit)}
						validateMaxPeopleOfType={validateMaxPeopleOfType}
					/>
				)}
			</form>
		</FormProvider>
	);
}

HotelSearchForm.propTypes = {
	clear: PropTypes.func.isRequired,
	params: PropTypes.shape({
		location: PropTypes.string,
		rooms: PropTypes.arrayOf(
			PropTypes.shape({
				adults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				infants: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			})
		),
		adults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		infants: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		startDate: PropTypes.string,
		endDate: PropTypes.string,
	}),
	hideClearBtn: PropTypes.bool,
};

HotelSearchForm.defaultProps = {
	params: {},
	hideClearBtn: false,
};

export default HotelSearchForm;

/**
 * @typedef {"option-plus" | "seat-selection"} requestParamsKey
 */

/**
 * @typedef {"hotels" | "flights" | "motorhome-hire" | "holidays" | "car-hire"} category
 */

/**
 * @name requestParams
 * @param {requestParamsKey} key
 * @param {any} state
 * @param {category} category
 * @returns {unknown} params
 */

export function requestParams(key, state, category) {
    if (!state || !key) return null;

    switch (key) {
        case 'option-plus':
            const selectedItems = category === 'holidays' ? state?.selected?.items : state?.selected?.items;
            const pax = state?.bookingDetails?.pax;
            const people = state?.selected?.people;

            if (!selectedItems || !selectedItems.length) return null;

            return {
                category: category,
                items: selectedItems,
                pax: pax ? pax : null,
                ...category !== 'holidays' && { people: pax ? [] : [people] }
            }

        case 'seat-selection':
            const items = category === 'holidays' ? state?.selected?.items?.[0].items : state?.selected?.items;

            if (!items || !items.length) return null;

            const flights = items.filter((item) => item?.type === 'flight');
            const params = flights.reduce((acc, item) => {
                const outbound = item?.outbound;
                const inbound = item?.inbound;

                if (outbound) {
                    acc.outbound = {
                        ...acc.outbound,
                        ...outbound,
                    };
                }

                if (inbound) {
                    acc.inbound = {
                        ...acc.inbound,
                        ...inbound,
                    };
                }

                return acc;
            }, {});

            return {
                category: category,
                ...params,
            };

        default:
            return null
    }

}

import {
	isDate,
	format,
	isValid,
	isBefore,
	differenceInDays,
	startOfDay,
} from 'date-fns';
import qs from 'qs';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { ErrorBoundary } from 'react-error-boundary';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';

import {
	Icon,
	Tabs,
	Text,
	Alert,
	Button,
	Heading,
	Dialog,
	Skeleton,
	TabsList,
	TabsContent,
	TabsTrigger,
	buttonVariants,
	DatePlaceHolder,
} from '@components/common';
import { useBookingStore } from '@/store';
import { useFetch, useUrlParams, useBreakpoint, useSearchSave } from '@/hooks';
import { cn, navigate, currencyFormatter } from '@/lib/utils';
import { HOLIDAY_MAX_DATE } from '@/lib/utils/restrictions';
import { EnquiryWidget } from '@/components/common';
import useDateRestrictions from '@/hooks/useDateRestrictions';

import hotelBookingClass from './types/hotel';
import holidayBookingClass from './types/holidays';

/**
 * @typedef {Object} option
 * @property {string} label
 * @property {"hotels" | "holidays" | "motorhome" | "itinerary"} value
 * @property {import("@components/common/atoms/icon").iconNames} icon
 */

/**
 * @typedef {Object} BookingWidgetProps
 * @property {option[]} options
 * @property {boolean} hideTrigger
 * @property {string} containerId
 **/

/**
 * @name BookingWidget
 * @description A booking widget that allows users to book hotels, motorhomes, flights, and itineraries.
 * @param {BookingWidgetProps} props
 * @returns {React.JSX.Element}
 * @example
 * <BookingWidget options={[]} containerId="booking-hotels-booking-widget" />
 * @todo TODO: Add rest validation schemas
 **/

function BookingWidget({ options, hideTrigger, containerId }) {
	// const navigate = useNavigate();
	const bookingModalRef = useRef(null);
	const isLg = useBreakpoint('lg');
	const [isLoading, setIsLoading] = useState(false);
	const [customError, setCustomError] = useState(null);
	const [enquiryDataset, setEnquiryDataset] = useState({});
	const { hotelOnlyMaxDate } = useDateRestrictions();

	const { saveSearch } = useSearchSave();
	const { params, updateParams } = useUrlParams();
	const { setBookingState, resetState } = useBookingStore();
	const [activeTab, setActiveTab] = useState(options[0]?.value);
	const [fetchParams, setFetchParams] = useState(null);

	const bookingState = useBookingStore((store) =>
		store ? store[activeTab] : null
	);

	const getBookingClass = () => {
		switch (activeTab) {
			case 'hotels':
				return hotelBookingClass;
			case 'holidays':
				return holidayBookingClass;
			default:
				return hotelBookingClass;
		}
	};

	// set the active tab
	useEffect(() => {
		if (!params?.default?.tab) return;

		// check the param matches a valid tab option
		const tabOption = options.find(
			(option) => option.value === params.default.tab
		);
		if (!tabOption) return;

		setActiveTab(tabOption.value);
	}, [params?.default?.tab]);

	// get the code from the portal container
	const code = useMemo(() => {
		const container = document.getElementById(containerId);
		const defaultCode = 'TOR073'; // DEBUGGABLE CODE// BAN009 - External // TOR073 - Internal
		if (container) {
			setEnquiryDataset(container.dataset);
			switch (activeTab) {
				case 'hotels':
				case 'holidays':
					return container.getAttribute(getBookingClass().htmlAttribute);
				case 'motorhomes':
					return container.getAttribute('data-motorhome');
				case 'itinerary':
					return container.getAttribute('data-itinerary');
				default:
					return defaultCode;
			}
		}
		return defaultCode;
	}, [containerId, activeTab]);

	// get url params
	const urlParams = useMemo(() => {
		if (params?.default) {
			return {
				code: code,
				...params.default,
			};
		}
		return {
			code: code,
		};
	}, [code, JSON.stringify(params?.default)]);

	// set defaultValues for the form
	const defaultValues = useMemo(() => {
		const from = urlParams?.from ? urlParams.from.split(':') : [];
		const adults = urlParams?.adults ? urlParams.adults.split(':') : [];
		const children = urlParams?.children ? urlParams.children.split(':') : [];
		const infants = urlParams?.infants ? urlParams.infants.split(':') : [];
		const startDate = urlParams?.startDate
			? new Date(urlParams.startDate)
			: null;
		const endDate = urlParams?.endDate ? new Date(urlParams.endDate) : null;

		const isValidEndDate =
			isDate(startDate) &&
			isDate(endDate) &&
			!isBefore(startOfDay(endDate), startOfDay(startDate));

		switch (activeTab) {
			case 'holidays':
				return getBookingClass().getDefaultValues(urlParams, HOLIDAY_MAX_DATE);

			case 'hotels':
				return getBookingClass().getDefaultValues(urlParams, hotelOnlyMaxDate);

			case 'motorhome':
				return {
					when: {
						from: isDate(startDate) ? startDate : undefined,
						to: isValidEndDate ? endDate : undefined,
					},
					adults:
						{
							value: adults[0],
							label: adults[1],
						} ?? null,
					children:
						{
							value: children[0],
							label: children[1],
						} ?? null,
					infants:
						{
							value: infants[0],
							label: infants[1],
						} ?? null,
				};
			case 'itinerary':
				return {
					departure:
						{
							value: from[0],
							label: from[1],
						} ?? null,

					when: {
						from: isDate(startDate) ? startDate : undefined,
						to: isValidEndDate ? endDate : undefined,
					},
					adults:
						{
							value: adults[0],
							label: adults[1],
						} ?? null,
					children:
						{
							value: children[0],
							label: children[1],
						} ?? null,
					infants:
						{
							value: infants[0],
							label: infants[1],
						} ?? null,
				};
			default:
				return {
					from: null,
					when: {
						from: undefined,
						to: undefined,
					},
					adults: null,
					children: null,
					infants: null,
					rooms: [],
				};
		}
	}, [JSON.stringify(urlParams), activeTab, code, hotelOnlyMaxDate, HOLIDAY_MAX_DATE]);

	const {
		watch,
		reset,
		resetField,
		control,
		setValue,
		setError,
		handleSubmit,
		clearErrors,
		formState: { errors },
	} = useForm({
		defaultValues,
		resolver: yupResolver(getBookingClass().validationSchema),
	});

	useEffect(() => {
		reset(defaultValues, { keepDirtyValues: true, keepDirty: true });
	}, [defaultValues, reset]);

	useEffect(() => {
		const subscription = watch((state, { name }) => {
			// update just the dates
			if (name === 'when') {
				const when = state[name];
				const startDate = isValid(when?.from)
					? format(when.from, 'yyyy-MM-dd')
					: null;

				const endDate = isValid(when.to) ? format(when.to, 'yyyy-MM-dd') : null;

				if (!startDate || !endDate) return;

				updateParams({
					startDate,
					endDate,
				});
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, updateParams]);

	const { when, rooms, from, duration } = watch();

	// reset date if invalid when changing tab to prevent invalid date selection
	useEffect(() => {
		if (!activeTab) return;
		if (!(urlParams?.startDate || urlParams?.endDate)) return;

		let newDefaultParams;
		switch (activeTab) {
			case 'holidays':
				newDefaultParams = getBookingClass().getDefaultValues(
					urlParams,
					HOLIDAY_MAX_DATE
				);
				break;
			case 'motorhome-hire':
				newDefaultParams = getBookingClass().getDefaultValues(urlParams);
				break;
			case 'hotels':
				newDefaultParams = getBookingClass().getDefaultValues(
					urlParams,
					hotelOnlyMaxDate
				);
				break;
		}

		setValue('when', {
			from: newDefaultParams?.when?.from,
			to: newDefaultParams?.when?.to,
		});
	}, [activeTab]);

	const validRooms = rooms?.filter((room) => !!room?.adults?.value);
	const selectedNights =
		isDate(when?.from) && isDate(when?.to)
			? differenceInDays(when.to, when.from)
			: null;

	const previewParams = async () => {
		const validStartDate = isValid(when?.from)
			? format(when?.from, 'yyyy-MM-dd')
			: null;
		const validEndDate = isValid(when?.to)
			? format(when?.to, 'yyyy-MM-dd')
			: null;

		if (!validStartDate || !validEndDate) {
			return null;
		}

		const baseParams = {
			code,
			type: getBookingClass().bookingType,
			endDate: validEndDate,
			outboundDepart: from?.value,
			startDate: validStartDate,
			...(activeTab === 'holidays' && {
				holidayType: params?.default?.type ?
					params?.default?.type?.split(':')?.[0] :
					'holidays',
			}),
		};

		setIsLoading(true);
		const fetchedParams = await getBookingClass().getPreviewParams(
			baseParams,
			rooms
		);

		if (activeTab === 'holidays') {
			if (!fetchedParams) {
				if (!!rooms && rooms[0]?.room) {
					setCustomError({
						message: 'The holiday is not available.',
					});
				}
			} else if (!!fetchedParams) {
				setCustomError(null);
			}
		}
		setFetchParams(fetchedParams);

		return fetchedParams;
	};

	// refetch whenit changes
	useEffect(() => {
		previewParams();
	}, [
		code,
		JSON.stringify(from),
		JSON.stringify(when),
		JSON.stringify(duration),
		activeTab,
		JSON.stringify(validRooms),
		params?.default?.type,
	]);

	// const fetchParams = previewParams();
	// fetch the price preview
	const { data, isFetching, error, isPending } = useFetch({
		key: 'booking-preview',
		method: 'POST',
		useBody: true,
		params: fetchParams,
		config: {
			enabled: fetchParams !== null,
		},
		onData: () => {
			setIsLoading(false);
		},
	});

	const onError = useCallback(() => {
		if (data?.original?.error) {
			setCustomError({
				...error,
				message: data?.original?.error,
			});
		}

		if (!error) return;
		if (error?.message.includes('booking-preview')) {
			setCustomError({
				...error,
				message: 'Please check your selections and try again.',
			});
		} else {
			setCustomError(error);
		}

		setTimeout(() => {
			setCustomError(null);
		}, 4000);
	}, [error, data?.original?.error]);

	useEffect(() => {
		setCustomError(null);
	}, [activeTab]);

	useEffect(() => {
		onError();
	}, [onError]);

	const onSubmit = (values) => {
		// calculate the total number of people
		const totalPeople = values.rooms.reduce((acc, room) => {
			const { adults, children, infants } = room;
			if (adults?.value) acc += adults.value;
			if (children?.value) acc += children.value;
			if (infants?.value) acc += infants.value;
			return acc;
		}, 0);

		// reset the booking state
		resetState(getBookingClass().stateName);

		const bookingParams = {
			items: fetchParams?.items,
		};

		// set the booking state for the selected tab
		let newState = {
			shouldBuildParams: true,
			includeHolidayUpgrades: false,
			selected: bookingParams,
			bookingParams: bookingParams,
			passengerDetails: bookingState?.passengerDetails
				? { ...bookingState.passengerDetails }
				: undefined,
			preview: undefined,
			questions: undefined,
			paymentDetails: undefined,
			holidayUpgrades: undefined,
			error: null,
		};

		if (bookingState?.bookingDetails) {
			// remove pax and answered questions
			newState = {
				...newState,
				bookingDetails: {
					...(bookingState.bookingDetails || {}),
					questions: undefined,
					pax: undefined,
				},
			};
		}

		// set preview to prevent my travel plan loading delay
		if (activeTab === 'holidays') {
			newState.shouldBuildParams = false;
			newState.includeHolidayUpgrades = true;
			newState.preview = data;
			newState.holidayUpgrades = data?.holiday?.upgrades;
			newState.questions = data?.questions;
		}

		setBookingState(
			getBookingClass().stateName,
			newState,
			'SET_SELECTED_HOLIDAY'
		);

		const params = {
			itemCode: code,
			people: totalPeople,
			endDate: isValid(values?.when.to)
				? format(values.when.to, 'yyyy-MM-dd')
				: null,
			startDate: isValid(values?.when.from)
				? format(values.when.from, 'yyyy-MM-dd')
				: null,
		};

		const queryString = qs.stringify(params, {
			addQueryPrefix: true,
			encode: true,
		});

		if (activeTab === 'holidays') {
			const accommodation = data?.breakdown.filter(
				(b) => b.type === 'accommodation'
			)[0];
			const loc = `${accommodation?.location}:${accommodation?.locationName}`;
			const searchQueryString =
				getBookingClass().createQueryStringFromBookingWidget(
					urlParams,
					values,
					loc
				);
			saveSearch(searchQueryString, 'holidays');
		}

		// navigate to booking page for the selected tab
		navigate(getBookingClass().nextPage + queryString);
	};

	const handleTabChange = (value) => {
		if (value === activeTab) return;

		switch (value) {
			case 'holidays':
				const newWhen = {
					from: undefined,
					to: undefined,
				};

				// only allow 7/14 night durations
				const difference =
					isDate(when?.from) && isDate(when?.to)
						? differenceInDays(when.to, when.from)
						: -1;
				if ([7, 14].includes(difference)) {
					newWhen.from = when.from;
					newWhen.to = when.to;
				}

				resetField('when', { defaultValue: newWhen });
				break;
			case 'hotels':
				// set duration back to default values if from/to was cleared
				if (!(when?.from && when?.to)) {
					resetField('when', { defaultValue: defaultValues.when });
				}
				break;
		}

		setActiveTab(value);
	};

	const cleanDocTitle = document.title.replace(/\|/g, '-');

	// handle state change
	const Form = useMemo(() => {
		return getBookingClass().getFormComponent();
	}, [activeTab]);

	const submitButtonText = useMemo(() => {
		switch (activeTab) {
			case 'motorhome':
				return 'Book online';
			case 'holidays':
				return 'Select flights';
			default:
				return 'Book now';
		}
	}, [activeTab]);

	const iconLinks = [
		{
			name: 'Facebook',
			icon: 'facebook',
			href: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&t=${cleanDocTitle}`,
		},
		{
			name: 'Twitter',
			icon: 'twitter-x',
			href: `http://twitter.com/share?text=${cleanDocTitle}&url=${window.location.href}`,
		},
	];

	const bottomShareComponent = () => (
		<div className="flex items-center justify-center py-4 mt-2 text-core-blue/80">
			<div className="flex items-center gap-4">
				<Text
					as="span"
					className="font-bold tracking-tight uppercase leading-extra-tight pt-0.5"
				>
					Share
				</Text>
				<div className="flex items-center gap-2">
					{iconLinks.map((item) => (
						<a
							key={item.name}
							href={item.href}
							rel="noopener noreferrer"
							target="_blank"
						>
							<Icon
								name={item.icon}
								className={cn('w-4.5 h-4.5 hover:text-core-blue', {
									'w-3.5 h-3.5': item.icon === 'twitter-x',
								})}
							/>
						</a>
					))}
				</div>
			</div>
			{/* <Button
				label="Save"
				iconName="heart"
				disableAnimation
				variant="unstyled"
				className="w-auto h-4.5 gap-4 hover:text-core-blue rounded-none"
				iconClassName="w-5 h-4.5"
				labelClassName="font-bold leading-extra-tight tracking-tight pt-0.5 uppercase"
			/> */}
		</div>
	);

	if (Object.hasOwn(enquiryDataset, 'atcom') && !enquiryDataset?.atcom) {
		if (isLg) {
			return <EnquiryWidget containerId={containerId} />;
		}
		return (
			<section className="w-full min-h-20 md:w-[450px]">
				<div className="flex items-center justify-between bg-white drop-shadow-md">
					<EnquiryWidget containerId={containerId} />
				</div>
				{bottomShareComponent()}
			</section>
		);
	}

	const bookingComponent = () => (
		<section className={cn('w-full min-h-20 sm:w-[450px]', isLg && 'mx-auto')}>
			<div
				className={cn(
					'flex items-center justify-between bg-white drop-shadow-md',
					{ 'pb-6': activeTab }
				)}
			>
				<form onSubmit={handleSubmit(onSubmit)} className="w-full">
					<Tabs className="w-full" value={activeTab}>
						<TabsList className="w-full p-0 rounded-none h-14">
							{options.map((option) => (
								<TabsTrigger
									key={option.value}
									value={option.value}
									className={cn(
										'flex gap-2 w-full h-full shadow-none rounded-none text-white bg-core-blue hover:opacity-80',
										{
											'text-white shadow-none bg-core-blue data-[state=active]:text-core-blue data-[state=active]:bg-white data-[state=active]:shadow-none':
												options.length > 1, // swap active states when more than one tab
										},
										{ hidden: hideTrigger }
									)}
									onClick={() => handleTabChange(option.value)}
								>
									<Icon name={option.icon} />
									<Text as="span">{option.label}</Text>
								</TabsTrigger>
							))}
						</TabsList>
						{activeTab && (
							<>
								<div
									className={cn(
										'p-8 flex flex-col gap-5',
										hideTrigger && 'pt-0'
									)}
								>
									<Heading as="h3" className="text-5xl font-body">
										{activeTab === 'hotels'
											? 'Book hotel.'
											: 'Book hotel & flights.'}
									</Heading>

									<TabsContent value={activeTab}>
										<AnimatePresence mode="wait" initial={false}>
											{customError ? (
												<motion.div
													initial={{ opacity: 0, height: 0 }}
													animate={{ opacity: 1, height: 'auto' }}
													exit={{ opacity: 0, height: 0 }}
												>
													<Alert variant="destructive" className="mb-4">
														{customError?.message ?? ''}
													</Alert>
												</motion.div>
											) : null}
										</AnimatePresence>
										<ErrorBoundary
											fallback={<span> Something went wrong ...</span>}
										>
											<Form
												watch={watch}
												errors={errors}
												control={control}
												params={urlParams}
												setError={setError}
												setValue={setValue}
												clearErrors={clearErrors}
											/>
										</ErrorBoundary>
									</TabsContent>
								</div>

								<div className="flex flex-col gap-3 px-8">
									{when?.to && (
										<div className="flex flex-col gap-3">
											<DatePlaceHolder
												className="w-full gap-5"
												selected={{
													from: new Date(when?.from),
													to: new Date(when?.to),
												}}
												hideTime
											/>
											{when?.from && (
												<Text className="flex items-center gap-2">
													<Icon name="moon" />
													<Text as="span" className="font-bold">
														{selectedNights || 1}{' '}
														{pluralize('night', selectedNights || 1)}
													</Text>
												</Text>
											)}
										</div>
									)}
									<div>
										<div className="flex items-center justify-between">
											<Text as="span" className="text-3xl font-bold">
												Booking total
											</Text>
											{isFetching && !data?.overview?.total ? (
												<Skeleton className="w-20 h-8" />
											) : (
												<Text as="span" className="text-3xl font-bold">
													{currencyFormatter({
														amount: data?.overview?.total ?? null,
													})}
												</Text>
											)}
										</div>
										<Text
											as="p"
											className="text-sm leading-snug tracking-less-tight text-light-black/70"
										>
											Total price for all travellers
										</Text>
									</div>
									<Button
										type="submit"
										label={submitButtonText}
										disabled={
											isPending ||
											isLoading ||
											isFetching ||
											!!error ||
											Object.keys(errors || {}).length > 0 ||
											!!customError ||
											Object.keys(customError || {}).length > 0
										}
										className="justify-between w-full py-4"
										variant={
											activeTab === 'itinerary'
												? 'core-blue'
												: 'supporting-yellow'
										}
									/>

									{activeTab === 'itinerary' ? (
										<div className="flex flex-col gap-3">
											<a
												href="tel:0207 616 9184"
												className={cn(
													buttonVariants({
														variant: 'core-blue',
														className:
															'w-full py-4 flex items-center justify-between',
													})
												)}
												variant="core-blue"
											>
												<Text as="span" className="flex items-center gap-2">
													<Icon name="phone" className="w-4 h-4" />
													<Text as="span" className="font-bold">
														Call to book
													</Text>
												</Text>
												<Text as="span" className="font-bold">
													0207 616 9184
												</Text>
											</a>
											<Button
												label="Get a quote"
												onClick={handleSubmit(() => {
													// navigate to quote page
												})}
												className="justify-between w-full py-4"
												variant="supporting-yellow"
											/>
										</div>
									) : null}
								</div>
							</>
						)}
					</Tabs>
				</form>
			</div>
			{bottomShareComponent()}
		</section>
	);

	const bookingModalComponent = () => (
		<Dialog
			ref={bookingModalRef}
			as="modal"
			hideCloseBtn
			position="center"
			contentClassName="p-0 h-full overflow-y-auto"
			size="screen"
			renderTrigger={({ DialogTrigger, onOpen }) => (
				<DialogTrigger asChild>
					<Button
						label="Search & Book"
						onClick={onOpen}
						variant="core-blue"
						className="justify-between lg:w-full !text-supporting-yellow py-3.25 shrink-0"
						labelClassName="text-lg font-body font-bold tracking-tighter leading-less-snug"
					/>
				</DialogTrigger>
			)}
		>
			{({ CloseButton, onClose }) => (
				<div className="relative">
					<span className="flex items-center justify-end w-full h-12">
						<CloseButton
							onClick={onClose}
							className="relative top-0 right-0 w-12.5 h-full text-white bg-dark-grey"
							variant="square"
						/>
					</span>
					{bookingComponent()}
				</div>
			)}
		</Dialog>
	);

	if (isLg) {
		return (
			<div className="fixed bottom-0 left-0 right-0 flex-row items-center justify-between flex lg:hidden bg-supporting-yellow/90 py-4 px-6.25 z-10">
				<div className="flex flex-col gap-1">
					<p className="flex flex-col gap-1 sm:items-center sm:flex-row sm:gap-2 grow">
						<Text
							as="span"
							className="block text-xs font-medium sm:text-sm md:text-base !leading-extra-tight tracking-extra-tight font-body text-black/50"
						>
							{data?.overview?.total
								? 'Booking total'
								: params?.default?.priceFrom
									? 'Total From'
									: ''}
						</Text>
						<Text
							as="span"
							className="block text-base font-bold text-black sm:text-xl md:text-2xl font-body !leading-extra-tight tracking-extra-tight"
						>
							{currencyFormatter({
								amount: data?.overview?.total
									? data?.overview?.total
									: params?.default?.priceFrom ?? null,
							})}
						</Text>
					</p>
					{data?.overview?.total && (
						<Text
							as="span"
							className="block text-xs font-medium sm:text-sm md:text-base !leading-extra-tight tracking-extra-tight font-body text-black/50"
						>
							Total price for all travellers
						</Text>
					)}
				</div>
				{bookingModalComponent()}
			</div>
		);
	}

	return (
		<ErrorBoundary fallback={<span> Something went wrong ...</span>}>
			{bookingComponent()}
		</ErrorBoundary>
	);
}

BookingWidget.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.oneOf([
				'Hotels & Flights',
				'Hotels Only',
				'Motorhome',
				'Itinerary',
			]),
			value: PropTypes.oneOf(['holidays', 'hotels', 'motorhome', 'itinerary']),
			icon: PropTypes.oneOf(['flights', 'hotel', 'motorhome', 'itinerary']),
		})
	),
	hideTrigger: PropTypes.bool,
};

BookingWidget.defaultProps = {
	options: [
		{
			label: 'Hotels & Flights',
			value: 'holidays',
			icon: 'flights',
		},
		{
			label: 'Hotels Only',
			value: 'hotels',
			icon: 'hotel',
		},
	],
	hideTrigger: false,
};

export default BookingWidget;

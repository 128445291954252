import React, { useRef, useState, useEffect, useImperativeHandle } from 'react';
import pluralize from 'pluralize';

import {
	Icon,
	Carousel,
	CarouselNext,
	CarouselItem,
	CarouselContent,
	CarouselPrevious,
	Text,
	Button,
} from '@components/common';
import { cn, currencyFormatter } from '@/lib/utils';

/**
 * @typedef {Object} DatePriceCarouselProps
 * @property {Array} slides - An array of objects containing the image / video assets
 * @property {string} className - A class name to be applied to the carousel wrapper
 */

/**
 * @name DatePriceCarousel
 * @description A carousel component that can be used to display images or videos
 * @param {DatePriceCarouselProps} props
 * @returns {React.JSX.Element}
 * @todo TODO: Add autoplay, create a lazy loaded image/ video component
 * @example
 * <DatePriceCarousel slides={data} />
 */

const DatePriceCarousel = React.forwardRef(
	({ slides, className, setSelectedDatePrice }, ref) => {
		const [selectedIndex, setSelectedIndex] = useState(0);
		const [api, setApi] = useState(null);

		useEffect(() => {
			setSelectedDatePrice(slides[selectedIndex]);
		}, [slides, selectedIndex]);

		useEffect(() => {
			api && api.scrollTo(selectedIndex);
		}, [api, selectedIndex]);

		useImperativeHandle(ref, () => ({
			updateSelectedIndex: (idx) => {
				setSelectedIndex(idx);
			},
		}));

		return (
			<Carousel
				ref={ref}
				opts={{ loop: true }}
				className={cn('relative w-full px-5', className && className)}
				setApi={setApi}
				setSelectedIndex={setSelectedIndex}
			>
				<div className="inset-y-1 lg:inset-y-3.75 inset-x-0 border-y border-dark-grey/20 absolute z-0" />
				<CarouselContent className="relative ml-0 z-1 lg:-ml-5">
					{slides.map((slide, index) => (
						<CarouselItem key={index} className="flex pl-0 basis-1/3 lg:pl-5">
							<Button
								hideIcon
								variant="unstyled"
								onClick={() => {
									api.scrollTo(index);
								}}
								className={cn(
									'relative flex flex-col items-center justify-center px-2.5 py-3 lg:py-5.5 mx-auto bg-transparent grow shrink basis-0 rounded-2.5xl lg:rounded-2xl z-0 w-full gap-0 lg:gap-1 h-full text-center',
									selectedIndex === index && 'bg-slate-800'
								)}
							>
								<Text
									className={cn(
										'!text-xxs sm:!text-xs lg:!text-sm font-black !leading-snug sm:!leading-snug tracking-wide uppercase text-darker-grey/50',
										selectedIndex === index && 'text-white/50 lg:text-white'
									)}
								>
									{slide.name}
								</Text>
								<div className="flex flex-col items-center lg:gap-1 lg:flex-row">
									<Text
										as="span"
										variant="black"
										className={cn(
											'sm:text-lg font-bold !leading-snug tracking-tighter',
											selectedIndex === index && 'text-white'
										)}
									>
										{currencyFormatter({ amount: slide.price })}
									</Text>
									<Text
										variant="body"
										className={cn(
											'!text-xxs sm:!text-xs font-normal !leading-extra-tight sm:!leading-extra-tight tracking-tight text-dark-grey/60 lg:hidden',
											selectedIndex === index && 'text-white/60'
										)}
									>
										{slide.startDate && slide.endDate ? '' : 'unavailable'}
									</Text>
									<Text
										as="span"
										variant="black"
										className={cn(
											'text-base font-bold leading-snug tracking-tighter hidden lg:block',
											selectedIndex === index && 'text-white'
										)}
									>
										{slide.startDate && slide.endDate ? '' : 'unavailable'}
									</Text>
								</div>
								{slide.startDate && slide.endDate && (
									<div className="items-center hidden gap-2 lg:flex">
										<Icon
											name="moon"
											className={cn(
												'text-black',
												selectedIndex === index && 'text-white'
											)}
										/>
										<Text
											variant="body"
											className={cn(
												'text-base font-normal leading-snug tracking-tighter',
												selectedIndex === index && 'text-white'
											)}
										>
											{slide.duration} {pluralize('night', slide.duration)}
										</Text>
									</div>
								)}
							</Button>
						</CarouselItem>
					))}
				</CarouselContent>
				<CarouselPrevious
					variant="unstyled"
					className="absolute left-0 w-5 rounded-none grow shrink basis-0"
				/>
				<CarouselNext
					variant="unstyled"
					className="absolute right-0 w-5 rounded-none grow shrink basis-0"
				/>
			</Carousel>
		);
	}
);

DatePriceCarousel.defaultProps = {
	slides: [
		{
			name: 'May 2024',
			pricePerPerson: '1009',
			duration: '7',
		},
		{
			name: 'June 2024',
			pricePerPerson: '900.5',
			duration: '6',
		},
		{
			name: 'July 2024',
			pricePerPerson: '915',
			duration: '5',
		},
	],
};

export default DatePriceCarousel;

import { useMemo } from 'react';
import useFetch from './useFetch';

/**
 * @name useDateRestrictions
 * @description Fetches date restrictions from the api.
 * @returns {Object}
 * @example
 * const { carHireMaxDate } = useDateRestrictions();
 */

function useDateRestrictions() {
    const { data, isLoading } = useFetch({
        key: 'date-restrictions',
        format: (res) => res?.data
    });

    const restrictions = useMemo(() => {
        return {
            isLoadingRestrictions: isLoading,
            flightOnlyMaxDate: data?.flightOnly || null,
            carHireMaxDate: data?.carHireOnly || null,
            hotelOnlyMaxDate: data?.hotelOnly || null,
            motorhomeHireMaxDate: data?.motorhomeHireOnly || null,
            holidayMaxDate: data?.holiday || null,
        };
    }, [data, isLoading]);

    return restrictions;
}

export default useDateRestrictions;

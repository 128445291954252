import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
	add,
	differenceInDays,
	format,
	isDate,
	isValid as isValidDate,
} from 'date-fns';

import { cn } from '@/lib/utils';
import { useBreakpoint } from '@/hooks';
import { DirectionalArrow, Icon, Text } from '@components/common';
import pluralize from 'pluralize';

/**
 * @typedef {Object} DatePlaceholderProps
 * @property {{
 * 		from: string | Date,
 * 		to: string | Date,
 * }} selected
 * @property {boolean} hideTime
 * @property {boolean} showIcon
 * @property {boolean} showDifference
 * @property {string} className
 * @property {"date" | "inputdate"} type
 * @property {()=>void} handleClick
 */

/**
 * @name DatePlaceholder
 * @description A component that shows the selected dates
 * @param {DatePlaceholderProps} props
 * @returns {React.JSX.Element}
 * @example
 * <DatePlaceholder
 * 	 selected={{
 * 		from: new Date(),
 * 		to: add(new Date(), { days: 7 }),
 * 	 }}
 * 	 hideTime={false}
 * 	 className=""
 * 	 type="date"
 * 	 handleClick={() => {}}
 * />
 */
function DatePlaceholder({
	type,
	selected,
	hideTime,
	showIcon,
	showDifference,
	className,
	dateFormat,
	handleClick,
	isValid,
	hideFromTime,
	hideToTime,
	placeholderLabel,
	selectedTime,
	useCurrentTime,
	textClassName,
}) {
	const isSm = useBreakpoint('sm');
	const { dateFrom, dateFromLabel, dateTo, dateToLabel } = useMemo(() => {
		if (!selected)
			return {
				dateFrom: 'Select a date',
				dateTo: 'Select a date',
			};
		let dateformat = dateFormat
			? dateFormat
			: isSm
			? 'dd/MM/yy HH:mm'
			: 'dd/MM/yyyy HH:mm';

		if (hideTime) {
			dateformat = dateformat.replace('HH:mm', '').replace('a', '');
		}

		if (
			typeof useCurrentTime === 'boolean' &&
			!selectedTime &&
			!useCurrentTime
		) {
			dateformat = dateformat.replace('HH:mm', '').replace('a', '');
		}

		const selectedFrom = isDate(selected?.from)
			? selected?.from
			: new Date(selected?.from);
		const selectedTo = isDate(selected?.to)
			? selected?.to
			: new Date(selected?.to);

		const fromDateValid = isValidDate(selectedFrom);
		const toDateValid = isValidDate(selectedTo);
		return {
			dateFrom: fromDateValid ? selectedFrom : null,
			dateFromLabel: fromDateValid ? format(selectedFrom, dateformat) : null,
			dateTo: toDateValid ? selectedTo : null,
			dateToLabel: toDateValid ? format(selectedTo, dateformat) : null,
		};
	}, [selected, hideTime, dateFormat]);

	const difference =
		showDifference && dateFrom && dateTo
			? Math.abs(differenceInDays(dateTo, dateFrom))
			: null;

	return (
		<>
			<Text
				as="span"
				onClick={handleClick}
				className={cn(
					'flex w-auto shrink grow items-center justify-between gap-2 p-0',
					isValid && !(dateFrom && dateTo) ? 'text-lightest-grey' : '',
					isValid && hideToTime && dateFrom && !dateTo ? 'text-current' : '',
					!isValid ? 'text-core-red' : '',
					className,
					{
						'w-full border border-border-color h-10.5 lg:h-13.25 rounded px-3 py-2 gap-4':
							type === 'inputdate',
					}
				)}
			>
				{dateFromLabel || dateToLabel ? (
					<span className="flex flex-wrap items-center justify-start w-auto p-0 gap-x-2 gap-y-0 shrink grow sm:flex-nowrap">
						{hideFromTime ? null : (
							<Text
								as="span"
								className={cn(
									'text-current truncate shrink',
									textClassName && textClassName
								)}
							>
								{dateFromLabel}
							</Text>
						)}
						{hideToTime ? null : (
							<>
								{hideFromTime ? null : (
									<DirectionalArrow className="w-10 grow" />
								)}
								<Text
									as="span"
									className={cn(
										'text-current truncate shrink',
										textClassName && textClassName
									)}
									variant={dateToLabel ? 'body' : 'muted'}
								>
									{dateToLabel ? dateToLabel : placeholderLabel}
								</Text>
							</>
						)}
					</span>
				) : (
					<Text as="span" variant="muted" className="text-current">
						{placeholderLabel}
					</Text>
				)}

				{showIcon ? <Icon name="calendar" className="fill-current" /> : null}
			</Text>
			{showDifference && dateFrom && dateTo && difference > 0 ? (
				<Text as="small" variant="muted">
					{difference} {pluralize('night', difference)}
				</Text>
			) : null}
		</>
	);
}

DatePlaceholder.propTypes = {
	selected: PropTypes.shape({
		from: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
		to: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
	}),
	hideTime: PropTypes.bool,
	className: PropTypes.string,
	type: PropTypes.oneOf(['date', 'inputdate']),
	handleClick: PropTypes.func,
	showIcon: PropTypes.bool,
	showDifference: PropTypes.bool,
	isValid: PropTypes.bool,
	placeholderLabel: PropTypes.string,
};

DatePlaceholder.defaultProps = {
	selected: {
		from: new Date(),
		to: add(new Date(), { days: 7 }),
	},
	hideTime: false,
	className: '',
	type: 'date',
	handleClick: () => {},
	showIcon: false,
	showDifference: false,
	isValid: true,
	placeholderLabel: 'Select...',
};

export default DatePlaceholder;

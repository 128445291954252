import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import {
	Icon,
	Text,
	Dialog,
	Button,
	Heading,
	TravelPlanMobilePricing,
	SaveForLater,
} from '@components/common';
import { cn } from '@/lib/utils';
import { useBookingStore } from '@/store';
import { usePreviewFetch, useBreakpoint } from '@/hooks';
import { HotelTravelPlanDetails } from '@/components/hotels';
import { FlightTravelPlanDetails } from '@/components/flights';
import { CarhireTravelPlanDetails } from '@/components/carhire';
import HolidayTravelPlanDetails from '@/components/holidays/organisms/travel-plan-details';
/**
 * @typedef {Object} TravelPlanWidgetProps
 * @property {"hotels" | "holidays" | "motorhome-hire" | "car-hire" | "flights"} category
 * @property {()=>void} onContinue
 * @property {boolean} continueIsDisabled
 * @property {boolean} hideMobileDialog
 * @property {boolean} hideTitleIcon
 * @property {boolean} hideFooter
 * @property {boolean} disablePreviewFetch
 * @property {string} className
 * @property {string} contentClassName
 * @property {string} panelClassName
 * @property {string} panelTitleContainerClassName
 * @property {boolean} isLoading
 **/

/**
 * @name TravelPlanWidget
 * @description A widget that shows the travel plan for specific categories
 * @param {TravelPlanWidgetProps} props
 * @param {React.ForwardedRef<HTMLDivElement>} ref
 * @returns {React.JSX.Element}
 * @example
 * <TravelPlanWidget
 * 	 category="hotels"
 * 	 onContinue={onContinue}
 * 	 hideMobileDialog={hideTravelPlan}
 * 	 className="w-full"
 * />
 */

// if children is passed, it will be rendered in the content section of the widget else check for the category component.

function TravelPlanWidget(
	{
		category,
		title,
		children,
		className,
		onContinue,
		hideMobileDialog,
		hideTitleIcon,
		hideFooter,
		disablePreviewFetch,
		hidePanelOnMobile = true,
		contentClassName,
		panelClassName,
		panelTitleContainerClassName,
		continueIsDisabled,
		isLoading,
	},
	ref
) {
	const [showSaveForLater, setShowSaveForLater] = useState(false);
	const [isAtolProtected, setIsAtolProtected] = useState(false);
	const [computedTotal, setComputedTotal] = useState(null);

	const state = useBookingStore((store) => store[category]);

	// Fetch the travel plan details
	usePreviewFetch(category, disablePreviewFetch);

	const handleTotalPrice = useCallback((value) => {
		if (!value) return setComputedTotal(null);
		setComputedTotal(value);
	}, []);
	const handleShowSaveForLater = useCallback(
		(value) => setShowSaveForLater(value),
		[]
	);

	const handleAtolProtected = useCallback(
		(value) => setIsAtolProtected(value),
		[]
	);

	const PlanComponent = useMemo(() => {
		const plans = {
			hotels: HotelTravelPlanDetails,
			'car-hire': CarhireTravelPlanDetails,
			flights: FlightTravelPlanDetails,
			holidays: HolidayTravelPlanDetails,

			// TODO: when adding 'holidays' widget, check for isAtolProtected on booking preview response. If true, set handleAtolProtected(true)
		};

		if (!plans[category]) {
			return () => (
				<div className="w-full p-3 text-center rounded-xl bg-red-50">
					No travel plan available for {category}
				</div>
			);
		}
		return plans[category];
	}, [category]);

	const setTotalPrice = useCallback(() => {
		if (!state?.preview?.overview?.total) {
			setComputedTotal(null);
			return;
		}

		setComputedTotal(state?.preview?.overview?.total);
	}, [state?.preview?.overview?.total]);

	useEffect(() => {
		setTotalPrice();
	}, [setTotalPrice]);

	const isSmallScreen = useBreakpoint('md');

	return hidePanelOnMobile && isSmallScreen ? (
		<>
			{hideMobileDialog ? null : (
				<Dialog
					as="drawer"
					hideCloseBtn
					position="center"
					contentClassName="p-0 m-0 min-h-[30dvh] h-auto w-full"
					renderTrigger={({ DialogTrigger, onOpen }) => (
						<DialogTrigger asChild className="md:hidden">
							<TravelPlanMobilePricing
								ref={ref}
								onOpen={onOpen}
								price={computedTotal}
								onContinue={onContinue}
								isLoading={isLoading || state?.previewLoading}
								continueIsDisabled={
									isLoading ||
									state?.previewLoading ||
									state?.isLoading ||
									continueIsDisabled
								}
								showWarning={!!state?.error || state?.showFlightsChangedWarning ? true : false}
							/>
						</DialogTrigger>
					)}
				>
					{({ CloseButton }) => (
						<div
							className={cn(
								'min-h-[20vh] max-h-screen h-auto w-full overflow-y-auto',
								className
							)}
						>
							<div className="flex items-center justify-between w-full h-16 gap-3 text-white bg-core-blue ">
								<div className="flex items-center h-full gap-3 pl-6">
									{!hideTitleIcon && (
										<Icon
											name={category === 'flights' ? 'flight-fill' : category}
											className="w-5 h-5"
										/>
									)}
									<Heading
										as="h3"
										className="text-xl font-semibold tracking-tighter capitalize font-body leading-extra-tight"
									>
										{title || 'My Travel Plan'}
									</Heading>
								</div>
								<CloseButton
									variant="square"
									className="relative top-0 right-0 w-16 h-full ml-auto bg-dark-grey"
								/>
							</div>
							<div className="p-6 bg-white xl:p-8">
								{children ? (
									children
								) : (
									<PlanComponent
										handleTotalPrice={handleTotalPrice}
										handleShowSaveForLater={handleShowSaveForLater}
										handleAtolProtected={handleAtolProtected}
										isLoading={isLoading}
									/>
								)}
								<Button
									type="button"
									label="Continue"
									onClick={onContinue}
									variant="supporting-yellow"
									disabled={
										continueIsDisabled ||
										isLoading ||
										state?.previewLoading ||
										state?.isLoading
									}
									className="justify-between w-full mt-4"
								/>
							</div>
						</div>
					)}
				</Dialog>
			)}
		</>
	) : (
		<div className={cn('hidden md:block', panelClassName)}>
			<div
				className={cn(
					'hidden min-h-[20vh] h-auto w-full max-w-full animate-fade-in md:block',
					className
				)}
			>
				<div
					className={cn(
						'flex items-center gap-3 bg-core-blue text-white w-full justify-start py-4 px-6 xl:px-12 xl:py-6',
						panelTitleContainerClassName
					)}
				>
					{!hideTitleIcon && (
						<Icon
							className="w-6 h-6"
							name={category === 'flights' ? 'flight-fill' : category}
						/>
					)}
					<Heading
						as="h3"
						className={cn(
							'font-body text-xl font-semibold capitalize leading-extra-tight tracking-tighter',
							hideTitleIcon && 'pl-3 lg:pl-0'
						)}
					>
						{title || 'My Travel Plan'}
					</Heading>
				</div>
				<div
					className={cn(
						'bg-white border border-lighter-grey p-6 xl:px-8',
						contentClassName
					)}
				>
					<div>
						{children ? (
							children
						) : (
							<PlanComponent
								handleTotalPrice={handleTotalPrice}
								handleShowSaveForLater={handleShowSaveForLater}
								handleAtolProtected={handleAtolProtected}
							/>
						)}
					</div>
					{onContinue ? (
						<Button
							type="button"
							label="Continue"
							onClick={onContinue}
							variant="supporting-yellow"
							className="justify-between w-full mt-4"
							disabled={
								continueIsDisabled ||
								isLoading ||
								state?.previewLoading ||
								state?.isLoading
							}
						/>
					) : null}

					{!hideFooter && (
						<>
							<div
								className={cn(
									'mt-5 flex items-center',
									showSaveForLater ? 'justify-between' : 'justify-center'
								)}
							>
								{showSaveForLater && <SaveForLater category={category} />}
								<a
									aria-label="Get in touch with Canadian Affair"
									href="/about-us/contact-us"
									target="_blank"
									rel="noopener noreferrer"
									className="font-bold underline underline-offset-4"
								>
									Get in Touch
								</a>
							</div>
							<div className="flex items-center justify-around mt-3 2xl:mx-3">
								<Text className="flex items-center gap-2 text-dark-grey/30">
									<Icon name="lock" className="" />
									<Text as="span" className="font-bold">
										100% secure
									</Text>
								</Text>
								{category === 'holidays' && isAtolProtected && (
									<Text className="flex items-center gap-2 text-dark-grey/30">
										<Icon name="atol-secure" />
										<Text as="span" className="font-bold">
											ATOL Protected
										</Text>
									</Text>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default forwardRef(TravelPlanWidget);

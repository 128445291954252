import React from 'react';
import SearchWidget from '@/components/common/organisms/search-widget';
import { withPortal } from '@/HOCs/withPortal';
import { cn } from '@/lib/utils';
import useVerticalOverflow from '@/hooks/useVerticalOverflow';

function HeaderSearchWidget() {
	const { observe, isOverflowing } = useVerticalOverflow(true);

	return (
		<SearchWidget
			ref={observe}
			isHeader
			className={cn(
				'max-h-[calc(100vh-3.75rem)] md:max-h-[calc(100vh-6.25rem)]',
				isOverflowing ? 'overflow-y-auto' : ''
			)}
		/>
	);
}

export default withPortal(HeaderSearchWidget, 'booking-header-search');

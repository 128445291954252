/**
 * @module merge
 * @description A collection of functions to merge objects and arrays
 * @category Utils
 */

/**
 * @typedef {Object} mergeParams
 * @property {any[]} toMerge - The object to be merged into the other
 * @property {any[]} toBeMergedInto - The object to be merged into
 */

/**
 * @name mergeArrayOfObjects
 * @description Merges two arrays of objects based on the index
 * @param {mergeParams} params - The parameters for the function
 * @returns {Array} - The merged array
 * @example
 * import { mergeArrayOfObjects } from '@/lib/utils';
 * mergeArrayOfObjects({ toMerge: [{ a: 1 }, { b: 2 }], toBeMergedInto: [{ c: 3 }, { d: 4 }] }); 
 */

export const mergeArrayOfObjects = ({ toMerge, toBeMergedInto, filtered = false }) => {
    // check that both are arrays and not empty
    if (!Array.isArray(toMerge) || !Array.isArray(toBeMergedInto) || !toMerge.length || !toBeMergedInto.length) return []

    return toBeMergedInto.map((item, index) => {
        let filteredToMerge = toMerge;
        if (filtered) {
            filteredToMerge = toMerge.reduce((objs, obj) => {
                const filtered = objs.filter(o => o.type === obj.type);

                if (filtered.length > 0) {
                    objs.map(o => {
                        if (o.type === obj.type) {
                            return { ...o, ...obj };
                        }
                        return o;
                    });
                } else {
                    objs.push(obj);
                }

                return objs;
            }, []);
        }

        Object.keys(item).forEach(key => {
            // if item[key] is string or number return the value of toMerge if it exists
            if (typeof item[key] === 'string' || typeof item[key] === 'number') {
                if (filteredToMerge[index][key]) {
                    item[key] = filteredToMerge[index][key]
                } else {
                    item[key] = item[key]
                }
                return;
            }

            if (filteredToMerge[index][key]) {
                // TODO: to be optimized to handle nested objects
                item[key] = { ...item[key], ...filteredToMerge[index][key] }
            }
        })
        return item
    })

}



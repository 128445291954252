import PropTypes from 'prop-types';
import {
	Text,
	Heading,
	CheckButton,
	Carousel,
	CarouselNext,
	CarouselContent,
	CarouselPrevious,
	Route,
} from '@components/common';
import { cn, formatLocalDate } from '@/lib/utils';
import { useBreakpoint } from '@/hooks';
import { HotelOptionCard } from '@components/holidays';
import { currencyFormatter } from '@/lib/utils/currency';

function HotelOptionList({
	hotel,
	altHotels,
	handleChange,
	className,
	disabled,
	scrollToContainer,
}) {
	if (!altHotels?.length) return null;

	const isSmallDevice = useBreakpoint('xl');
	const formattedStartDate = hotel?.startDate
		? formatLocalDate(hotel.startDate, 'dd MMMM yyyy')
		: null;
	const formattedEndDate = hotel?.endDate
		? formatLocalDate(hotel.endDate, 'dd MMMM yyyy')
		: null;

	return (
		<div className="flex flex-col w-full">
			{formattedStartDate || formattedEndDate ? (
				<Route
					className="hidden px-3 py-6 md:px-5 md:flex"
					iconName="hotel"
					renderFrom={() => (
						<Text as="span" className="flex flex-col">
							<Text as="span">Check in</Text>
							<Text as="span" className="font-bold">
								{formattedStartDate ? formattedStartDate : '-'}
							</Text>
						</Text>
					)}
					renderTo={() => (
						<Text as="span" className="flex flex-col items-end">
							<Text as="span">Check out</Text>
							<Text as="span" className="font-bold">
								{formattedEndDate ? formattedEndDate : '-'}
							</Text>
						</Text>
					)}
				/>
			) : null}

			<div className="hidden border-t border-b border-t-light-grey border-b-light-grey py-7.5 mt-0 mb-6 md:flex px-3 md:px-5">
				<Heading
					as="h3"
					className="py-0 my-0 text-3xl md:text-4xl xl:text-5xl font-body leading-[2.4rem] tracking-extra-tight"
				>
					Select your hotel
				</Heading>
			</div>

			<Carousel
				opts={{
					loop: true,
					align: 'start',
					active: false,
					breakpoints: {
						'(min-width: 1280px)': { active: true },
					},
				}}
				className={cn('relative w-full md:px-5', className && className)}
			>
				<CarouselContent className="relative flex-col h-full ml-0 z-1 lg:-ml-6 lg:flex-row lg:flex-wrap xl:flex-nowrap md:gap-y-3 lg:gap-y-6 xl:gap-y-0">
					{altHotels.map((altHotel, index) => (
						<HotelOptionCard
							key={altHotel?.code || index}
							index={index}
							isSelected={altHotel?.code === hotel?.code}
							altHotel={altHotel}
							handleChange={handleChange}
							disabled={disabled}
							scrollToContainer={scrollToContainer}
						/>
					))}
				</CarouselContent>

				{!isSmallDevice && (
					<>
						<CarouselPrevious
							variant="outline"
							className={cn(
								'right-20.5 left-auto -top-[3.0625rem] -translate-y-full h-12.5 w-12.5 p-3 bg-white border border-gray-400 rounded-full disabled:!-translate-y-full'
							)}
						/>
						<CarouselNext
							variant="outline"
							className={cn(
								'right-5 -top-[3.0625rem] -translate-y-full h-12.5 w-12.5 p-3 bg-white border border-gray-400 rounded-full disabled:!-translate-y-full'
							)}
						/>
					</>
				)}
			</Carousel>

			<Text
				as="span"
				className="flex px-5 mb-5 font-semibold leading-snug tracking-tighter md:mt-6 md:mb-0 text-dark-grey"
			>
				* average price per person per night
			</Text>
		</div>
	);
}

HotelOptionList.propTypes = {
	hotel: PropTypes.object,
	altHotels: PropTypes.array,
	handleChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	disabled: PropTypes.bool,
};

HotelOptionList.defaultProps = {
	hotel: {},
	altHotels: [],
	disabled: false,
};

export default HotelOptionList;

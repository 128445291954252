import { forwardRef } from 'react';

import { Button, Skeleton, Text } from '@components/common';
import { cn, currencyFormatter } from '@/lib/utils';

/**
 * @typedef {Object} TravelPlanMobilePricingProps
 * @property {number} price
 * @property {()=>void} onOpen
 * @property {()=>void} onContinue
 * @property {string} className
 * @property {boolean} isLoading
 **/

/**
 * @name TravelPlanMobilePricing
 * @description A mobile pricing component that shows the travel plan price & a continue button
 * @param {TravelPlanMobilePricingProps} props
 * @param {React.ForwardedRef<HTMLDivElement>} ref
 * @returns {React.JSX.Element}
 * @example
 * <TravelPlanMobilePricing
 * 	 price={1587.23}
 * 	 onOpen={onOpen}
 * 	 onContinue={onContinue}
 * 	 className="w-full"
 * />
 */
function TravelPlanMobilePricing(props, ref) {
	let {
		price,
		onOpen,
		onContinue,
		className,
		isLoading,
		continueIsDisabled,
		showWarning
	} = props;
	return (
		<div
			ref={ref}
			className={cn(
				'h-fit w-full bg-supporting-yellow items-center p-5 flex justify-between',
				className
			)}
		>
			<div className="flex flex-col items-start">
				{isLoading ? (
					<Skeleton className="h-6 w-20 bg-lightish-grey" />
				) : (
					<Text as="span" className="text-2xl font-semibold tracking-tighter">
						{currencyFormatter({
							amount: price,
						})}
					</Text>
				)}

				<Button
					hideIcon={!showWarning ? true : false}
					iconName="warning"
					disableAnimation
					iconClassName="stroke-core-red stroke-2 h-5 group-hover:stroke-3 transition-all duration-200"
					onClick={onOpen}
					variant="unstyled"
					type="button"
					label="View Summary"
					labelClassName="group-hover:font-bold transition-all duration-180"
					className={cn(
						'flex-row-reverse underline underline-offset-4 pt-0.5',
						showWarning ? 'text-core-red' : 'text-core-blue',
					)}
				/>
			</div>
			<Button
				variant="core-blue"
				type="button"
				label="Continue"
				disabled={isLoading || continueIsDisabled}
				onClick={onContinue}
				className="!text-supporting-yellow h-12"
			/>
		</div>
	);
}

TravelPlanMobilePricing.displayName = 'TravelPlanMobilePricing';

export default forwardRef(TravelPlanMobilePricing);

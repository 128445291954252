import { useMemo } from 'react';

import { cn, currencyFormatter } from '@/lib/utils';
import { Button, Icon, Text } from '@/components/common';
import SelectedTick from '@/components/common/molecules/selected-tick';

/**
 * @typedef {Object} FlightMobileFareCardProps
 * @property {Object} option
 * @property {string} selected
 * @property {string} selectedCabin
 * @property {(value: string)=>void} onSelect
 */

/**
 * @name FlightMobileFareCard
 * @description Renders the flight mobile fare card component
 * @param {FlightMobileFareCardProps} props
 * @returns {React.JSX.Element | null}
 * @example
 * <FlightMobileFareCard option={option} selected={selected} />
 */

function FlightMobileFareCard({ flight, option, onSelect, selected }) {
	const isSelected = useMemo(() => {
		if (!selected) return false;
		return selected === `${flight?.key}-${option?.cabin?.code}`;
	}, [selected]);

	return (
		<div className="flex flex-col gap-2 p-6 even:bg-light-grey">
			<div className="flex items-end justify-between">
				<Text className="flex flex-col items-start gap-1">
					<Text
						as="span"
						className="text-2xl font-bold leading-extra-tight tracking-extra-tight"
					>
						{option?.cabin?.displayName || option?.cabin?.name}
					</Text>
				</Text>
				<Text className="text-2xl font-bold leading-extra-tight tracking-extra-tight">
					{currencyFormatter({
						amount: parseFloat(option?.pricing?.adult), // TODO: Do we need to get the total amount for all adults/children/infants?
					})}
				</Text>
			</div>

			{option?.cabin?.description && (
				<Text className="text-dark-grey">{option.cabin.description}</Text>
			)}

			{option.cabin?.features?.length > 0 ? (
				<ul className="flex flex-col gap-1.5">
					{option.cabin.features.map((feature, idx) => (
						<li className="flex items-center gap-2" key={`feature-${idx}`}>
							<Icon name="maple-leaf" className="w-5 h-5 shrink-0" />
							<Text className="text-dark-grey">{feature}</Text>
						</li>
					))}
				</ul>
			) : null}

			<div className="flex items-center justify-between">
				<Button
					label="Confirm"
					variant="supporting-yellow"
					onClick={() => {
						if (typeof onSelect === 'function') {
							// get the value from the selected Options
							onSelect(option?.cabin?.code);
						}
					}}
				/>

				<SelectedTick isSelected={isSelected} />
			</div>
		</div>
	);
}
export default FlightMobileFareCard;

import { format } from 'date-fns';
/**
 * Reusable hard-coded select options.
 */

export const ONE_TO_NINE = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
];

export const ZERO_TO_NINE = [
    { value: 0, label: '0' },
    ...ONE_TO_NINE
];

export const HOLIDAY_DURATIONS = [
    { value: 1, label: '1 Night' },
    { value: 2, label: '2 Nights' },
    { value: 3, label: '3 Nights' },
    { value: 4, label: '4 Nights' },
    { value: 5, label: '5 Nights' },
    { value: 6, label: '6 Nights' },
    { value: 7, label: '7 Nights' },
];

export const FLIGHT_HOTEL_DURATIONS = [
    { value: 7, label: '7 Nights' },
    { value: 14, label: '14 Nights' },
];

export const FIND_MY_HOLIDAY_DURATIONS = [
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
    { value: 13, label: '13' },
    { value: 14, label: '14' },
    { value: 15, label: '15' },
    { value: 16, label: '16' },
    { value: 17, label: '17' },
    { value: 18, label: '18' },
    { value: 19, label: '19' },
    { value: 20, label: '20' },
    { value: 21, label: '21' },
];

export const MONTHS = Array.from({ length: 12 }, (_, i) => {
    const date = new Date(0, i);
    return {
        label: format(date, 'MMMM'),
        value: format(date, 'MMMM'),
    };
});


export const STAR_RATINGS = [
    { value: '5', label: '5 Star' },
    { value: '4', label: '4 Star' },
    { value: '3', label: '3 Star' },
    { value: '2', label: '2 Star' },
    { value: '1', label: '1 Star' },
    { value: 'unrated', label: 'Unrated' },
]


export const LOCATIONS = [
    { value: 'toronto', label: 'Toronto (4)' },
    { value: 'vancouver', label: 'Vancouver (6)' },
    { value: 'calgary', label: 'Calgary (3)' },
    { value: 'montreal', label: 'Montreal (4)' },
    { value: 'banff', label: 'Banff (2)' },
]

export const ATTRACTIONS = [
    { value: 'northern_lights', label: 'Northern Lights (3)' },
    { value: 'rockies', label: 'Rockies (8)' },
    { value: 'niagara_falls', label: 'Niagara Falls (2)' },
    { value: 'stanley_park', label: 'Stanley Park (4)' },
]

export const EXPERIENCES_ACTIVITIES = [
    { value: 'skiing', label: 'Skiing (3)' },
    { value: 'wildlife', label: 'Wildlife (8)' },
    { value: 'rockies', label: 'Rockies (2)' },
    { value: 'cycling', label: 'Cycling (4)' },
    { value: 'climbing', label: 'Climbing (3)' },
    { value: 'horse_riding', label: 'Horse Riding (8)' },
    { value: 'zip_lining', label: 'Zip Lining (2)' },
    { value: 'canoeing_kayaking', label: 'Canoeing & Kayaking (4)' },
    { value: 'whale_watching', label: 'Whale Watching (8)' },
    { value: 'bear_watching', label: 'Bear Watching (2)' },
]

export const HOLIDAY_TYPES = [
    { value: 'rail', label: 'rail (18)', icon: 'rail' },
    { value: 'cruise', label: 'Cruise (2)', icon: 'cruise' },
    { value: 'rocky_mountaineer', label: 'Rocky Mountaineer (22)', icon: 'holiday' },
    { value: 'fly_drive', label: 'Fly Drive (16)', icon: 'fly-drive' },
    { value: 'motorhome', label: 'Motorhome (5)', icon: 'motorhome' },
    { value: 'adventure', label: 'Adventure (12)', icon: 'adventure' },
    { value: 'city_break', label: 'City Break (6)', icon: 'hotel' },
    { value: 'escorted_tour', label: 'Escorted Tour (6)', icon: 'tour' },
    { value: 'ski', label: 'Ski (18)', icon: 'ski' },
    { value: 'wildlife', label: 'Wildlife (9)', icon: 'wildlife' },
    { value: 'winter', label: 'Winter (2)', icon: 'winter' },
]


export const CAPACITY = [
    { value: '5_passengers', label: '5+ Passengers (4)' },
    { value: '1-2_passengers', label: '1 - 2 Passengers (6)' },
]

// hotel search sort options
export const SORT_OPTIONS = [
    {
        label: 'Price - Low to High',
        value: 'price:asc',
    },
    {
        label: 'Price - High to Low',
        value: 'price:desc',
    },
    {
        label: 'Name - Ascending',
        value: 'pagetitle:asc',
    },
    {
        label: 'Name - Descending',
        value: 'pagetitle:desc',
    },
];

// hotel search page options
export const PAGE_OPTIONS = [
    {
        label: '10',
        value: '10',
    },
    {
        label: '20',
        value: '20',
    },
];


export const POPULAR_SEARCHES = [
    {
        icon: 'lite-search',
        label: 'Holidays in Canada',
        value: 'Holidays in Canada',
    },
    {
        icon: 'lite-search',
        label: 'City Breaks in Canada',
        value: 'City breaks in Canada',
    },
    {
        icon: 'lite-search',
        label: 'The Rockies',
        value: 'The rockies',
    },
    {
        icon: 'lite-search',
        label: 'Niagara Falls',
        value: 'Niagara falls',
    },
    {
        icon: 'location',
        label: 'Toronto',
        value: 'Toronto',
    },
    {
        icon: 'location',
        label: 'Calgary',
        value: 'Calgary',
    },
    {
        icon: 'page',
        label: 'Top 10 things to do in Canada',
        value: 'Top 10 things to do in Canada',
    },
];


export const HOLIDAY_DESTINATIONS_GOING_TO = [
    {
        label: "Toronto",
        value: "TOR-CITY",
    },
    {
        label: "Calgary",
        value: "CAL-CITY",
    },
    {
        label: "Vancouver",
        value: "VAN-CITY",
    }
]

export const SEARCH_HOLIDAY_TYPES = [
    {
        label: "City Break",
        value: "holidays",
    },
    // {
    //     label: "Motorhome Holidays",
    //     value: "motorhome",
    // },
    // {
    //     label: "Other",
    //     value: "multicentre",
    // }
]

export const HOLIDAY_DESTINATIONS_DEPARTING = [
    {
        label: "London Gatwick",
        value: "LGW-AIR",
    },
    {
        label: "London Heathrow",
        value: "LHR-AIR",
    },
    {
        label: "London (All)",
        value: "LGW-AIR,LHR-AIR",
    },
    {
        label: "Manchester",
        value: "MAN-AIR",
    },
    {
        label: "Glasgow",
        value: "GLA-AIR",
    },
    {
        label: "Dublin",
        value: "DUB-AIR",
    },
]


export const CHELSEA_HOTEL_ROOMS = [
    {
        label: "Standard Room",
        value: "STD",
    },
    {
        label: "Deluxe Room",
        value: "DLX",
    },
    {
        label: "Club Room",
        value: "CLB",
    },
    {
        label: "One Bedroom Suite",
        value: "1BD",
    },
    {
        label: "Two Bedroom Suite",
        value: "2BD",
    },
    {
        label: "Three Bedroom Suite",
        value: "3BD",
    },
    {
        label: "Family Suite",
        value: "FAM",
    },
    {
        label: "Deluxe Family Suite",
        value: "DFM",
    },
]


export const FLIGHT_ONLY_AIRPORT_OPTIONS = [
    {
        label: "London Gatwick",
        value: "LGW-AIR",
        country: "UK",
    },
    {
        label: "Manchester",
        value: "MAN-AIR",
        country: "UK",
    },
    {
        label: "Glasgow",
        value: "GLA-AIR",
        country: "UK",
    },
    {
        label: "Dublin",
        value: "DUB-AIR",
        country: "UK",
    },
    {
        label: "Toronto",
        value: "YYZ-AIR",
        country: "CA",
    },
    {
        label: "Montreal",
        value: "YUL-AIR",
        country: "CA",
    },
    {
        label: "Calgary",
        value: "YYC-AIR",
        country: "CA",
    },
    {
        label: "Quebec City",
        value: "YQB-AIR",
        country: "CA",
    },
    {
        label: "Vancouver",
        value: "YVR-AIR",
        country: "CA",
    },
    {
        label: "Edmonton",
        value: "YEG-AIR",
        country: "CA",
    },
    {
        label: "Halifax",
        value: "YHZ-AIR",
        country: "CA",
    },
    {
        label: "Ottawa",
        value: "YOW-AIR",
        country: "CA",
    },
];

export const TITLE_OPTIONS = [
    {
        label: 'Mr',
        value: 'mr',
    },
    {
        label: 'Mrs',
        value: 'mrs',
    },
    {
        label: 'Miss',
        value: 'miss',
    },
    {
        label: 'Ms',
        value: 'ms',
    },
    {
        label: 'Dr',
        value: 'dr',
    },
]

export const GENDER_OPTIONS = [
    {
        label: 'Male',
        value: 'SEX_MALE',
    },
    {
        label: 'Female',
        value: 'SEX_FEMALE',
    },
    // {
    //     label: 'Other',
    //     value: 'SEX_UNKNOWN',
    // },
]


export const WHO_OPTIONS = [
    {
        label: 'Adult (Age 16+)',
        value: 'adults',
    },
    {
        label: 'Youth (Age 12-15)',
        value: 'youth',
    },
    {
        label: 'Child (Age 2-11)',
        value: 'children',
    },
    // {
    //     label: 'Child (Age 3-15)',
    //     value: 'children',
    // },
    {
        label: 'Infant (Up to 2)',
        value: 'infants',
    },
];

export const HOTEL_WHO_OPTIONS = [
    {
        label: 'Adult (Age 18+)',
        value: 'adults',
    },
    // {
    //     label: 'Youth (Age 12-15)',
    //     value: 'youth',
    // },
    // {
    //     label: 'Child (Age 3-11)',
    //     value: 'children',
    // },
    {
        label: 'Child (Age 2-17)',
        value: 'children',
    },
    {
        label: 'Infant (Age < 2)',
        value: 'infants',
    },
];

export const FLIGHT_SPECIAL_ASSISTANCE_OPTIONS = [
    {
        label: 'No thanks',
        value: '',
    },
    {
        label: 'Assistance within airport',
        value: 'WCHR',
    },
    {
        label: 'Manual wheelchair',
        value: 'WCMP',
    },
    {
        label: 'Assistance onto aircraft, cannot use stairs',
        value: 'WCHS',
    },
];


export const HOLIDAY_FLEXIBLE_TRAVEL_OPTIONS = [
    {
        label: "No",
        value: "no",
    },
    {
        label: "Yes within a month",
        value: "withinMonth",
    },
    {
        label: "Yes within a week",
        value: "withinWeek",
    },
]

export const HOLIDAY_ENQUIRY_DEPARTING = [
    {
        label: "London (Any)",
        value: "LON-AIR",
    },
    {
        label: "London Gatwick",
        value: "LGW-AIR",
    },
    {
        label: "London Heathrow",
        value: "LHR-AIR",
    },
    {
        label: "Manchester",
        value: "MAN-AIR",
    },
    {
        label: "Glasgow",
        value: "GLA-AIR",
    },
    {
        label: "Other",
        value: "OTHER-AIR",
    },
]


export const CABIN_OPTIONS = [
    {
        id: 'eco-cabin',
        label: 'Eco',
        value: 'eco',
    },
    {
        id: 'club-cabin',
        label: 'Club',
        value: 'club',
    },
];

import { useMemo, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import {
	Text,
	Button,
	PageWrapper,
	BookingPageWrapper,
} from '@/components/common';
import { getNextStep } from '@/lib/utils';
import { useBookingStore } from '@/store';
import { SeatSelectionProvider } from '@/context';
import { getSteps, holidaySteps, flightSteps } from '@/lib/steps';
import { SelectionList } from '@components/flights';
import {
	useBackNavigate,
	useFetch,
	useSkipPage,
	useMetaDescription,
} from '@/hooks';

function SeatSelectionPage() {
	const navigate = useNavigate();
	const { params } = useParams();
	const { pathname } = useLocation();
	const { setBookingState, submitBooking, requestParams } = useBookingStore();

	const category = params?.category
		? params?.category
		: pathname && pathname.split('/')[2];
	const state = useBookingStore((store) => store[category]);
	const pageSteps = useMemo(() => getSteps(category), [category]);

	useMetaDescription([
		'Seat Selection',
		category === 'holidays' ? 'Holidays' : 'Flights',
		'Canadian Affair',
	]);

	// digest the preview object to get the flights
	const seatParams = useMemo(
		() => requestParams('seat-selection', category),
		[category]
	);

	const seatSelectionAvailable = useMemo(() => {
		// check if option plus is available on any flight direction
		const items =
			category === 'holidays'
				? state?.selected?.items?.[0].items
				: state?.selected?.items;
		const flights = items.filter((item) => item?.type === 'flight');
		let availability = false;

		// skip if items is not available
		if (!flights) return availability;

		availability = flights.some((item) => {
			const outboundAvailable = item?.outbound?.isSeatResPossible;
			const inboundAvailable = item?.inbound?.isSeatResPossible;

			return outboundAvailable || inboundAvailable;
		});

		return availability;
	}, [state?.selected?.items]);

	// skip page
	const { skip } = useSkipPage({
		steps: pageSteps,
		disableSkip: seatSelectionAvailable, // disable skip if seat selection is available
		skipCallback: () => {
			// clear previous payment details
			const newState = {
				...state,
				shouldBuildParams: true,
				paymentDetails: undefined,
			};
			setBookingState(
				category,
				newState,
				category === 'holidays'
					? 'HOLIDAYS_PAYMENT_RESET'
					: 'FLIGHTS_PAYMENT_RESET'
			);
			// submit booking data
			submitBooking(category);
		},
	});

	const { data, error, isLoading } = useFetch({
		key: 'seating',
		method: 'POST',
		useBody: true,
		params: seatParams,
		config: {
			enabled: !!seatParams || !seatSelectionAvailable,
		},
		format: (res) => res?.data,
		onData: (data) => {
			if (typeof data?.sectors === 'undefined') {
				// skip seat selection step if there aren't any baggage options available
				skip(true);
				return;
			}

			const sectorsAvailable =
				data?.sectors?.outbound?.length >= 1 ||
				data?.sectors?.inbound?.length >= 1;

			if (!sectorsAvailable) {
				// seating options unavailable - skip step
				skip(true);
				return;
			}
		},
	});

	const onContinue = () => {
		// clear previous payment details
		const newState = {
			...state,
			shouldBuildParams: true,
			paymentDetails: undefined,
		};

		// reset holiday flow flight warning indicator
		if (category === 'holidays' && state?.showFlightsChangedWarning) {
			newState.showFlightsChangedWarning = false;
		}

		setBookingState(
			category,
			newState,
			category === 'holidays'
				? 'HOLIDAYS_PAYMENT_RESET'
				: 'FLIGHTS_PAYMENT_RESET'
		);

		// submit booking data
		submitBooking(category);

		// get next step
		const nextStep = getNextStep(
			category === 'holidays' ? holidaySteps : flightSteps,
			pathname
		);
		navigate(nextStep?.to);
	};

	const { handleBack, previousStep } = useBackNavigate(pageSteps);

	const isDisabled =
		state?.isLoading || state?.previewLoading || isLoading || error;

	const continueIsDisabled =
		isDisabled || state?.bookingDisabled || state?.showOptionPlusSeatError;

	// determine if seating is required (due to option plus), and we don't have a seat map
	const getIsRequiredAndMissingSeatMap = (type) => {
		const items =
			category === 'holidays'
				? state?.selected?.items?.[0].items
				: state?.selected?.items;
		const flights = items.filter((item) => item?.type === 'flight');

		const hasOptionPlus = flights.reduce((acc, item) => {
			if (item[type]) {
				acc = item[type]?.hasOptionPlus || false;
			}
			return acc;
		}, false);
		if (!hasOptionPlus) return false;

		const hasSeatMapOnAnySector = data?.sectors?.[type]?.some((sector) => {
			if (!sector?.template) return false;

			const template = data?.templates[sector?.template];
			return template ? true : false;
		});

		return hasOptionPlus && !hasSeatMapOnAnySector;
	};

	useEffect(() => {
		const items =
			category === 'holidays'
				? state?.selected?.items?.[0].items
				: state?.selected?.items;
		const flights = items.filter((item) => item?.type === 'flight');

		// reset error display on mount
		if (!flights || !data?.templates) {
			const newState = {
				showOptionPlusSeatError: false,
			};
			setBookingState(
				category,
				newState,
				category === 'holidays'
					? 'HOLIDAYS_RESET_OPTION_PLUS_SEAT_ERROR'
					: 'FLIGHTS_RESET_OPTION_PLUS_SEAT_ERROR'
			);
			return;
		}

		// determine if we need to show option plus error
		const outboundMissing = getIsRequiredAndMissingSeatMap('outbound');
		const inboundMissing =
			category === 'holidays'
				? getIsRequiredAndMissingSeatMap('inbound')
				: !state?.isOneWay
					? getIsRequiredAndMissingSeatMap('inbound')
					: false;

		const newState = {
			showOptionPlusSeatError: outboundMissing || inboundMissing,
		};

		setBookingState(
			category,
			newState,
			category === 'holidays'
				? 'HOLIDAYS_SET_OPTION_PLUS_SEAT_ERROR'
				: 'FLIGHTS_SET_OPTION_PLUS_SEAT_ERROR'
		);
	}, [data, state?.selected?.items]);

	// run once on mount
	useEffect(() => {
		const newState = {
			shouldBuildParams: true,
		};

		setBookingState(category, newState, 'TRIGGER_PREVIEW_ONMOUNT');
	}, []);

	return (
		<BookingPageWrapper
			steps={pageSteps}
			category={category}
			title="Seat Selection"
			onContinue={onContinue}
			previousPage={previousStep}
			continueIsDisabled={continueIsDisabled}
			disablePreviewFetch={isLoading || error}
		>
			<PageWrapper
				error={error}
				loading={isLoading}
				loadingText="Fetching flight seats..."
				loaderClassName="h-96 border border-lighter-grey"
				errorClassName="border border-lighter-grey"
			>
				<div className="flex flex-col max-w-6xl gap-6">
					<Text>
						Forget about the rush to be at check in early to get a good seat,
						pre-selecting your seat means that you'll know in advance where
						you'll be sitting for the flight. So you can enjoy the day of
						departure and relax at check in for this small price... it's worth
						it.
					</Text>

					<SeatSelectionProvider seatData={data} category={category}>
						<SelectionList category={category} useParentFlight={false} />
					</SeatSelectionProvider>

					<div className="flex flex-col items-start justify-between gap-2 md:flex-row md:items-center md:px-0">
						<Button
							label="Continue"
							disabled={continueIsDisabled}
							onClick={onContinue}
							variant="supporting-yellow"
							className="hidden md:flex"
						/>
						<Button
							disableAnimation
							variant="unstyled"
							disabled={isDisabled}
							onClick={handleBack}
							iconName="arrow-left"
							className="flex-row-reverse"
							labelClassName="font-normal"
							label={`Back to ${previousStep?.name}`}
						/>
					</div>
				</div>
			</PageWrapper>
		</BookingPageWrapper>
	);
}
export default SeatSelectionPage;

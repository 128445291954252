import { useDisclosure } from '@/hooks';
import PropTypes from 'prop-types';
import {
	Heading,
	Text,
	Icon,
	Badge,
	Button,
	MediaCarousel,
	RteStyledContent,
	CheckButton,
	Image,
	CarouselItem,
} from '@/components/common';
import { useBreakpoint } from '@/hooks';
import { cn, formatCarouselSlides } from '@/lib/utils';
import { useMemo } from 'react';
import { currencyFormatter } from '@/lib/utils/currency';
import SelectedTick from '@/components/common/molecules/selected-tick';

function HotelOptionCard({
	index,
	altHotel,
	isSelected,
	className,
	handleChange,
	scrollToContainer,
	disabled,
}) {
	const { onToggle, isOpen } = useDisclosure(false);
	const isSmallDevice = useBreakpoint('lg');

	const slides = useMemo(
		() => formatCarouselSlides(altHotel?.gallery),
		[altHotel?.gallery]
	);

	return (
		<CarouselItem
			key={index}
			className={cn(
				'pl-0 lg:basis-1/2 xl:basis-1/3 lg:pl-6 xl:grow',
				className
			)}
		>
			<div className="flex flex-col w-full h-full gap-4">
				{slides?.length > 0 && (
					<div className="relative flex flex-col items-end justify-start w-full overflow-hidden aspect-w-12 aspect-h-8">
						<Image
							width="100%"
							height="100%"
							className="absolute inset-0 block object-cover w-full h-full"
							src={slides[0].src}
							alt={slides[0].alt}
						/>
						{/* <div className="absolute inset-0 w-full">
							<div className="w-full h-full max-h-full">
								<MediaCarousel
									slides={slides}
									theme="dark"
									className="h-full"
								/>
							</div>
						</div> */}
					</div>
				)}

				<div className="flex flex-col items-start flex-1 gap-1.5 px-5 pt-1 pb-5 md:p-0">
					<Heading
						as="h2"
						className="mb-0 text-3xl font-bold font-body leading-extra-tight tracking-extra-tight text-dark-grey"
					>
						{altHotel?.pagetitle || altHotel?.name}
					</Heading>
					{altHotel?.address && (
						<Text
							as="p"
							className="leading-[1.3] font-medium text-dark-grey/65 tracking-less-tight"
						>
							{altHotel?.address}
						</Text>
					)}
					<div className="flex flex-row items-stretch gap-1.5">
						{altHotel?.rating && altHotel.rating != 'unrated' && (
							<Badge variant="core-blue" text="">
								{new Array(altHotel.rating).fill(0).map((_, i) => (
									<Icon
										key={i}
										name="light-filled-star"
										className="w-[0.8125rem] h-[0.8125rem]"
									/>
								))}
							</Badge>
						)}
					</div>
					<div className="flex flex-col items-start gap-1 py-2 grow">
						{altHotel?.highlights?.length > 0 && (
							<div className="flex flex-col gap-1 pb-1">
								{altHotel.highlights.map((highlight) => (
									<div key={highlight?.id} className="flex items-center">
										<Text
											as="span"
											className="leading-[1.3] font-medium text-dark-grey tracking-less-tight"
										>
											{highlight?.title}
										</Text>
									</div>
								))}
							</div>
						)}
						{isOpen ? (
							// TODO: create a separate hotel details component
							<div>
								{altHotel?.facilities?.length > 0 && (
									<div>
										<Heading as="h4" className="text-xl font-bold font-body">
											Facilities
										</Heading>
										<div className="flex flex-col gap-1">
											{altHotel.facilities.map((facility) => (
												<div key={facility?.id} className="flex items-center">
													<Text
														as="span"
														className="leading-[1.3] font-medium text-dark-grey"
													>
														{facility?.title}
													</Text>
												</div>
											))}
										</div>
									</div>
								)}
								{altHotel?.introtext && (
									<div className="my-2">
										<Heading as="h4" className="text-xl font-bold font-body">
											About the hotel
										</Heading>
										<Text className="text-base font-normal leading-snug tracking-normal font-body text-dark-grey/75">
											{altHotel.introtext}
										</Text>
									</div>
								)}
								{altHotel?.nearby && (
									<div>
										<Heading as="h4" className="text-xl font-bold font-body">
											What's nearby
										</Heading>
										<RteStyledContent
											variant="normal"
											dangerouslySetInnerHTML={{ __html: altHotel.nearby }}
										/>
									</div>
								)}
							</div>
						) : null}
						{(altHotel?.facilities?.length > 0 ||
							altHotel?.introtext ||
							altHotel?.nearby) && (
							<Button
								variant="unstyled"
								onClick={onToggle}
								className="gap-1 my-0 hover:opacity-75"
							>
								<Text as="span" className="text-sm leading-tight">
									{isOpen ? 'Hide' : 'Info'}
								</Text>
								<Icon
									className="w-3 h-3"
									name={isOpen ? 'chevron-up' : 'chevron-down'}
								/>
							</Button>
						)}
					</div>
					<Text
						as="span"
						className="text-2xl font-bold leading-snug tracking-tighter text-dark-grey"
					>
						{(altHotel?.priceDiffPerPersonPerNight || 0) >= 0 ? '+' : ''}
						{currencyFormatter({
							amount: altHotel?.priceDiffPerPersonPerNight || 0,
						})}
						*
					</Text>

					{isSmallDevice ? (
						<div className="flex items-center justify-between w-full">
							<Button
								label="Select"
								variant="supporting-yellow"
								onClick={() => handleChange(altHotel)}
								disabled={disabled}
							/>
							<SelectedTick isSelected={isSelected} />
						</div>
					) : (
						<CheckButton
							checked={isSelected}
							onChange={() => {
								if (
									scrollToContainer &&
									typeof scrollToContainer === 'function'
								) {
									scrollToContainer();
								}
								handleChange(altHotel);
							}}
							isDisabled={disabled}
							className="gap-2"
							renderText={(checked) => (
								<Text
									as="span"
									className="text-lg leading-snug tracking-tighter"
								>
									{checked ? 'Selected' : 'Select'}
								</Text>
							)}
						/>
					)}
				</div>
			</div>
		</CarouselItem>
	);
}

HotelOptionCard.propTypes = {
	altHotel: PropTypes.object.isRequired,
	isSelected: PropTypes.bool,
	className: PropTypes.string,
	handleChange: PropTypes.func,
	scrollToContainer: PropTypes.func,
	disabled: PropTypes.bool,
};

HotelOptionCard.defaultProps = {
	disabled: false,
};

export default HotelOptionCard;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigation } from 'react-day-picker';
import {
	format,
	isAfter,
	isBefore,
	isEqual,
	isSameMonth,
	startOfMonth,
	startOfToday,
	subMonths,
} from 'date-fns';
import { Text, Button, Calendar } from '@components/common';
import { cn } from '@/lib/utils';

function CalendarNav({
	activeMonth,
	maxDate,
	onChange,
	className,
	renderPrice,
}) {
	const firstEnabledMonth = startOfMonth(startOfToday());
	const lastEnabledMonth = maxDate ? startOfMonth(maxDate) : null;
	const [selectedMonth, setSelectedMonth] = useState(() => {
		if (activeMonth) {
			return activeMonth;
		}
		return subMonths(new Date(), 1);
	});

	const handleMonthChange = (month) => {
		setSelectedMonth(month);

		if (onChange && typeof onChange === 'function') {
			onChange(month);
		}
	};

	useEffect(() => {
		if (!activeMonth) return;
		if (isEqual(selectedMonth, activeMonth)) return;

		handleMonthChange(activeMonth);
	}, [activeMonth]);

	return (
		<div>
			<div
				className={cn(
					'w-full h-14 bg-white border-t border-b border-neutral-800 border-opacity-20 justify-center items-center inline-flex relative',
					className
				)}
			>
				<Calendar
					className="flex items-center justify-center w-full"
					numberOfMonths={1}
					defaultMonth={selectedMonth}
					onMonthChange={handleMonthChange}
					components={{
						Day: () => null,
						Head: () => null,
						IconLeft: ({ ...props }) => (
							<Button
								variant="unstyled"
								iconName="arrow-left"
								className="grow shrink basis-0"
								{...props}
							/>
						),
						IconRight: ({ ...props }) => (
							<Button
								variant="unstyled"
								iconName="arrow-right"
								className="grow shrink basis-0"
								{...props}
							/>
						),
						Caption: () => {
							const { currentMonth, nextMonth, previousMonth, goToMonth } =
								useNavigation();

							return (
								<div className="flex items-center justify-center w-full h-full gap-2 mx-auto">
									<Button
										hideIcon
										variant="unstyled"
										onClick={() => goToMonth(previousMonth)}
										className={cn(
											'flex flex-col h-16 justify-center items-center gap-1 grow shrink basis-0 py-3 rounded-2xl bg-transparent text-dark-grey z-2',
											isSameMonth(previousMonth, selectedMonth) &&
												'bg-slate-800 text-white max-w-fit px-6'
										)}
										disabled={isBefore(previousMonth, firstEnabledMonth)}
									>
										<Text
											as="span"
											className="text-xs font-black leading-none tracking-tight text-opacity-50 uppercase"
										>
											{format(previousMonth, 'MMM yyyy')}
										</Text>
										<Text className="text-lg font-bold leading-snug">
											{renderPrice(previousMonth)}
										</Text>
									</Button>
									<Button
										hideIcon
										variant="unstyled"
										onClick={() => goToMonth(currentMonth)}
										className={cn(
											'flex flex-col h-16 justify-center items-center gap-1 grow shrink basis-0 py-3 rounded-2xl bg-transparent text-dark-grey z-2',
											isSameMonth(currentMonth, selectedMonth) &&
												'bg-slate-800 text-white max-w-fit px-6'
										)}
									>
										<Text
											as="span"
											className="text-xs font-black leading-none tracking-tight text-opacity-50 uppercase"
										>
											{format(currentMonth, 'MMM yyyy')}
										</Text>
										<Text as="span" className="text-lg font-bold leading-snug">
											{renderPrice(currentMonth)}
										</Text>
									</Button>
									<Button
										hideIcon
										variant="unstyled"
										onClick={() => goToMonth(nextMonth)}
										className={cn(
											'flex flex-col h-16 justify-center items-center gap-1 grow shrink basis-0 py-3 rounded-2xl bg-transparent text-dark-grey z-2',
											isSameMonth(nextMonth, selectedMonth) &&
												'bg-slate-800 text-white max-w-fit px-6'
										)}
										disabled={
											lastEnabledMonth
												? isAfter(nextMonth, lastEnabledMonth)
												: false
										}
									>
										<Text
											as="span"
											className="text-xs font-black leading-none tracking-tight text-opacity-50 uppercase"
										>
											{format(nextMonth, 'MMM yyyy')}
										</Text>
										<Text as="span" className="text-lg font-bold leading-snug">
											{renderPrice(nextMonth)}
										</Text>
									</Button>
									<div className="absolute top-0 left-0 flex items-center justify-between w-full h-full p-0 z-1">
										<Button
											variant="unstyled"
											iconName="arrow-left"
											onClick={() => {
												goToMonth(previousMonth);
											}}
											disabled={isBefore(previousMonth, firstEnabledMonth)}
										/>
										<Button
											variant="unstyled"
											iconName="arrow-right"
											onClick={() => {
												goToMonth(nextMonth);
											}}
											disabled={
												lastEnabledMonth
													? isAfter(nextMonth, lastEnabledMonth)
													: false
											}
										/>
									</div>
								</div>
							);
						},
					}}
					classNames={{
						row: 'border-none',
						months: 'w-[90%] mx-auto flex items-center justify-center ',
						month: 'h-full w-full mx-auto',
						nav: 'bg-red-300',
					}}
				/>
			</div>
		</div>
	);
}

CalendarNav.propTypes = {
	activeMonth: PropTypes.instanceOf(Date),
	maxDate: PropTypes.instanceOf(Date),
	onChange: PropTypes.func,
	className: PropTypes.string,
	renderPrice: PropTypes.func,
};

CalendarNav.defaultProps = {
	activeMonth: new Date(),
	onChange: () => {},
	className: '',
	renderPrice: () => null,
};

export default CalendarNav;

/**
 * @module room
 * @desc Mapping of flight cabin details to api fields.
 * @category Utils
 */
export const cabinFeatures = [
    {
        label: 'Baggage',
        icon: 'baggage',
        apiFields: ['hold_baggage_details', 'hand_baggage_details'],
    },
    {
        label: 'Seat selection',
        icon: 'seat',
        apiFields: ['seat_selection_details'],
    },
    {
        label: 'Changes',
        icon: 'changes',
        apiFields: ['changes_details'],
    },
    {
        label: 'Cancellations',
        icon: 'cancellation',
        apiFields: ['cancellation_details'],
    },
];

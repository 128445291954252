import { DatePlaceHolder } from '@components/common';

function QueryDetails({ params, QueryItem }) {
	let holidayMonthDuration = null;
	const location = params?.location
		? params?.location.split(':')[1]
		: 'Select Location';
	const rooms = params?.rooms ? params?.rooms.length : 0;

	const people = !params?.rooms ? 0 :
		params?.rooms?.reduce((total, room) => {
			const adults = room?.adults ? parseInt(room.adults) : 0;
			if (adults) total += adults;

			const children = room?.children ? parseInt(room.children) : 0;
			if (children) total += children;

			const infants = room?.infants ? parseInt(room.infants) : 0;
			if (infants) total += infants;

			return total;
		}, 0);

	if (params?.category === 'holidays' && params?.duration && params?.month) {
		holidayMonthDuration =
			params?.month.split(':')[1] + ', ' + params?.duration.split(':')[1];
	}

	return (
		<div className="flex flex-wrap items-center justify-between w-full h-full gap-2 px-2 py-2 lg:py-0">
			<QueryItem label="Location" value={location} />
			<QueryItem
				label="Rooms"
				value={
					typeof rooms === 'undefined'
						? 'Select Rooms'
						: `${rooms} ${rooms > 1 ? 'rooms' : 'room'}`
				}
			/>
			<QueryItem
				icon="users"
				value={people}
				className="flex flex-row items-center gap-3 md:hidden"
			/>
			{holidayMonthDuration ? (
				<QueryItem label="Dates" value={holidayMonthDuration} />
			) : (
				<QueryItem
					label="Dates"
					className="col-span-2 md:col-span-1"
					value={() => (
						<DatePlaceHolder
							hideTime
							selected={{
								from: new Date(params.startDate),
								to: new Date(params.endDate),
							}}
						/>
					)}
				/>
			)}

			<QueryItem
				icon="users"
				value={people}
				className="flex-row items-center hidden gap-3 md:flex"
			/>
		</div>
	);
}

export default QueryDetails;

import { currencyFormatter } from '@/lib/utils';
import {
	Text,
	Heading,
	AccordionItem,
	DatePlaceHolder,
	AccordionContent,
	AccordionTrigger,
} from '@components/common';

const Accommodation = ({ option, showPricingBreakdown }) => {
	return (
		<AccordionItem
			key={`${option?.ref}-${option?.code}`}
			value={`${option?.ref}-${option?.code}`}
			className="mb-2"
		>
			<AccordionTrigger
				asChild
				className="flex-row-reverse items-start justify-end w-full gap-2 tracking-normal cursor-pointer "
			>
				<Text
					as="p"
					className="flex items-center justify-between w-full font-bold"
				>
					<Text as="span" className="font-bold">
						Hotel
					</Text>
					{showPricingBreakdown ? (
						<Text as="span" className="font-bold">
							{currencyFormatter({
								amount: option?.total,
							})}
						</Text>
					) : null}
				</Text>
			</AccordionTrigger>
			<AccordionContent>
				<div className="flex flex-col py-1">
					<Heading as="h3" className="text-base font-semibold font-body">
						{option?.pagetitle || option?.name}
					</Heading>

					{option?.rooms?.length >= 1
						? option.rooms.map((room) => (
							<div className="flex flex-col py-1" key={room?.number}>
								<Text className="font-light">
									Room {room?.number}: {room?.name}
									{room?.boardBasis
										? ` - ${room?.boardBasisDisplayName || room?.boardBasis}`
										: ''}
								</Text>
								<DatePlaceHolder
									hideTime
									showDifference
									selected={{
										from: option?.startDate,
										to: option?.endDate,
									}}
									dateFormat="dd MMM, yyyy"
								/>
								{showPricingBreakdown && room?.prices ? (
									<div className="mt-4">
										{room?.prices?.map((price) => (
											<div
												key={price?.name}
												className="flex items-center justify-between mb-2"
											>
												<Text as="span" className="font-normal">
													{price?.name}
												</Text>
												<Text as="span" className="font-bold">
													{currencyFormatter({
														amount: price?.price,
													})}
												</Text>
											</div>
										))}
									</div>
								) : null}
							</div>
						))
						: null}
				</div>
			</AccordionContent>
		</AccordionItem>
	);
};

export default Accommodation;

import React from 'react';
import PropTypes from 'prop-types';

import {
	Tabs,
	Icon,
	Dialog,
	Button,
	TabsList,
	TabsTrigger,
	TabsContent,
	Heading,
	Text,
	LoopWrapper,
	RteStyledContent,
} from '@/components/common';
import cn from '@/lib/utils/tailwind';
import { currencyFormatter } from '@/lib/utils/currency';
import { useBreakpoint } from '@/hooks';
import SelectedTick from '@/components/common/molecules/selected-tick';
import { cabinFeatures } from '@/lib/utils/cabin';

/**
 * @typedef {Object} FareModalProps
 * @property {import("@components/common/atoms/dialog").DialogProps["renderTrigger"]} renderTrigger
 * @property {()=>void} onSelect
 * @property {Object} selected
 * */

/**
 * @name FareModal
 * @description Renders a modal with the fare details
 * @param {FareModalProps} props
 * @returns {React.JSX.Element | null}
 * @example
 * <FareModal onSelect={handleSelect} selected={selected} />
 * */

function FareModal({
	carrier,
	fareOptions,
	onSelect,
	selected,
	renderTrigger,
	disabled,
	confirmLabel = 'Confirm',
	confirmDescription,
}) {
	const isSm = useBreakpoint('sm');
	if (!fareOptions?.length) return null;

	// show the first tab if the selected fare isn't an available upgrade option
	const selectedOptionExists = fareOptions.some(
		(item) => item?.cabin?.code === selected
	);
	const defaultTabValue = selectedOptionExists ?
		selected :
		fareOptions?.[0]?.cabin?.code;

	return (
		<Dialog
			as="modal"
			hideCloseBtn
			position="center"
			contentClassName="p-0"
			size={isSm ? 'screen' : 'xl'}
			renderTrigger={renderTrigger}
		>
			{({ CloseButton, onClose }) => (
				<div className="relative">
					<span className="absolute top-5.25 right-1 md:relative md:top-auto md:right-auto flex items-center justify-end w-auto h-12.5">
						<CloseButton
							onClick={onClose}
							className="relative top-0 right-0 w-12.5 h-full text-dark-grey bg-white md:text-white md:bg-core-blue"
							variant="square"
						/>
					</span>
					<div className="flex items-center w-full gap-4 p-5 lg:px-8 lg:pb-8 md:pt-0 md:pb-6 md:px-6">
						{carrier?.logo ? (
							<img
								className="object-contain w-13 h-13 md:w-16 md:h-16 lg:w-22.5 lg:h-22.5 border border-dark-grey/10"
								src={carrier.logo}
								alt={carrier?.name || carrier?.code}
							/>
						) : null}
						{carrier?.name ? (
							<Heading
								as="h2"
								className="text-3xl font-bold tracking-tighter leading-extra-tight md:!leading-tight md:text-4xl lg:text-5xl text-core-blue font-body md:font-display"
							>
								{carrier.name}
							</Heading>
						) : null}
					</div>
					<Tabs defaultValue={defaultTabValue}>
						<div className="relative h-10 p-0 md:h-13 bg-light-grey">
							<div className="absolute top-0 inset-x-0 h-0.5 bg-light-grey border-t-3 md:border-t-2 border-solid border-black/5 z-0"></div>
							<TabsList className="relative h-10 p-0 md:h-13 z-1">
								{fareOptions.map((option) => (
									<TabsTrigger
										key={option?.cabin?.code}
										value={option?.cabin?.code}
										className={cn(
											'flex gap-2 w-fit rounded-none px-5 md:px-6 lg:px-8 h-10 md:h-13 text-dark-grey border-t-3 md:border-t-2 border-transparent font-bold md:font-black md:uppercase shadow-none bg-transparent data-[state=active]:bg-white data-[state=active]:shadow-none data-[state=active]:mx-0 data-[state=active]:border-t-3 data-[state=active]:md:border-t-2 data-[state=active]:border-core-red data-[state=active]:text-dark-grey leading-snug tracking-tighter md:tracking-wide'
										)}
									>
										{option?.cabin?.displayName || option?.cabin?.name}
									</TabsTrigger>
								))}
							</TabsList>
						</div>

						{fareOptions.map((option) => (
							<TabsContent
								key={option?.cabin?.code}
								value={option?.cabin?.code}
								className="mt-0"
							>
								<div className="flex flex-col gap-2 md:gap-3.5 lg:gap-5 p-6 md:p-8 lg:p-10">
									<Heading
										as="h3"
										className="text-2xl font-bold tracking-extra-tight md:tracking-tighter leading-extra-tight md:!leading-tight font-body md:font-display md:text-4xl lg:text-5xl text-dark-grey md:text-core-blue"
									>
										{option?.cabin?.displayName || option?.cabin?.name}
									</Heading>

									{/* Cabin features */}
									<LoopWrapper list={cabinFeatures}>
										{(feature) => {
											const hasContent = feature?.apiFields?.some((field) =>
												option.cabin[field] ? true : false
											);
											if (!hasContent) return null;

											return (
												<div className="flex flex-col gap-2.5">
													<div className="flex items-center">
														<Icon name={feature.icon} className="w-6 h-6" />
														<Text
															as="span"
															className="text-base leading-snug tracking-tighter font-normal ml-2.5 text-dark-grey"
														>
															{feature?.label}
														</Text>
													</div>
													<LoopWrapper list={feature?.apiFields}>
														{(field) => (
															<RteStyledContent
																variant="dark"
																className="flight-info"
																dangerouslySetInnerHTML={{
																	__html: option.cabin[field],
																}}
															/>
														)}
													</LoopWrapper>
												</div>
											);
										}}
									</LoopWrapper>

									{option?.pricing?.adult ? (
										<Text className="text-2xl font-bold leading-snug tracking-tighter text-dark-grey">
											{currencyFormatter({
												amount: option.pricing.adult,
											})}
										</Text>
									) : null}

									<div className="flex items-center justify-between w-full">
										<Button
											label={confirmLabel}
											variant="supporting-yellow"
											disabled={disabled}
											onClick={() => {
												onSelect(option);
												onClose();
											}}
										/>
										<SelectedTick
											isSelected={option?.cabin?.code === selected}
										/>
									</div>
									{confirmDescription ? (
										<Text
											className="leading-snug tracking-tighter text-dark-grey"
										>
											{confirmDescription}
										</Text>
									) : null}
								</div>
							</TabsContent>
						))}
					</Tabs>
				</div>
			)}
		</Dialog>
	);
}

FareModal.propTypes = {
	renderTrigger: PropTypes.func.isRequired,
	onSelect: PropTypes.func,
	selected: PropTypes.string,
	fareOptions: PropTypes.arrayOf(PropTypes.object),
	disabled: PropTypes.bool,
	confirmLabel: PropTypes.string,
	confirmDescription: PropTypes.string,
};

FareModal.defaultProps = {
	onSelect: () => {},
	fareOptions: [],
	selected: null,
	disabled: false,
};

export default FareModal;

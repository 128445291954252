import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';
import qs from 'qs';

import { FlightBlock, HotelBlock } from '@/components/holidays';
import {
	Button,
	Heading,
	Icon,
	Text,
	BookingPageWrapper,
} from '@/components/common';
import PageWrapper from '@/components/common/organisms/page-wrapper';
import useBreakpoint from '@/hooks/useBreakPoint';
import { useBookingStore } from '@/store';
import { useSearchSave } from '@/hooks';
import useMetaDescription from '@/hooks/useMetaDescription';

function TravelPlan({ steps = [] }) {
	const params = useParams();
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const ref = useRef(null);
	const isBreakpoint = useBreakpoint('lg');
	const [displayBlocks, setDisplayBlocks] = useState([]);
	const [holidaySummary, setHolidaySummary] = useState({});
	const [loadingBlockIdx, setLoadingBlockIdx] = useState(null);

	useMetaDescription(['Travel Plan', 'Holidays', 'Canadian Affair']);

	const category = params?.category
		? params?.category
		: pathname && pathname.split('/')[2];

	const state = useBookingStore((store) => store[category]);
	const { setBookingState } = useBookingStore();
	const isChangeDisabled = state?.isLoading || state?.previewLoading;
	const { getSearch } = useSearchSave();
	const savedSearch = getSearch(category);

	const holidayIdx = useMemo(
		() =>
			state?.selected?.items?.findIndex((item) => item?.type === 'holidays'),
		[state?.selected?.items]
	);

	// add the left offset to the total width of the container
	useEffect(() => {
		if (isBreakpoint) return;
		if (!ref.current) return;
		const { width } = ref.current.getBoundingClientRect();
		ref.current.style.width = `${width + 32}px`;
	}, []);

	// enable requesting new upgrades when making travel plan changes
	useEffect(() => {
		if (state?.includeHolidayUpgrades) return;

		const newState = {
			...state,
			includeHolidayUpgrades: true,
		};
		setBookingState(category, newState, 'ENABLE_HOLIDAY_REQUEST_UPGRADES');
	}, [state?.includeHolidayUpgrades]);

	// reset loading block state
	useEffect(() => {
		if (state?.isLoading || state?.previewLoading) return;

		setLoadingBlockIdx(null);
	}, [state?.isLoading, state?.previewLoading, loadingBlockIdx]);

	const updateBookingState = (
		itemParamIdx,
		newItem,
		label,
		showLoader,
		onUpdatedState
	) => {
		if (isChangeDisabled) return;

		if (holidayIdx < 0) return;
		if (!state?.selected?.items) return;

		const newBookingItems = JSON.parse(JSON.stringify(state.selected.items));
		newBookingItems[holidayIdx].items[itemParamIdx] = newItem;

		// set loading
		if (showLoader) {
			setTimeout(() => {
				setLoadingBlockIdx(itemParamIdx);
			}, 50);
		}

		// update state
		let newState = {
			...state,
			shouldBuildParams: true,
			selected: {
				...(state?.selected || {}),
				items: newBookingItems,
			},
			bookingParams: {
				...(state?.selected || {}),
				items: newBookingItems,
			},
			baggage: undefined,
			optionPlusOptions: undefined,
			selectedSeats: undefined,
			questions: undefined,
			paymentDetails: undefined,
			showFlightsChangedWarning: false,
		};

		if (state?.bookingDetails) {
			// answered questions
			newState = {
				...newState,
				bookingDetails: {
					...(state.bookingDetails || {}),
					questions: undefined,
				},
			};
		}

		setBookingState(category, newState, label);

		if (typeof onUpdatedState === 'function') onUpdatedState();
	};

	useEffect(() => {
		if (!state.bookingParams) return;
		if (!state?.preview?.breakdown?.length) return;

		const { bookingParams } = state;
		const holiday = bookingParams?.items?.[0];
		if (!holiday?.items?.length) return;

		// extract holiday summary information
		setHolidaySummary(state?.preview?.holiday?.summary || {});

		// build blocks
		const blocks = [];
		Object.keys(holiday.items).map((i) => {
			const params = holiday.items[i];
			switch (params?.type) {
				case 'accommodation':
					const hotelPreviews = state.preview.breakdown.filter(
						(item) =>
							item?.type === params.type &&
							item?.startDate === params?.startDate &&
							item?.endDate === params?.endDate &&
							item?.code === params?.code
					);

					// NOTE: external inventory has multiple preview blocks
					const hotelDetails = {
						...(hotelPreviews?.[0] || {}),
						startDate: params?.startDate,
						endDate: params?.endDate,
						rooms: hotelPreviews.reduce((rooms, preview) => {
							if (!preview?.rooms?.length) return rooms;

							return [...rooms, ...preview.rooms];
						}, []),
					};

					const hotelOptions = state?.holidayUpgrades?.find(
						(upgrade) =>
							upgrade?.type === params.type &&
							upgrade?.startDate === params?.startDate &&
							upgrade?.endDate === params?.endDate
					);

					blocks.push(
						<HotelBlock
							key={`my-tranplan-${i}`}
							title="Accommodation"
							headerTitle="Confirm Accommodation"
							hotel={hotelDetails}
							hotelOptions={hotelOptions}
							category={category}
							holidayIdx={holidayIdx}
							itemParamIdx={i}
							updateBookingState={updateBookingState}
							isChangeDisabled={isChangeDisabled}
							loading={i === loadingBlockIdx}
						/>
					);
					break;
				case 'flight':
					const flightPreview = state.preview.breakdown.find(
						(item) => item?.type === 'flights'
					);

					// parse upgrade options
					const summaryKey = params.outbound?.holidaySummaryKey;
					const outboundKey = params.outbound?.holidayUpgradeKey;
					const flightOptions = state?.holidayUpgrades?.find(
						(upgrade) => upgrade?.type === 'flights'
					);
					const upgradeSummary = Object.values(flightOptions?.all || {}).find(
						(summary) =>
							summary?.key === summaryKey &&
							typeof summary?.outbound?.[outboundKey] === 'object'
					);

					const outbound = flightPreview?.outbound?.flights?.[0];
					const outboundUpgrade = upgradeSummary?.outbound?.[outboundKey];

					const inbound = flightPreview?.inbound?.flights?.[0];
					const inboundKey = params.inbound?.holidayUpgradeKey;
					const inboundUpgrade = outboundUpgrade?.inbound?.[inboundKey];

					// outbound flight
					if (outbound) {
						blocks.unshift(
							<FlightBlock
								key={`my-tranplan-outbound-${i}`}
								flight={outbound}
								flightParams={params.outbound}
								direction="outbound"
								flightOptions={flightOptions}
								outboundFareOptions={outboundUpgrade?.pricingMatrix}
								inboundFareOptions={inboundUpgrade?.pricingMatrix}
								category={category}
								holidayIdx={holidayIdx}
								itemParamIdx={i}
								updateBookingState={updateBookingState}
								isChangeDisabled={isChangeDisabled}
								loading={i === loadingBlockIdx}
								headerTitle="Select Flights"
							/>
						);
					}

					// inbound flight
					if (inbound) {
						blocks.splice(
							1,
							0,
							<FlightBlock
								key={`my-tranpla-inbound-${i}`}
								flight={inbound}
								flightParams={params.inbound}
								direction="inbound"
								outboundFareOptions={outboundUpgrade?.pricingMatrix}
								inboundFareOptions={inboundUpgrade?.pricingMatrix}
								category={category}
								holidayIdx={holidayIdx}
								itemParamIdx={i}
								updateBookingState={updateBookingState}
								isChangeDisabled={isChangeDisabled}
								loading={i === loadingBlockIdx}
							/>
						);
					}
					break;
			}
		});
		setDisplayBlocks(blocks);
	}, [
		state.bookingParams?.items,
		state?.preview?.breakdown,
		state?.holidayUpgrades,
		holidayIdx,
		isChangeDisabled,
		loadingBlockIdx,
	]);

	const accommodationItemUrl = useMemo(() => {
		if (!state?.preview?.breakdown?.length) return null;

		const accommodationItem = state?.preview?.breakdown.filter(
			(i) => i.type === 'accommodation'
		)[0];

		return accommodationItem.url;
	}, [state?.preview?.breakdown]);

	const handleContinue = () => {
		// reset warning and disable upgrade fetch
		const newState = {
			...state,
			includeHolidayUpgrades: false,
			showFlightsChangedWarning: false,
		};

		// enable preview if we need to retrieve booking questions
		if (!state?.questions) {
			newState.shouldBuildParams = true;
		}

		setBookingState(category, newState, 'TRAVEL_PLAN_CONTINUE');

		navigate('/booking/holidays/passenger-details');
	};

	const continueIsDisabled =
		state?.isLoading || state?.previewLoading || !!state?.error;

	return (
		<BookingPageWrapper
			error={null}
			steps={steps}
			title="My Travel Plan"
			category="holidays"
			onContinue={handleContinue}
			continueIsDisabled={continueIsDisabled}
			previousPage={true}
			{...(!!accommodationItemUrl && {
				renderBackButton: (step) => (
					<Button
						type="button"
						variant="unstyled"
						onClick={() => {
							const parsedSavedSearch = qs.parse(savedSearch);
							parsedSavedSearch.rooms.map((r) => delete r.room);
							window.location.href = savedSearch
								? `${accommodationItemUrl}?${qs.stringify(parsedSavedSearch)}`
								: accommodationItemUrl;
						}}
						className="flex items-center gap-2 mb-4 text-base tracking-tighter"
						disabled={state?.previewLoading || state?.isLoading}
					>
						<Icon name="arrow-left" className="w-5 h-5" />
						<span className="font-normal">Back to Hotel</span>
					</Button>
				),
			})}
		>
			{(holidaySummary?.name || holidaySummary?.duration > 0) && (
				<div className="px-0">
					{holidaySummary?.name ? (
						<Heading
							as="h3"
							className="mb-4 text-lg leading-snug md:text-3xl font-body md:tracking-extra-tight"
						>
							{holidaySummary?.name}
						</Heading>
					) : null}
					{holidaySummary?.duration > 0 && (
						<Text className="flex items-center gap-2">
							<Icon name="moon" className="w-3.75 h-4.25" />
							<Text
								as="span"
								className="font-bold leading-snug tracking-tighter"
							>
								{holidaySummary?.duration} Nights
							</Text>
						</Text>
					)}
				</div>
			)}
			<div className="flex flex-col items-start justify-between gap-4 p-5 text-white rounded-lg bg-core-light-blue xl:flex-row xl:items-center md:mx-0">
				<Text className="text-lg font-bold leading-snug tracking-tighter">
					Need help with changes to this holiday?
				</Text>
				<Text className="flex flex-col items-start gap-4 xl:flex-row xl:items-center">
					<Text
						as="span"
						className="block text-lg font-bold leading-snug tracking-tighter text-white font-body"
					>
						Talk to an expert
					</Text>
					<Text
						as="a"
						href="tel:02034246305"
						className="flex items-center gap-2.5"
					>
						<Icon name="phone" className="w-5 h-5.25" />
						<Text
							as="span"
							className="text-lg font-bold leading-snug tracking-tighter"
						>
							Call 0203 424 6305
						</Text>
					</Text>
				</Text>
			</div>
			{/* flights & accommodation blocks */}
			<PageWrapper
				loading={!state?.preview}
				loaderClassName="h-96 border border-lighter-grey"
				errorClassName="border border-lighter-grey"
				loadingText=""
			>
				<div className="flex flex-col -mx-5 gap-2.5 md:mx-0 md:gap-5 relative">
					{displayBlocks}
				</div>
			</PageWrapper>
		</BookingPageWrapper>
	);
}

export default TravelPlan;

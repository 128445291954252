import React from 'react';

import { cn } from '@/lib/utils';
import { Icon, Text } from '@/components/common';

function Navbar({ className }) {
	return (
		<nav
			className={cn(
				'bg-white h-20 shadow-md p-4 w-full sticky top-0 left-0 z-50 lg:h-24',
				className
			)}
		>
			<div className="container flex items-center justify-between w-full h-full gap-5 mx-auto max-w-screen-3xl">
				<a href="/" aria-label="Home">
					<Icon name="logo" className="w-full h-12 lg:h-20" />
				</a>
				<div className="flex flex-col items-start justify-end">
					<Text className="leading-snug text-xsm md:text-base md:leading-normal">
						Want help with your booking?
					</Text>
					<Text title="02034246305" className="text-sm md:text-base">
						Call us today on{' '}
						<a href="tel:02034246305" className="font-bold hover:opacity-75">
							0203 424 6305
						</a>
					</Text>
				</div>
			</div>
		</nav>
	);
}
export default Navbar;

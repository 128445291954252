import { cn, currencyFormatter, formatLocalDate } from '@/lib/utils';
import Text from '@components/common/atoms/text';
import Button from '@components/common/atoms/button';
import Route from '@components/common/molecules/route';
import RouteDuration from '@components/flights/molecules/route-duration';
import FlightBlockListItemDetails from '@components/flights/organisms/flight-block-listitem-details';
import { useMemo, useState } from 'react';
import { useBreakpoint, useDisclosure } from '@/hooks';

/**
 * this component is a flight item for the flight block
 * It will also have the outbound & return flights
 * return flights only show if the outbound is selected
 */

function FlightBlockListItem({ overview, selected, onSelect, disabled }) {
	const { onToggle, isOpen } = useDisclosure(false);
	const isLg = useBreakpoint('lg');
	const isXl = useBreakpoint('xl');
	const [selectedOutbound, setSelectedOutbound] = useState(null);

	const outboundOptions = useMemo(
		() => (overview?.outbound ? Object.values(overview.outbound) : []),
		[overview?.outbound]
	);

	const inboundOptions = useMemo(
		() =>
			selectedOutbound?.inbound ? Object.values(selectedOutbound.inbound) : [],
		[selectedOutbound?.inbound]
	);

	const handleChange = (outbound, inbound) => {
		// select the first fare in the pricing matrix for each flight
		const outboundItem = outbound?.pricingMatrix?.[0];
		const inboundItem = inbound?.pricingMatrix?.[0];
		if (!(outboundItem && inboundItem)) return;

		onSelect(outboundItem, inboundItem);
	};

	const formatRouteName = (code, city) => {
		if (!city) return code || '';
		return city + (code ? ` (${code})` : '');
	};

	return (
		<div className="flex flex-col">
			<section className="w-full h-auto px-5 lg:px-6">
				<div
					className={cn(
						'flex flex-col gap-1.5 md:gap-3.5 lg:gap-8 py-5 lg:py-6 border-b xl:flex-row border-b-dark-grey/10',
						isOpen && 'border-b-0'
					)}
				>
					<div
						className={cn(
							'flex flex-col items-center lg:gap-6 xl:flex-row grow',
							overview?.outboundSummary?.carrier?.logo && 'gap-5'
						)}
					>
						<div
							className={cn(
								'relative flex shrink-0 flex-col justify-start overflow-hidden md:bg-gray-50',
								overview?.outboundSummary?.carrier?.logo &&
									'w-22.5 h-22.5 border border-dark-grey/10'
							)}
						>
							{overview?.outboundSummary?.carrier?.logo && (
								<img
									width="100%"
									height="100%"
									className="block object-contain w-full h-full"
									src={overview?.outboundSummary?.carrier.logo}
									alt={
										overview?.outboundSummary?.carrier?.name ||
										overview?.outboundSummary?.carrier?.code
									}
								/>
							)}
						</div>

						<div className="w-full grow xl:w-auto">
							<Text className="mb-1.5 md:mb-3.5 lg:mb-5 font-bold leading-snug tracking-less-tight text-dark-grey">
								Flights
							</Text>
							<div className="flex flex-col gap-3 2xl:gap-1.5 w-full">
								{overview?.outboundSummary && (
									<div className="flex flex-col items-start w-full gap-4 2xl:gap-2 2xl:flex-row 2xl:items-center">
										{overview?.outboundSummary?.departureDate ? (
											<div className="flex flex-row items-center justify-between w-full 2xl:w-auto">
												<Text className="w-16 font-bold leading-snug tracking-less-tight text-dark-grey shrink-0">
													{formatLocalDate(
														overview?.outboundSummary?.departureDate,
														'dd MMM'
													)}
												</Text>
												{isLg && (
													<RouteDuration flight={overview?.outboundSummary} />
												)}
											</div>
										) : null}
										<Route
											className="gap-4 grow"
											connectLineClassName="lg:flex 2xl:hidden"
											durationClassName="lg:hidden 2xl:flex"
											from={formatRouteName(
												overview?.outboundSummary?.departure,
												overview?.outboundSummary?.departureCity
											)}
											to={formatRouteName(
												overview?.outboundSummary?.arrival,
												overview?.outboundSummary?.arrivalCity
											)}
											iconName="flight-fill"
											iconClassName="w-[0.625rem] h-[0.625rem]"
											{...(!isLg && {
												renderIcon: () => (
													<RouteDuration flight={overview?.outboundSummary} />
												),
											})}
										/>
									</div>
								)}
								{overview?.inboundSummary && (
									<div className="flex flex-col items-start w-full gap-4 2xl:gap-2 2xl:flex-row 2xl:items-center">
										{overview?.inboundSummary?.departureDate ? (
											<div className="flex flex-row items-center justify-between w-full 2xl:w-auto">
												<Text className="w-16 font-bold leading-snug tracking-less-tight text-dark-grey shrink-0">
													{formatLocalDate(
														overview?.inboundSummary?.departureDate,
														'dd MMM'
													)}
												</Text>
												{isLg && (
													<RouteDuration flight={overview?.inboundSummary} />
												)}
											</div>
										) : null}
										<Route
											className="gap-4 grow"
											connectLineClassName="lg:flex 2xl:hidden"
											durationClassName="lg:hidden 2xl:flex"
											from={formatRouteName(
												overview?.inboundSummary?.departure,
												overview?.inboundSummary?.departureCity
											)}
											to={formatRouteName(
												overview?.inboundSummary?.arrival,
												overview?.inboundSummary?.arrivalCity
											)}
											iconName="flight-fill"
											iconClassName="w-[0.625rem] h-[0.625rem]"
											{...(!isLg && {
												renderIcon: () => (
													<RouteDuration flight={overview?.inboundSummary} />
												),
											})}
										/>
									</div>
								)}
							</div>
						</div>
					</div>

					<div className="flex flex-col xl:items-end justify-center gap-1.5 md:gap-2.5">
						<Text className="text-2xl font-bold leading-snug tracking-tighter text-dark-grey">
							{(overview?.fromPriceDiff || 0) >= 0 ? '+' : ''}
							{currencyFormatter({
								amount: overview?.fromPriceDiff || 0,
							})}
						</Text>
						<Button
							hideIcon
							disableAnimation
							variant={isOpen ? 'outline' : 'core-blue'}
							label={
								isOpen
									? 'Cancel'
									: isXl
									? 'View flight times and confirm'
									: 'Select flights'
							}
							onClick={onToggle}
							className={cn('min-w-40 self-start', {
								'bg-light-grey border-light-grey text-dark-grey hover:border-black/20 border-3':
									isOpen,
							})}
							disabled={disabled}
						/>
					</div>
				</div>
			</section>
			{isOpen && (
				<div className="relative flex flex-col w-full p-5 xl:px-12 xl:py-6 border-b-5 border-core-blue bg-black/2">
					<Text
						as="p"
						className="mb-0 text-base lg:text-1.5xl leading-[1.3] tracking-less-tight text-dark-grey"
					>
						Please select your{' '}
						<strong className="underline underline-offset-4">outbound</strong>{' '}
						flight {!selectedOutbound ? 'to view return flight options' : ''}
					</Text>

					<div className="flex flex-col">
						{outboundOptions.map((outbound, idx) => {
							const isSelected =
								selectedOutbound?.holidayUpgradeKey ===
								outbound?.holidayUpgradeKey;

							return (
								<div
									key={outbound?.holidayUpgradeKey}
									className={
										!isSelected
											? 'border-b border-dark-grey/10 last:border-b-0'
											: ''
									}
								>
									<FlightBlockListItemDetails
										flight={outbound}
										isSelected={isSelected}
										onChange={() =>
											setSelectedOutbound(isSelected ? null : outbound)
										}
										disabled={disabled}
									/>

									{isSelected && (
										<div
											className={cn(
												'py-5 lg:py-6 border-y md:border-y-2 border-y-dark-grey',
												!!inboundOptions.length && 'pb-0 lg:pb-0'
											)}
										>
											<Text
												as="p"
												className="mb-0 text-base lg:text-1.5xl leading-[1.3] tracking-less-tight text-dark-grey"
											>
												Please select your{' '}
												<strong className="underline underline-offset-4">
													return
												</strong>{' '}
												flight
											</Text>

											{inboundOptions.map((inbound) => (
												<FlightBlockListItemDetails
													key={inbound?.holidayUpgradeKey}
													flight={inbound}
													isSelected={false}
													onChange={() => {
														handleChange(selectedOutbound, inbound);
													}}
													disabled={disabled}
												/>
											))}
										</div>
									)}
								</div>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
}
export default FlightBlockListItem;

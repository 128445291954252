import { mergeArrayOfObjects, setNestedValue } from "@/lib/utils";

/**
 * @name setBookingState
 * @description Sets the booking state for the given key.
 * @param {"hotels" | "flights" | "motorhome-hire" | "holidays" | "car-hire"} key
 * @param {any} value
 * @param {import('zustand').StoreApi<T>['setState']} set
 * @param {string} label
 * @returns {void}
 * @example
 * setBookingState('hotels', { loading: true }, 'SET_LOADING');
 */

export const setBookingState = (key, value, set, label = 'SET_BOOKING_STATE') => {
    const updateState = (state) => ({
        ...state,
        [key]: {
            ...(state[key] || {}), // if the key doesn't exist, create an empty object
            ...value,
        }
    });

    set(updateState, false, label);
};

/**
 * @name resetState
 * @description Resets the booking state to the initial state.
 * @param {"hotels" | "flights" | "motorhome-hire" | "holidays" | "car-hire"} key
 * @param {import('zustand').StoreApi<T>['setState']} set
 * @returns {void}
 * @example
 * resetState();
 */
export const resetState = (key, set, initialState) => {
    if (key) {
        set((state) => ({
            ...state,
            [key]: initialState[key],
        }));
        return;
    } else {
        set(initialState);
    }
};

/**
 * @name resetNestedState
 * @description Resets the nested state to the initial state.
 * @param {"hotels" | "flights" | "motorhome-hire" | "holidays" | "car-hire"} category
 * @param {Array<string>} keys
 * @param {import('zustand').StoreApi<T>['getState']} get
 * @returns {void}
 * @example
 * resetNestedState('hotels', 'selected');
 * resetNestedState('flights', 'selected.outbound');
 * */
export const resetNestedState = (category, keys, get) => {
    if (!category || !keys.length) return;

    const state = get()[category];
    const { setBookingState } = get();

    keys.forEach((key) => {
        const newState = setNestedValue(state, key, initialState[category][key]);
        setBookingState(category, newState, 'RESET_NESTED_STATE');
    });

    return;
};


/**
 * @name mergeParams
 * @description Merges the additional params into the selected items.
 * @param {import('zustand').StoreApi<T>['getState']} get
 * @returns {void}
 * @example
 * mergeParams();
 */

export const mergeParams = (get, category) => {
    const state = get()[category];
    const { setBookingState } = get();

    const selected = state?.selected;
    const items = category === 'holidays' ? selected?.items?.[0].items : selected?.items;
    const additionalParams = state?.preview?.additionalParams;

    if (!items || !additionalParams) return;

    // filter out errata from the additional params
    const newAdditionalParams = state?.preview?.additionalParams?.map(
        (item) => {
            Object.keys(item).forEach((key) => {
                if (item[key]?.errata) {
                    delete item[key]?.errata;
                }
            });

            return item;
        }
    );

    let toMerge = additionalParams;

    // if the user has read the information, merge the params without the errata
    if (state?.bookingDetails?.read_information) {
        toMerge = newAdditionalParams;
    }

    const selectedItems = mergeArrayOfObjects({
        toMerge,
        toBeMergedInto: items,
        filtered: category === 'holidays',
    });

    const newState = {
        ...state,
        shouldBuildParams: true,
        selected: {
            ...state.selected,
            items:
                category === 'holidays'
                    ? [
                        {
                            ...selected?.items?.[0],
                            items: selectedItems,
                        },
                    ]
                    : selectedItems,
        },
    };

    setBookingState(category, newState, 'MERGE_PARAMS');
}

import { useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { getNextStep } from '@/lib/utils';
import { useBookingStore } from '@/store';
import { getSteps } from '@/lib/steps';
import {
	useBackNavigate,
	useFetch,
	useRemoveQueries,
	useSkipPage,
	useMetaDescription,
} from '@/hooks';
import { Button, BookingPageWrapper, PageWrapper } from '@/components/common';
import { OptionPlusInclusions, OptionsPlusList } from '@/components/flights';

function OptionPlus() {
	const navigate = useNavigate();
	const { params } = useParams();
	const { pathname } = useLocation();
	const { removeQueries } = useRemoveQueries();
	const { setBookingState, mergeParams, requestParams } = useBookingStore();
	const [optionPlusErrorMessage, setOptionPlusErrorMessage] = useState('');

	const category = params?.category
		? params?.category
		: pathname && pathname.split('/')[2];
	const state = useBookingStore((store) => store[category]);
	const pageSteps = useMemo(() => getSteps(category), [category]);

	useMetaDescription([
		'Option Plus',
		category === 'holidays' ? 'Holidays' : 'Flights',
		'Canadian Affair',
	]);

	const navigateToNextStep = () => {
		const nextStep = getNextStep(pageSteps, pathname);
		navigate(nextStep?.to);
	};

	const { handleBack, previousStep } = useBackNavigate(pageSteps);

	const hasPaxInfoInPreview = useMemo(() => {
		// determine if we have fetched pax type from Atcom
		if (!state?.bookingDetails?.pax?.length) return false;

		return state?.preview?.pax?.length > 0;
	}, [state?.preview?.pax]);

	const optionPlusAvailable = useMemo(() => {
		// assume available until we have fetched pax type info from Atcom
		if (!hasPaxInfoInPreview) return true;

		// check if option plus is available on any flight direction
		const items = state?.preview?.additionalParams; // NOTE: check most recent preview to ensure option plus remains available
		let availability = false;

		// skip if items is not available
		if (!items) return availability;

		const flight = items.filter((item) => item?.type === 'flight');
		flight.forEach((item) => {
			const outboundAvailable = item?.outbound?.isOptionPlusAvailable;
			const inboundAvailable = item?.inbound?.isOptionPlusAvailable;

			if (outboundAvailable || inboundAvailable) {
				availability = true;
			}
		});

		return availability;
	}, [hasPaxInfoInPreview, state?.preview?.additionalParams]);

	const { skip } = useSkipPage({
		steps: pageSteps,
		disableSkip: optionPlusAvailable,
		skipCallback: () => {
			mergeParams(category);
		},
	});

	// Not this one
	const optionPlusParams = useMemo(() => {
		return requestParams('option-plus', category);
	}, [category]);

	const { data, error, isLoading } = useFetch({
		key: 'option-plus',
		method: 'POST',
		useBody: true,
		params: optionPlusParams,
		config: {
			enabled: hasPaxInfoInPreview && optionPlusAvailable && !!optionPlusParams,
		},
		onData: (data) => {
			if (!hasPaxInfoInPreview) return;

			// skip if no products returned from api response
			const dataExists = typeof data !== 'undefined';
			const noOptions = !(data?.outbound || data?.inbound || data?.roundTrip);
			if (dataExists && noOptions) {
				skip(true); // add custon availability check here
				return;
			}

			if (state?.isLoading) {
				setBookingState(category, { isLoading: false }, 'RESET_ISLOADING');
			}

			// enable preview if returning to page & if shouldBuildParams is not true
			if (dataExists && !noOptions && !state?.shouldBuildParams) {
				const newState = { shouldBuildParams: true };
				setBookingState(category, newState, 'TRIGGER_PREVIEW_ONDATA');
			}

			// Handle Errors
		},
		format: (res) => res?.data, // returns the second data object
	});

	const checkOptionExists = (type) => {
		if (!state?.optionPlusOptions) return false;

		return !!state?.optionPlusOptions[type];
	};

	const handleOptionSelection = (type, option) => {
		const newSelection = {
			...(state?.optionPlusOptions || {}),
			[type]: option,
		};

		switch (type) {
			case 'roundTrip':
				// round trip selected - remove any outbound/inbound choices
				delete newSelection.outbound;
				delete newSelection.inbound;
				break;
			case 'nothanks':
				// round trip selected - remove any outbound/inbound choices
				delete newSelection.outbound;
				delete newSelection.inbound;
				delete newSelection.roundTrip;
				break;
			default:
				// single (inbound/outbound) selected - remove roundTrip selection
				delete newSelection.roundTrip;
				break;
		}

		// check if option already exists and remove it
		const selected = checkOptionExists(type);
		if (selected) delete newSelection[type];

		const newState = {
			...state,
			shouldBuildParams: true,
			optionPlusOptions: Object.keys(newSelection)?.length
				? newSelection
				: undefined,
		};

		if (state?.baggage) {
			// reset baggage search results
			newState.baggage = undefined;
			removeQueries(['baggage']);
		}

		setBookingState(category, newState, 'SET_OPTION_PLUS_OPTIONS');
	};

	const handleContinue = () => {
		const noOptionsSelected = !(
			checkOptionExists('outbound') ||
			checkOptionExists('inbound') ||
			checkOptionExists('roundTrip') ||
			checkOptionExists('nothanks')
		);

		if (noOptionsSelected) {
			setOptionPlusErrorMessage('Please select an option.');
			return;
		}

		// reset holiday flow flight warning indicator
		if (category === 'holidays' && state?.showFlightsChangedWarning) {
			const newHolidayState = {
				...state,
				showFlightsChangedWarning: false,
			};

			setBookingState(category, newHolidayState, 'HOLIDAY_OPTION_PLUS_RESET_WARNING');
		}

		navigateToNextStep();
		// merge state here ...
		mergeParams(category);
	};

	// show full loader if loading preview and we don't have pax info in the preview
	const showPageLoader =
		(state?.isLoading || state?.previewLoading) &&
		!hasPaxInfoInPreview &&
		!state?.error;

	const selectedFlights = useMemo(() => {
		return category === 'holidays'
			? state?.selected?.items?.[0].items.filter(
					(item) => item?.type === 'flight'
			  )[0]
			: state?.selected;
	}, [category, state?.selected]);

	return (
		<BookingPageWrapper
			steps={pageSteps}
			title="Option Plus"
			category={category}
			previousPage={previousStep}
			onContinue={handleContinue}
			disablePreviewFetch={!optionPlusAvailable || isLoading}
			continueIsDisabled={
				state?.isLoading || state?.previewLoading || isLoading
			}
			error={!hasPaxInfoInPreview ? state?.error : null}
			loading={showPageLoader}
		>
			<div className="flex flex-col gap-6 lg:max-w-5xl">
				<OptionPlusInclusions />
				<PageWrapper
					error={error}
					loading={isLoading}
					loaderClassName="h-96 border border-lighter-grey"
					loadingText="Fetching your options..."
				>
					<OptionsPlusList
						category={category}
						options={data}
						selectedFlights={selectedFlights}
						handleOptionSelection={handleOptionSelection}
						checkOptionExists={checkOptionExists}
						optionPlusErrorMessage={optionPlusErrorMessage}
					/>
				</PageWrapper>

				<div className="flex flex-col items-start justify-between gap-2 md:flex-row md:items-center">
					<Button
						label="Continue"
						variant="supporting-yellow"
						className="hidden md:flex"
						onClick={handleContinue}
						disabled={state?.isLoading || state?.previewLoading || isLoading}
					/>
					<Button
						disableAnimation
						variant="unstyled"
						onClick={handleBack}
						iconName="arrow-left"
						className="flex-row-reverse"
						labelClassName="font-normal"
						label={`Back to ${previousStep?.name}`}
						disabled={state?.isLoading || state?.previewLoading || isLoading}
					/>
				</div>
			</div>
		</BookingPageWrapper>
	);
}

export default OptionPlus;

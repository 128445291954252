import React from 'react';

import { withPortal } from '@/HOCs/withPortal';
import { HotelsList, HotelsMap } from '@/components/hotels';
import { cn, buildFacetFilters, SORT_OPTIONS, PAGE_OPTIONS } from '@/lib/utils';
import { useMetaDescription } from '@/hooks';
import { SearchResultsPage, SearchWidget } from '@/components/common';
import useVerticalOverflow from '@/hooks/useVerticalOverflow';

/**
 * @name HolidaySearch Page
 * @description Page to display holiday search results
 * @returns {React.JSX.Element} HolidaySearch
 */
function HolidaySearch({ portalData }) {
	const { observe, isOverflowing } = useVerticalOverflow(true);

	if (!portalData?.location) {
		useMetaDescription(['Search Results', 'Holidays', 'Canadian Affair']);
	}

	return (
		<>
			{!portalData?.location && (
				<SearchWidget
					ref={observe}
					category="holidays"
					className={cn(
						portalData?.withSiteHeader
							? 'fixed z-50 w-full top-15 md:top-25 drop-shadow-3xl max-h-[calc(100vh-3.75rem)] md:max-h-[calc(100vh-6.25rem)]'
							: 'max-h-screen',
						isOverflowing ? 'overflow-y-auto' : ''
					)}
				/>
			)}
			<SearchResultsPage
				paramsKey="holidays_filters"
				queryKey="holidays-search"
				sortOptions={SORT_OPTIONS}
				pageOptions={PAGE_OPTIONS}
				pageTitle={!portalData?.location ? 'Holiday Search Results' : null}
				filterLabel="Filter Holidays By"
				buildSearchParams={(params) => {
					// use default parameters for location hotel landing pages
					// if (portalData?.location) {
					// 	const startDate = addMonths(new Date(), 1);

					// 	return {
					// 		startDate: format(startDate, 'yyyy-MM-dd'),
					// 		endDate: format(addDays(startDate, 3), 'yyyy-MM-dd'),
					// 		location: portalData.location,
					// 		rooms: [{ adults: 2, children: 0, infants: 0 }],
					// 	};
					// }

					// parse search params from query string
					const {
						type,
						from,
						location,
						rooms,
						startDate,
						endDate,
						month,
						duration,
					} = params.default;

					let formattedRooms = null;
					if (rooms?.length) {
						formattedRooms = rooms.map((room) => {
							const acc = {
								adults: 0,
								children: 0,
								infants: 0,
							};

							Object.keys(room).map((type) => {
								const value = room[type];
								acc[type] = parseInt(value);
							}, {});
							return acc;
						});
					}

					return {
						type: type?.split(':')[0],
						outboundDepart:
							from && typeof from === 'string' ? from.split(':')[0] : '',
						location:
							location && typeof location === 'string'
								? location.split(':')[0]
								: '',
						rooms: formattedRooms,
						...(!startDate || !endDate
							? {
									period: month?.split(':')[0],
									duration: duration?.split(':')[0],
							  }
							: {
									startDate,
									endDate,
							  }),
					};
				}}
				buildFilters={(distribution, icons, stats) => {
					const customFilters = {
						price: {},
					};

					// represent price as a budget range filter
					if (
						typeof stats?.price?.min !== 'undefined' &&
						typeof stats?.price?.max !== 'undefined' &&
						stats?.price?.max > 0
					)
						customFilters.price = {
							type: 'budget',
							min: 0,
							max: parseFloat(stats.price.max),
							name: 'budget',
							title: 'Budget',
							defaultToZero: true, // default to zero when resetting (as we don't know the future max price value)
						};

					return buildFacetFilters({
						distribution: distribution,
						icons: icons,
						customFilters: customFilters,
					});
				}}
				MapResultsComponent={HotelsMap}
				ListResultsComponent={HotelsList}
				className={portalData?.withSiteHeader && '!pt-18'}
				resultsContainerClassName="lg:flex lg:items-start lg:relative"
				filterClassName={cn(
					'lg:sticky lg:w-1/4 lg:top-25',
					portalData?.withSiteHeader && 'lg:top-50.5'
				)}
			/>
		</>
	);
}

// portaled to booking-holidays-search-page
export default withPortal(HolidaySearch, 'booking-holidays-search-page');

import {
	Text,
	AccordionItem,
	AccordionContent,
	AccordionTrigger,
	NullTerniaryWrapper,
	Icon,
} from '@components/common';
import {
	FLIGHT_SPECIAL_ASSISTANCE_OPTIONS,
	cn,
	currencyFormatter,
} from '@/lib/utils';

const PassengerDetails = ({ pax, flightSeats, stateBookingDetailsPax }) => {
	return (
		<NullTerniaryWrapper
			condition={pax && Array.isArray(pax) && pax.length > 0}
		>
			<AccordionItem value="passengerDetails" className="mb-2">
				<AccordionTrigger
					asChild
					className="flex-row-reverse items-start justify-end w-full gap-2 tracking-normal cursor-pointer"
				>
					<Text
						as="p"
						className="flex items-center justify-between w-full font-bold"
					>
						Passenger Details
						{flightSeats?.pax && flightSeats?.pax.length > 0 && (
							<Icon name="seat" />
						)}
					</Text>
				</AccordionTrigger>
				<AccordionContent value="passengerDetails">
					<NullTerniaryWrapper
						condition={pax && Array.isArray(pax) && pax.length > 0}
						className="my-4"
					>
						<div className="my-4">
							{pax &&
								pax.map((passenger, idx) => {
									const seat = flightSeats?.pax?.find(
										(px) => px?.ref === passenger?.ref
									);

									// get selected special assistance selection
									const selectedAssistValue = stateBookingDetailsPax?.length
										? stateBookingDetailsPax.find(
												(px) => px?.ref === passenger?.ref
										  )?.assistance
										: null;

									const assistanceOption = selectedAssistValue
										? FLIGHT_SPECIAL_ASSISTANCE_OPTIONS.find(
												(opt) => opt?.value === selectedAssistValue
										  )
										: null;

									return (
										<div key={passenger?.ref || idx} className="mb-4">
											<div className="flex items-center justify-between">
												<Text
													as="span"
													className="text-sm font-bold capitalize"
												>
													{passenger?.fullName} ({passenger?.type})
												</Text>
												<Text as="span" className="text-sm">
													Passenger {idx + 1}{' '}
												</Text>
											</div>

											<NullTerniaryWrapper
												condition={
													seat?.seats &&
													Array.isArray(seat?.seats) &&
													seat?.seats.length > 0
														? true
														: false
												}
												className="mt-1"
											>
												{seat?.seats.map((item, seatIdx) => (
													<Text
														key={seatIdx}
														className={cn(
															'text-sm capitalize',
															seatIdx !== 0 && 'mt-1'
														)}
													>
														<span>
															{`${item?.direction} Flight (${item?.carrier?.code}${item?.flightNumber}) Seat:`}{' '}
														</span>
														<span className="font-bold">
															{item?.seatNumber}
														</span>
													</Text>
												))}
											</NullTerniaryWrapper>

											<NullTerniaryWrapper
												condition={!!assistanceOption?.label}
												className="mt-1"
											>
												<Text className="text-sm">
													<span className="font-bold">Special request: </span>
													<span>{assistanceOption?.label}</span>
												</Text>
											</NullTerniaryWrapper>
										</div>
									);
								})}
						</div>
					</NullTerniaryWrapper>
				</AccordionContent>
			</AccordionItem>
		</NullTerniaryWrapper>
	);
};

export default PassengerDetails;

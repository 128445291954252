import PropTypes from 'prop-types';
import { useMemo, useRef } from 'react';
import pluralize from 'pluralize';

import BlockLayout from '@components/holidays/molecules/block-layout';
import Button from '@/components/common/atoms/button';
import FareModal from '@/components/flights/molecules/fare-modal';
import FlightCard from '@/components/flights/molecules/flight-card';
import FlightBlockListItem from '@/components/flights/organisms/flight-block-listitem';
import useDisclosure from '@/hooks/useDisclosure';
import useBookingStore from '@/store/useBookingStore';
import cn from '@/lib/utils/tailwind';
import { currencyFormatter } from '@/lib/utils/currency';

/**
 * @typedef {Object} FlightBlockProps
 * @property {string} category
 * @property {number|string} holidayIdx
 * @property {number|string} itemParamIdx
 * @property {Object} flight
 * @property {Object} flightOptions
 * @property {Object} fareOptions
 * @property {"outbound" | "inbound"} direction
 * @property {() => React.ReactNode} renderStep
 * @property {(itemParamIdx, newItem, label, showLoader, onUpdatedState) => void} updateBookingState
 * @property {boolean} isChangeDisabled
 * @property {boolean} loading
 * */

/**
 * @name FlightBlock
 * @description Renders a travel plan block with the flight details
 * @param {FlightBlockProps} props
 * @returns {JSX.Element | null}
 * */
function FlightBlock({
	category,
	holidayIdx,
	itemParamIdx,
	flight,
	flightParams,
	flightOptions,
	outboundFareOptions,
	inboundFareOptions,
	direction,
	renderStep,
	updateBookingState,
	isChangeDisabled,
	loading,
	headerTitle,
}) {
	const containerRef = useRef(null);
	const { isOpen, onToggle, onClose } = useDisclosure(false);
	const state = useBookingStore((store) => store[category]);
	const { all: altFlights } = flightOptions;

	const isTransatFlight = useMemo(
		() => flightParams?.carrierCode ?
			flightParams?.carrierCode?.toUpperCase() === 'TS' :
			false,
		[flightParams?.carrierCode]
	);

	const fareOptions = useMemo(() => {
		// allow all fare options on Transat flights
		if (isTransatFlight) {
			return direction === 'outbound' ? outboundFareOptions : inboundFareOptions;
		}

		// only offer fares available in both directions on non-Transat flights
		if (!(outboundFareOptions?.length && inboundFareOptions?.length)) return [];
		switch (direction) {
			case 'outbound':
				return outboundFareOptions.filter(
					(outboundFare) => inboundFareOptions.some(
						(inboundFare) => inboundFare?.cabin?.code === outboundFare?.cabin?.code
					)
				);
			case 'inbound':
				return inboundFareOptions.filter(
					(inboundFare) => outboundFareOptions.some(
						(outboundFare) => outboundFare?.cabin?.code === inboundFare?.cabin?.code
					)
				);
		}
		return [];
	}, [direction, isTransatFlight, outboundFareOptions, inboundFareOptions]);

	const showChangeFlight = useMemo(() => {
		if (direction !== 'outbound') return false;

		// show upgrade if we have more than one outbound
		const outboundOptions = Object.keys(altFlights || {}).reduce(
			(outbounds, summaryKey) => [
				...outbounds,
				...Object.values(altFlights?.[summaryKey]?.outbound || {}),
			],
			[]
		);
		if (outboundOptions.length > 1) return true;

		// check if the outbound has more than 1 inbound option
		const outboundOption = outboundOptions.pop();
		const inboundKeys = Object.keys(outboundOption?.inbound || {});
		return inboundKeys.length > 1;
	}, [direction, altFlights]);

	const showChangeFare = useMemo(() => {
		// hide if there aren't any upgrades
		if (!fareOptions || fareOptions?.length <= 1) return false;

		// hide return toggle on non-transat flights (handled by outbound modal)
		if (!isTransatFlight && direction === 'inbound') return false;

		return true;
	}, [fareOptions, isTransatFlight, direction]);

	const currentSummary = useMemo(() => {
		if (direction !== 'outbound') return null;

		const summaryKey = flightParams?.holidaySummaryKey;
		if (!summaryKey) return null;

		return altFlights?.[summaryKey];
	}, [direction, altFlights, flightParams?.holidayUpgradeKey]);

	const nextFlightUpgrade = useMemo(() => {
		if (!currentSummary?.key) return null;

		return Object.values(altFlights || {}).find(
			(summary) => summary?.isNextUpgrade === true
		);
	}, [altFlights, currentSummary?.key]);

	const nextFareUpgrade = useMemo(() => {
		if (!fareOptions) return null;
		if (fareOptions?.length <= 1) return null;

		return fareOptions?.find((option) => option?.isNextUpgrade === true);
	}, [fareOptions]);

	const scrollToContainer = () => containerRef.current.scrollIntoView();

	const handleChange = (newOutbound, newInbound, label, showLoader) => {
		if (isChangeDisabled) return;

		if (!(newOutbound || newInbound)) return;
		if (!state?.selected?.items?.[holidayIdx]?.items?.[itemParamIdx]) return;

		const newItem = JSON.parse(
			JSON.stringify(state.selected.items[holidayIdx].items[itemParamIdx])
		);
		if (newOutbound) newItem.outbound = newOutbound;
		if (newInbound) newItem.inbound = newInbound;

		updateBookingState(itemParamIdx, newItem, label, showLoader, () => {
			// close change flight panel
			if (isOpen) onClose();
			scrollToContainer();
		});
	};

	const handleTransatFareChange = (newFare) => {
		// do not update params if selecting the current fare
		if (newFare?.cabin?.code === flightParams?.cabin) return;

		// handle outbound fare change
		if (direction === 'outbound') {
			handleChange(newFare.bookingItem, null, 'SET_TRANSAT_OUTBOUND_FARE_UPGRADE', false);
			return;
		}

		// handle inbound fare change
		handleChange(null, newFare.bookingItem, 'SET_TRANSAT_INBOUND_FARE_UPGRADE', false);
	}

	const handleOtherAirlineFareChange = (newFare) => {
		// other airlines - outbound and inbound fares must match
		let outboundUpgrade = null;
		let inboundUpgrade = null;
		switch (direction) {
			case 'outbound':
				outboundUpgrade = newFare.bookingItem;

				const newInboundFare = inboundFareOptions?.find(
					(fare) => fare?.cabin?.code === newFare?.cabin?.code
				);
				inboundUpgrade = newInboundFare?.bookingItem || null;
				break;

			case 'inbound':
				inboundUpgrade = newFare.bookingItem;

				const newOutboundFare = outboundFareOptions?.find(
					(fare) => fare?.cabin?.code === newFare?.cabin?.code
				);
				outboundUpgrade = newOutboundFare?.bookingItem || null;
				break;
		}

		handleChange(
			outboundUpgrade,
			inboundUpgrade,
			'SET_OTHER_AIRLINE_FARE_UPGRADES',
			false
		);
	}

	const handleFareChange = (newFare) => {
		if (!newFare?.bookingItem) return;

		if (isTransatFlight) {
			handleTransatFareChange(newFare);
			return;
		}

		handleOtherAirlineFareChange(newFare);
	};

	const handleFlightChange = (newOutbound, newInbound) => {
		if (!(newOutbound?.bookingItem && newInbound?.bookingItem)) return;

		handleChange(
			newOutbound.bookingItem,
			newInbound.bookingItem,
			'SET_FLIGHT_UPGRADE',
			true
		);
	};

	return (
		<BlockLayout
			ref={containerRef}
			loading={loading && !state?.error}
			title={`${direction === 'inbound' ? 'return' : direction} Flights`}
			headerTitle={headerTitle}
			iconName="plane"
			date={flight?.departureTime}
			renderStep={renderStep}
		>
			<div className="flex flex-col gap-4 p-5 lg:items-end lg:gap-0 lg:relative lg:pr-28 xl:pr-5">
				{flight?.carrier?.logo ? (
					<span className="flex items-center justify-center w-22.5 h-22.5 p-3 border align-left border-lighter-grey lg:w-20 lg:h-auto lg:border-0 lg:p-0 lg:absolute lg:right-5 lg:top-5">
						<img
							className="object-contain w-20 h-fit"
							src={flight.carrier.logo}
							alt={flight?.carrier?.name || flight?.carrier?.code}
						/>
					</span>
				) : null}
				<div className="grid w-full grid-cols-1 gap-4 xl:grid-cols-5">
					<div className="w-full col-span-3">
						<FlightCard
							hideFlightLogo
							flight={flight}
							detailsWrapperClassName="mt-4"
							timeClassName="text-base leading-less-snug md:text-2xl md:tracking-extra-tight"
							detailsClassName="bg-light-grey"
							detailsTimeClassName="text-base font-bold leading-extra-tight tracking-extra-tight"
							hideDetailsPlaneIcon={false}
						/>
					</div>
					<div className="flex flex-col items-start justify-end w-full col-span-2 gap-4 xl:gap-2.5 xl:items-end xl:pt-14">
						{showChangeFare ? (
							<FareModal
								carrier={flight?.carrier}
								fareOptions={fareOptions}
								selected={flightParams?.cabin}
								onSelect={handleFareChange}
								disabled={isChangeDisabled}
								confirmLabel={isTransatFlight ? 'Confirm' : 'Confirm*'}
								confirmDescription={!isTransatFlight ? '* confirming this fare will update both your outbound and return fares' : ''}
								renderTrigger={({ DialogTrigger, onOpen }) => (
									<DialogTrigger asChild>
										<Button
											hideIcon
											variant="unstyled"
											label={
												nextFareUpgrade?.pricing
													? `Upgrade fare${!isTransatFlight ? 's' : ''} from ${(nextFareUpgrade?.pricing?.priceDiffAdult || 0) >=
														0
														? '+'
														: ''
													}${currencyFormatter({
														amount:
															nextFareUpgrade?.pricing?.priceDiffAdult || 0,
													})}`
													: `${fareOptions?.length - 1} other ${pluralize(
														'fare',
														fareOptions?.length - 1
													)} available`
											}
											onClick={onOpen}
											className="w-auto font-bold underline underline-offset-4 text-core-blue"
											labelClassName="font-bold leading-snug"
											disabled={isChangeDisabled}
										/>
									</DialogTrigger>
								)}
							/>
						) : null}
						{showChangeFlight ? (
							<Button
								label={
									isOpen
										? 'Cancel'
										: `Change flights${!isChangeDisabled && nextFlightUpgrade
											? ` from ${(nextFlightUpgrade?.fromPriceDiff || 0) >= 0
												? '+'
												: ''
											}${currencyFormatter({
												amount: nextFlightUpgrade?.fromPriceDiff || 0,
											})}`
											: ''
										}`
								}
								variant={isOpen ? 'outline' : 'core-blue'}
								hideIcon
								className={cn('w-max ', {
									'bg-lightish-grey text-core-blue p-3 px-10 border-none hover:border-2 hover:border-lightish-grey':
										isOpen,
								})}
								onClick={onToggle}
								disabled={isChangeDisabled}
							/>
						) : null}
					</div>
				</div>
			</div>

			{isOpen && (
				<div className="px-2.5 pt-2.5 md:border-t-5 md:border-core-blue bg-light-grey lg:px-4 lg:py-6">
					<div className="w-full bg-white border border-dark-grey/30">
						{Object.values(altFlights || {}).map((summary) => (
							<FlightBlockListItem
								key={summary?.key}
								overview={summary}
								selected={currentSummary}
								onSelect={handleFlightChange}
								disabled={isChangeDisabled}
							/>
						))}
					</div>
				</div>
			)}
		</BlockLayout>
	);
}

FlightBlock.propTypes = {
	category: PropTypes.string,
	holidayIdx: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
		.isRequired,
	itemParamIdx: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
		.isRequired,
	flight: PropTypes.object,
	flightOptions: PropTypes.shape({
		all: PropTypes.object,
		cheapest: PropTypes.object,
	}),
	outboundFareOptions: PropTypes.array,
	inboundFareOptions: PropTypes.array,
	direction: PropTypes.oneOf(['inbound', 'outbound']),
	renderStep: PropTypes.func,
	updateBookingState: PropTypes.func.isRequired,
	isChangeDisabled: PropTypes.bool,
	loading: PropTypes.bool,
};

FlightBlock.defaultProps = {
	flight: {},
	flightOptions: {
		all: {},
		cheapest: {},
	},
	direction: 'outbound',
	isChangeDisabled: false,
	loading: false,
};

export default FlightBlock;

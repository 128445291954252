import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import qs from 'qs';

import { cn } from '@/lib/utils';
import { useBreadcrumbs, useSearchSave } from '@/hooks';
import { Icon, Text } from '@/components/common';
import { useLocation } from 'react-router-dom';
import { useBookingStore } from '@/store';

/**
 * @typedef {Object} StepBreadcrumbProps
 * @property {import("@/hooks/useBreadcrumbs").Crumb} steps
 * @property {boolean} disabled
 * @property {string} className
 **/

/**
 * @name StepBreadcrumb Component
 * @description This component is used to create breadcrumbs that show the current page and future pages.
 * @param {StepBreadcrumbProps} props
 * @returns {React.JSX.Element}
 * @example
 * <StepBreadcrumb steps={steps} />
 * */

function StepBreadcrumb({ steps, className, disabled, category }) {
	const { pathname } = useLocation();
	const state = useBookingStore((store) => store[category]);
	const { crumbs, activeCrumbIndex } = useBreadcrumbs({
		pages: steps,
		showNextPages: true,
	});

	const { getSearch } = useSearchSave();
	const savedSearch = getSearch(category);

	const formatLink = (href) => {
		let link = href?.to;

		if (href?.search) {
			link = href?.search.startsWith('?')
				? `${href?.to}${href?.search}`
				: `${href?.to}?${href?.search}`;
		}

		// handle hotel/ holiday first page link
		if (category === 'hotels' || category === 'holidays') {
			if (state?.selected?.pageUrl) {
				link = state?.selected?.pageUrl;
			} else {
				if (savedSearch) {
					link = `${link}?${savedSearch}`;
				}
			}
		}
		return link;
	};

	const linkComponent = (link, index) => {
		if (link?.to?.toLowerCase().includes('search') && index === 0) {
			let hrefLink = formatLink(link);

			if (category === 'hotels') {
				const accommodationItem = state?.preview?.breakdown.filter(
					(i) => i.type === 'accommodation'
				)[0];
				const sortedSavedSearch = qs.stringify({
					...qs.parse(savedSearch),
					tab: 'hotels',
				});
				hrefLink =
					savedSearch && savedSearch !== 'undefined'
						? `${accommodationItem?.url}?${sortedSavedSearch}`
						: `${accommodationItem?.url}?tab=hotels`;
			}

			return (
				<a
					href={hrefLink}
					className="text-base font-medium truncate"
					tabIndex={disabled ? -1 : null}
				>
					{link.name}
				</a>
			);
		} else if (
			category === 'holidays' &&
			link?.to?.toLowerCase().includes('destinations') &&
			index === 1
		) {
			const accommodationItem = state?.preview?.breakdown.filter(
				(i) => i.type === 'accommodation'
			)[0];

			const parsedSavedSearch = qs.parse(savedSearch);
			parsedSavedSearch.rooms.map((r) => delete r.room);
			return (
				<a
					href={
						savedSearch
							? `${accommodationItem.url}?${qs.stringify(parsedSavedSearch)}`
							: accommodationItem.url
					}
					className="text-base font-medium truncate"
					tabIndex={disabled ? -1 : null}
				>
					{link.name}
				</a>
			);
		}
		return (
			<Link
				to={link?.search ? `${link?.to}${link?.search}` : link.to}
				className="text-base font-medium truncate go"
				state={{ from: pathname }}
				tabIndex={disabled ? -1 : null}
			>
				{link.name}
			</Link>
		);
	};

	return (
		<div
			className={cn(
				'@container flex items-center gap-2 text-core-blue',
				className
			)}
		>
			<Text className="flex gap-1 items-center @2xl:hidden text-sm md:text-base font-semibold tracking-tighter leading-less-snug">
				<span>Step {activeCrumbIndex + 1}</span>
				<span>of</span>
				<span>{crumbs && crumbs.length}</span>
			</Text>

			{crumbs.length > 0
				? crumbs.map((link, index) => (
						<span
							key={link.name}
							className={cn('hidden items-center gap-2 capitalize @3xl:flex', {
								'pointer-events-none opacity-75': disabled,
								'cursor-pointer': !disabled && index <= activeCrumbIndex,
								'opacity-40 pointer-events-none': index > activeCrumbIndex,
							})}
						>
							{linkComponent(link, index)}
							<Icon
								name="arrow-right"
								className={cn('w-5', index === crumbs.length - 1 && 'hidden')}
							/>
						</span>
				  ))
				: null}
		</div>
	);
}

StepBreadcrumb.propTypes = {
	steps: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			to: PropTypes.string.isRequired,
			search: PropTypes.string,
		})
	).isRequired,
	className: PropTypes.string,
};

export default StepBreadcrumb;

import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
	Text,
	Heading,
	Skeleton,
	Accordion,
	AccordionItem,
	DatePlaceHolder,
	AccordionContent,
	AccordionTrigger,
	TravelPlanPrice,
	NullTerniaryWrapper,
	Alert,
} from '@components/common';
import { useBookingStore } from '@/store';
import { cn, currencyFormatter, getCurrentStep } from '@/lib/utils';
import { useValueCallback } from '@/hooks';
import { getSteps } from '@/lib/steps';
import { useLocation } from 'react-router-dom';
import Accommodation from './blocks/accommodation';
import Flight from './blocks/flight';
import Extras from './blocks/extras';
import PassengerDetails from './blocks/passengerDetails';
import Baggage from './blocks/baggage';

/**
 * @typedef {Object} TravelPlanDetailsProps
 * @property {Array} details
 * @property {string} className
 * @property {() => {}} handleTotalPrice
 * @property {() => {}} handleShowSaveForLater
 * @property {() => {}} handleAtolProtected
 **/

/**
 * @name TravelPlanDetails
 * @description A component that shows travel details for hotels
 * @param {TravelPlanDetailsProps} props
 * @returns {React.JSX.Element}
 * @example
 * <TravelPlanDetails details={details} />
 **/
function TravelPlanDetails({
	className,
	handleTotalPrice,
	handleShowSaveForLater,
	handleAtolProtected,
}) {
	const { pathname } = useLocation();
	const state = useBookingStore((store) => store.holidays);

	const isPassengerDetailsPage = useMemo(() => {
		const steps = getSteps('holidays');
		if (!steps) return false;

		const currentStep = getCurrentStep(steps, pathname);
		if (!currentStep?.to) return false;

		return currentStep.to.includes('-details');
	}, [pathname]);

	// navigate away if landing on page without selecting a hotel via booking widget
	useEffect(() => {
		if (!isPassengerDetailsPage) return;

		// wait until bookingState has loaded - error prop should always be defined once initialized
		if (typeof state?.error === 'undefined') return;

		if (!state?.selected) window.location.href = '/';
	}, [state?.selected, state?.error, isPassengerDetailsPage]);

	const preview = useMemo(() => {
		if (!state?.preview) return null;
		return state?.preview;
	}, [state?.preview]);

	const totalPrice = useMemo(() => {
		const overview = state?.preview?.overview;
		if (!overview) return 0;
		return overview?.total;
	}, [state?.preview?.overview]);

	useEffect(() => {
		if (handleTotalPrice) handleTotalPrice(totalPrice);
	}, [totalPrice, handleTotalPrice]);

	// useValueCallback(handleTotalPrice, totalPrice);

	useValueCallback((newPreview) => {
		// determine whether to show save for later
		if (typeof handleShowSaveForLater === 'function') {
			if (typeof newPreview?.quotable !== 'undefined') {
				handleShowSaveForLater(newPreview.quotable === true);
			}
		}

		// determine whether to show atol protected
		if (typeof handleAtolProtected === 'function') {
			if (typeof newPreview?.isAtolProtected !== 'undefined') {
				handleAtolProtected(newPreview.isAtolProtected === true);
			}
		}
	}, state?.preview);

	const blocks = [];

	if (preview?.breakdown && Array.isArray(preview?.breakdown)) {
		preview?.breakdown.map((option, idx) => {
			switch (option.type) {
				case 'accomodation':
				case 'accommodation':
					blocks.push(
						<Accommodation
							key={option?.ref}
							option={option}
							showPricingBreakdown={preview?.showPricingBreakdown}
						/>
					);
					break;
				case 'flight':
				case 'flights':
					blocks.push(
						<Flight
							key={option?.ref}
							option={option}
							showPricingBreakdown={preview?.showPricingBreakdown}
						/>
					);
					break;
				case 'flightOptionPlus':
					blocks.push(
						<Extras key={`flightOptionPlus-${idx}`} option={option} />
					);
					break;
				case 'flightBaggage':
					blocks.push(<Baggage key={`flightBaggage-${idx}`} option={option} />);
					break;
				case 'flightSeats':
					blocks.push(
						<PassengerDetails
							key={`flightSeats-${idx}`}
							pax={preview?.pax}
							flightSeats={option}
							stateBookingDetailsPax={state?.bookingDetails?.pax}
						/>
					);
					break;
			}
		});
	}

	return (
		<div className={cn('', className)}>
			<NullTerniaryWrapper condition={!!state?.error} animate>
				<Alert
					className="mb-2"
					variant="destructive"
					title="Houston, we have a problem!"
					subtitle="Apologies, we have encountered an error with your request. Please <a href='mailto:enquiries@canadianaffair.com?subject=Website%20Error%20Query' target='_top' class='underline underline-offset-2'>email us</a> for assistance."
					showHtmlSubtitle={true}
				>
					{state?.error?.message}
				</Alert>
			</NullTerniaryWrapper>

			<NullTerniaryWrapper
				condition={state?.showFlightsChangedWarning ? true : false}
				animate
			>
				<Alert
					className="mb-2"
					variant="destructive"
					title="Notice: Your flight details have changed."
					subtitle="Please review before continuing."
					subtitleClassName="mb-0"
				/>
			</NullTerniaryWrapper>

			{state?.previewLoading && !state?.preview ? (
				<div className="flex flex-col items-start w-full gap-2">
					<Skeleton className="w-full h-4" />
					<Skeleton className="w-[80%] h-4" />
					<Skeleton className="w-[60%] h-4" />
				</div>
			) : (
				<Accordion collapsible>{blocks}</Accordion>
			)}

			<TravelPlanPrice
				total={totalPrice}
				isLoading={state?.previewLoading || state?.isLoading}
			/>
		</div>
	);
}

TravelPlanDetails.propTypes = {
	details: PropTypes.array.isRequired,
	className: PropTypes.string,
	handleTotalPrice: PropTypes.func,
	handleShowSaveForLater: PropTypes.func,
	handleAtolProtected: PropTypes.func,
};

TravelPlanDetails.defaultProps = {
	details: [],
	className: '',
};

export default TravelPlanDetails;

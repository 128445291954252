import * as yup from 'yup';
import { HOTEL_WHO_OPTIONS } from '@/lib/utils';

export const holidaySearchSchema = (showDatePicker) => yup.object().shape({
    type: yup
        .object()
        .nullable()
        .shape({
            value: yup.string().required(),
            label: yup.string(),
        })
        .required('Required'),
    location: yup
        .object()
        .nullable()
        .shape({
            value: yup.string().required(),
            label: yup.string(),
        })
        .required('Required'),
    from: yup
        .object()
        .nullable()
        .shape({
            value: yup.string().required(),
            label: yup.string(),
        })
        .required('Required'),
    roomCount: yup
        .object()
        .nullable()
        .shape({
            value: yup.string().required('At least one room is required'),
            label: yup.string(),
        })
        .required('At least one room is required'),
    rooms: yup.array().when('roomCount', {
        is: (roomCount) => roomCount && roomCount.value >= 1,
        then: () => yup.array().of(
            yup.object().shape(
                HOTEL_WHO_OPTIONS.reduce((acc, option) => {
                    if (option.value === 'adults') {
                        acc[option.value] = yup
                            .object()
                            .shape({
                                value: yup.string().required('At least one adult is required'),
                                label: yup.string(),
                            })
                            .required('At least one adult is required');

                        return acc;
                    }
                    acc[option.value] = yup.object().nullable().shape({
                        value: yup.string(),
                        label: yup.string(),
                    });
                    return acc;
                }, {})
            )
        ),
    }),
    month: yup
        .object()
        .nullable()
        .shape({
            value: yup.string(),
            label: yup.string(),
        })
        .when('when', {
            is: (when) => !showDatePicker,
            then: () => yup.object()
                .shape({
                    value: yup.string().required(),
                    label: yup.string(),
                })
                .required('Required'),
        }),
    duration: yup
        .object()
        .nullable()
        .shape({
            value: yup.string(),
            label: yup.string(),
        })
        .when('month', {
            is: (month) => !showDatePicker,
            then: () => yup.object()
                .shape({
                    value: yup.string().required(),
                    label: yup.string(),
                })
                .required('Required'),
        }),
    when: yup
        .object()
        .nullable()
        .shape({
            from: yup.date(),
            to: yup.date().min(yup.ref('from'), 'End date must be after starting date'),
        })
        .when(['month'], {
            is: (month) => showDatePicker,
            then: () => yup.object()
                .shape({
                    from: yup.date().required('Start date is required'),
                    to: yup.date().min(yup.ref('from'), 'End date must be after starting date').required('End date is required'),
                })
        }),
}, [
    ['month', 'duration'],
    ['month', 'when']
]);

import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
	Text,
	Heading,
	Skeleton,
	Accordion,
	AccordionItem,
	DatePlaceHolder,
	AccordionContent,
	AccordionTrigger,
	TravelPlanPrice,
	NullTerniaryWrapper,
	Alert,
} from '@components/common';
import { useBookingStore } from '@/store';
import { cn, currencyFormatter, getCurrentStep } from '@/lib/utils';
import { useValueCallback } from '@/hooks';
import { getSteps } from '@/lib/steps';
import { useLocation } from 'react-router-dom';

/**
 * @typedef {Object} TravelPlanDetailsProps
 * @property {Array} details
 * @property {string} className
 * @property {() => {}} handleTotalPrice
 * @property {() => {}} handleShowSaveForLater
 **/

/**
 * @name TravelPlanDetails
 * @description A component that shows travel details for hotels
 * @param {TravelPlanDetailsProps} props
 * @returns {React.JSX.Element}
 * @example
 * <TravelPlanDetails details={details} />
 **/
function TravelPlanDetails({
	className,
	handleTotalPrice,
	handleShowSaveForLater,
}) {
	const { pathname } = useLocation();
	const state = useBookingStore((store) => store.hotels);

	const isPassengerDetailsPage = useMemo(() => {
		const steps = getSteps('hotels');
		if (!steps) return false;

		const currentStep = getCurrentStep(steps, pathname);
		if (!currentStep?.to) return false;

		return currentStep.to.includes('-details');
	}, [pathname]);

	// navigate away if landing on page without selecting a hotel via booking widget
	useEffect(() => {
		if (!isPassengerDetailsPage) return;

		// wait until bookingState has loaded - error prop should always be defined once initialized
		if (typeof state?.error === 'undefined') return;

		if (!state?.selected) window.location.href = '/';
	}, [state?.selected, state?.error, isPassengerDetailsPage]);

	const preview = useMemo(() => {
		if (!state?.preview) return null;
		return state?.preview;
	}, [state?.preview]);

	const totalPrice = useMemo(() => {
		const overview = state?.preview?.overview;
		if (!overview) return 0;
		return overview?.total;
	}, [state?.preview?.overview]);

	useEffect(() => {
		if (handleTotalPrice) handleTotalPrice(totalPrice);
	}, [totalPrice, handleTotalPrice]);

	// useValueCallback(handleTotalPrice, totalPrice);

	// determine whether to show save for later
	useValueCallback(
		typeof handleShowSaveForLater === 'function'
			? (newPreview) => {
					if (typeof newPreview?.quotable !== 'undefined') {
						handleShowSaveForLater(newPreview.quotable === true);
					}
			  }
			: null,
		state?.preview
	);

	return (
		<div className={cn('', className)}>
			<NullTerniaryWrapper condition={!!state?.error} animate>
				<Alert
					className="mb-2"
					variant="destructive"
					title="Houston, we have a problem!"
					subtitle="Apologies, we have encountered an error with your request. Please <a href='mailto:enquiries@canadianaffair.com?subject=Website%20Error%20Query' target='_top' class='underline underline-offset-2'>email us</a> for assistance."
					showHtmlSubtitle={true}
				>
					{state?.error?.message}
				</Alert>
			</NullTerniaryWrapper>

			{state?.previewLoading && !state?.preview ? (
				<div className="flex flex-col items-start w-full gap-2">
					<Skeleton className="w-full h-4" />
					<Skeleton className="w-[80%] h-4" />
					<Skeleton className="w-[60%] h-4" />
				</div>
			) : (
				<Accordion collapsible>
					{preview?.breakdown &&
						Array.isArray(preview?.breakdown) &&
						preview?.breakdown.map((option) => (
							<AccordionItem
								key={`${option?.ref}-${option?.code}`}
								value={`${option?.ref}-${option?.code}`}
								className="mb-2"
							>
								<AccordionTrigger
									asChild
									className="flex-row-reverse items-start justify-end w-full gap-2 tracking-normal cursor-pointer "
								>
									<Text
										as="p"
										className="flex items-center justify-between w-full font-bold"
									>
										<Text as="span" className="font-bold">
											Hotel
										</Text>
										<Text as="span" className="font-bold">
											{currencyFormatter({
												amount: option?.total,
											})}
										</Text>
									</Text>
								</AccordionTrigger>
								<AccordionContent>
									<div className="flex flex-col py-1">
										<Heading
											as="h3"
											className="text-base font-semibold font-body"
										>
											{option?.pagetitle || option?.name}
										</Heading>

										{option?.rooms?.length >= 1
											? option.rooms.map((room) => (
													<div
														className="flex flex-col py-1"
														key={room?.number}
													>
														<Text className="font-light">
															Room {room?.number}: {room?.name}
															{room?.boardBasis
																? ` - ${
																		room?.boardBasisDisplayName ||
																		room?.boardBasis
																  }`
																: ''}
														</Text>
														<DatePlaceHolder
															hideTime
															showDifference
															selected={{
																from: option?.startDate,
																to: option?.endDate,
															}}
															dateFormat="dd MMM, yyyy"
														/>
														{room?.prices ? (
															<div className="mt-4">
																{room?.prices?.map((price) => (
																	<div
																		key={price?.name}
																		className="flex items-center justify-between mb-2"
																	>
																		<Text as="span" className="font-normal">
																			{price?.name}
																		</Text>
																		<Text as="span" className="font-bold">
																			{currencyFormatter({
																				amount: price?.price,
																			})}
																		</Text>
																	</div>
																))}
															</div>
														) : null}
													</div>
											  ))
											: null}
									</div>
								</AccordionContent>
							</AccordionItem>
						))}
				</Accordion>
			)}

			<TravelPlanPrice
				total={totalPrice}
				isLoading={state?.previewLoading || state?.isLoading}
			/>
		</div>
	);
}

TravelPlanDetails.propTypes = {
	details: PropTypes.array.isRequired,
	className: PropTypes.string,
};

TravelPlanDetails.defaultProps = {
	details: [],
	className: '',
};

export default TravelPlanDetails;

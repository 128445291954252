import { currencyFormatter } from '@/lib/utils';
import {
	Text,
	AccordionItem,
	AccordionContent,
	AccordionTrigger,
	NullTerniaryWrapper,
} from '@components/common';

const Baggage = ({ option }) => {
	return (
		<NullTerniaryWrapper condition={option && option?.total > 0 ? true : false}>
			<AccordionItem value="baggage" className="mb-2">
				<AccordionTrigger
					asChild
					className="flex-row-reverse items-start justify-end w-full gap-2 tracking-normal cursor-pointer"
				>
					<Text
						as="p"
						className="flex items-center justify-between w-full font-bold"
					>
						<Text as="span" className="font-bold">
							Additional Baggage
						</Text>
						<Text as="span" className="font-bold">
							{currencyFormatter({
								amount: option?.total ?? 0,
							})}
						</Text>
					</Text>
				</AccordionTrigger>
				<AccordionContent value="baggage">
					{option?.prices &&
						Object.keys(option?.prices).map((person, idx) => {
							const personDetails = option?.prices[person];

							if (!personDetails?.total) return null;
							return (
								<div className="my-1" key={`baggage-${idx}`}>
									<div className="flex items-center justify-between">
										<Text as="span" className="text-sm font-bold capitalize">
											{person} Totals
										</Text>
										<Text as="span" className="text-sm">
											{currencyFormatter({
												amount: personDetails?.total ?? 0,
											})}
										</Text>
									</div>
								</div>
							);
						})}
				</AccordionContent>
			</AccordionItem>
		</NullTerniaryWrapper>
	);
};

export default Baggage;

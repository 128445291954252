import PropTypes from 'prop-types';

import Text from '@/components/common/atoms/text';
import pluralize from 'pluralize';

function RouteDuration({ flight }) {
	return (
		<Text className="text-sm font-normal leading-snug tracking-tighter text-dark-grey">
			{typeof flight?.stops === 'number' && (
				<span>
					{flight.stops === 0
						? 'Direct'
						: `${flight.stops} ${pluralize('stop', flight.stops)}`}
				</span>
			)}
			{flight?.duration && <span> ({flight?.duration})</span>}
		</Text>
	);
}

RouteDuration.propTypes = {
	flight: PropTypes.object.isRequired,
};

export default RouteDuration;

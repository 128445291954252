import {
	FormProvider,
	useFieldArray,
	useForm,
	useFormState,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
	Text,
	Button,
	Heading,
	FormField,
	// ContactForm,
	// PassengerForm,
	ToggleButtonGroup,
	BookingPageWrapper,
	// PreferredContactForm,
	LoopWrapper,
	RteStyledContent,
} from '@/components/common';
import ContactForm from '@/components/common/forms/ContactForm';
import PassengerForm from '@/components/common/forms/PassengerForm';
import PreferredContactForm from '@/components/common/forms/PreferredContactForm';
import { getSteps } from '@/lib/steps';
import { formatYmd, getNextStep } from '@/lib/utils';
import { capitalizeString } from '@/lib/utils/string';
import {
	useBackNavigate,
	useErrata,
	useUrlParams,
	useMetaDescription,
} from '@/hooks';
import { getPassengerSchema } from '@/validationSchemas';

/**
 * @typedef {Object} PassengerDetailsTemplateProps
 * @property {Object} state - state from the category booking store
 * @property {"flights" | "hotels" | "holidays" | "multiholidays"} category
 * @property {Array<{label: string, value: string}>} assistanceOptions
 * @property {(params: {passengerDetails: object, bookingDetails: object})=> void} handleContinue - receives passenger details and booking details
 **/

/**
 * @name PassengerDetailsTemplate
 * @description This component is used to render the passenger details page.
 * @param {PassengerDetailsTemplateProps} props
 * @returns {React.JSX.Element}
 * @example
 * <PassengerDetailsTemplate state={state} category="flights" handleContinue={handleContinue} />
 **/

function PassengerDetailsTemplate({
	state,
	category,
	assistanceOptions,
	handleContinue,
}) {
	useMetaDescription([
		'Passenger Details',
		capitalizeString(category),
		'Canadian Affair',
	]);
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { params: urlParams } = useUrlParams();

	const questions = state?.questions || [];

	const [answers, setAnswers] = useState(() => {
		if (!questions?.length) return [];

		if (state?.bookingDetails?.questions?.length) {
			return state.bookingDetails.questions;
		}

		return questions.map((question) => ({
			id: question?.id,
			question: question?.question,
			optional: question?.optional,
			answer: {
				id: question?.answers[0]?.id,
				answer: question?.answers[0]?.answer,
			},
		}));
	});

	const setQuestions = useCallback(() => {
		if (!questions?.length) return;
		if (state?.bookingDetails?.questions?.length) return;

		setAnswers(
			questions.map((question) => ({
				id: question?.id,
				question: question?.question,
				optional: question?.optional,
				answer: {
					id: question?.answers[0]?.id,
					answer: question?.answers[0]?.answer,
				},
			}))
		);
	}, [questions]);

	useEffect(() => {
		setQuestions();
	}, [setQuestions]);

	const pageSteps = useMemo(() => getSteps(category), [category]);

	const formatPeople = (type, total) =>
		Array.from({ length: total ? parseInt(total) : 0 }).map(() => ({
			type: type,
		}));

	const people = useMemo(() => {
		let pax = [];

		switch (category) {
			case 'holidays':
			case 'hotels':
				// get total people from room allocation
				const sortedItems =
					category === 'holidays'
						? state?.selected?.items[0]?.items
						: state?.selected?.items;

				const hotel = sortedItems?.length
					? sortedItems.find((item) => item?.type === 'accommodation')
					: null;

				if (!hotel?.rooms?.length) break;

				pax = hotel.rooms.reduce((all, room, roomIdx) => {
					if (typeof room !== 'object') return all;

					const { adults, children, infants } = room;
					return [
						...all,
						...formatPeople('adult', adults, roomIdx),
						...formatPeople('hotel-child', children, roomIdx),
						...formatPeople('infant', infants, roomIdx),
					];
				}, []);

				break;

			default:
				// get total people from url params
				let totalPeople = 1;

				if (urlParams.default?.who) {
					totalPeople = Object.keys(urlParams.default?.who ?? {}).reduce(
						(acc, key) => {
							const value = urlParams.default?.who?.[key];
							return acc + (value ? parseInt(value.split(':')[0]) : 0);
						},
						0
					);
				}

				if (totalPeople === 1 && urlParams.default?.people) {
					totalPeople = parseInt(urlParams.default?.people);
				}

				pax = formatPeople(null, totalPeople); // allow any age

				break;
		}

		return pax;
	}, [
		category,
		state?.selected?.rooms,
		urlParams.default?.who,
		urlParams?.default?.people,
	]);

	const defaultValues = useMemo(() => {
		if (!state?.passengerDetails) {
			const defaults = {
				...passengerDefaultValues,
				passengers: people.map(() => ({
					...passengerDefaultValues.passengers[0],
				})),
			};

			// select first assistance option if provided
			if (assistanceOptions?.length && people?.length) {
				defaults.passengers = people.map(() => {
					const pax = { ...(passengerDefaultValues.passengers[0] || {}) };
					if (assistanceOptions?.length) pax.assistance = assistanceOptions[0];
					return pax;
				});
			}

			return defaults;
		}

		if (state?.passengerDetails?.passengers?.length !== people?.length) {
			const newPassengers = people.map((_, idx) => {
				const passenger = state?.passengerDetails?.passengers?.[idx] || {}; // get the passenger if it exists

				// if the passenger exists, return the passenger with the default values
				return {
					...passengerDefaultValues.passengers[0],
					...passenger,
				};
			});
			return {
				...state?.passengerDetails,
				passengers: newPassengers,
			};
		}
		return state?.passengerDetails;
	}, [state?.passengerDetails, people?.length]);

	const methods = useForm({
		defaultValues,
		mode: 'onBlur',
		resolver: yupResolver(getPassengerSchema(assistanceOptions)),
	});

	const {
		reset,
		control,
		handleSubmit,
		formState: { errors },
	} = methods;

	const { fields } = useFieldArray({
		control,
		name: 'passengers',
	});

	useEffect(() => {
		reset(defaultValues);
	}, [defaultValues, reset]);

	const onSubmit = (values) => {
		// push to the next page
		const nextStep = getNextStep(pageSteps, pathname);

		// get passenger details
		const paxDetails = values?.passengers.map((passenger, idx) => {
			const dob = formatYmd({
				day: passenger?.dob?.day,
				month: passenger?.dob?.month,
				year: passenger?.dob?.year,
			});
			const title = passenger?.title?.value || '';
			const gender = passenger?.gender?.value || '';
			const assistance = passenger?.assistance?.value || null;
			return {
				...passenger,
				ref: idx + 1,
				dob,
				title,
				gender,
				assistance,
			};
		});

		// booking params
		const bookingDetails = {
			pax: paxDetails,
			questions: answers,
			contactDetails: {
				email: values?.contact?.email,
				phone: values?.contact?.phone,
				address1: values?.contact?.address?.address1,
				address2: values?.contact?.address?.address2,
				county: values?.contact?.address?.county,
				postcode: values?.contact?.address?.postcode,
				town: values?.contact?.address?.town,
			},
			marketing: {
				mail: 'false',
				tel: values?.marketing?.phone === 'yes' ? 'true' : 'false',
				email: values?.marketing?.email === 'yes' ? 'true' : 'false',
			},
		};

		handleContinue({ passengerDetails: values, bookingDetails });
		// navigate to the next page
		navigate(nextStep.to);
	};

	const formatPassengerType = (index, type) => {
		// lead passenger must always be an adult
		if (index === 0) return 'Adult';

		// other passengers
		switch (type) {
			case 'adult':
				return 'Adult';
			case 'child':
			case 'hotel-child':
				return 'Child';
			case 'infant':
				return 'Infant';
		}
	};

	const { handleBack, previousStep } = useBackNavigate(pageSteps, category);

	const continueIsDisabled =
		state?.previewLoading ||
		state?.isLoading ||
		Object.keys(errors).length > 0 ||
		state?.error;

	return (
		<BookingPageWrapper
			steps={pageSteps}
			category={category}
			title={category === 'hotels' ? 'Traveller Details' : 'Passenger Details'}
			previousPage={previousStep}
			onContinue={handleSubmit(onSubmit)}
			continueIsDisabled={continueIsDisabled}
			renderFooterContent={() => (
				<FooterContent
					control={control}
					category={category}
					handleBack={handleBack}
					previousStep={previousStep}
					handleSubmit={handleSubmit(onSubmit)}
					isDisabled={continueIsDisabled}
				/>
			)}
		>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="flex flex-col gap-6">
						{category !== 'hotels' && (
							<div className="p-8 border border-dark-grey">
								<Text className="my-2 font-semibold">
									Please make sure full names and date of birth details are
									entered correctly, as per passport details
								</Text>
								<Text className="text-dark-grey/70">
									The details entered below will appear on the tickets and
									cannot be changed. Special meal requests must be received with
									a minimum of 72 hours notice prior to departure. Meals are not
									provided for infants.
								</Text>
							</div>
						)}

						<div className="flex flex-col max-w-3xl gap-5">
							<div className="flex flex-col gap-5">
								{fields.map((field, index) => (
									<div key={`passenger-form-${field?.id}`}>
										<Heading
											as="h2"
											className="my-4 text-3xl font-bold font-body"
										>
											{category === 'hotels' ? 'Traveller' : 'Passenger'}{' '}
											{index + 1}{' '}
											{(category === 'hotels' || category === 'holidays') && people?.[index]?.type
												? `(${formatPassengerType(
														index,
														people?.[index]?.type
												  )})`
												: ''}
										</Heading>
										<PassengerForm
											index={index}
											ageGroup={people?.[index]?.type}
											errors={errors}
											control={control}
											assistanceOptions={assistanceOptions}
											type={category === 'hotels' ? 'traveller' : 'passenger'}
										/>
									</div>
								))}
							</div>
							<div>
								<Heading as="h2" className="my-4 text-3xl font-bold font-body">
									Contact Details
								</Heading>
								<ContactForm />
							</div>
							<div className="w-full">
								<PreferredContactForm />

								{Array.isArray(questions) && questions.length > 0 && (
									<div>
										<Heading
											as="h3"
											className="my-4 text-xl font-bold font-body"
										>
											Special offers
										</Heading>
										<div className="flex flex-col gap-3">
											{questions.map((question) => (
												<div key={question?.id} className="flex flex-col gap-2">
													<Text as="label">{question?.question}?</Text>
													<ToggleButtonGroup
														key={question?.id}
														instance="formField"
														value={
															answers.find(
																(answer) => answer?.id === question?.id
															)?.answer?.id
														}
														onChange={(value) => {
															// find the question and update the answer and answer id
															if (!value || value === '') return;

															const questionIndex = answers.findIndex(
																(answer) => answer?.id === question?.id
															);
															const newAnswers = [...answers];

															newAnswers[questionIndex].answer.id = value;
															newAnswers[questionIndex].answer.answer =
																question?.answers.find(
																	(answer) => answer?.id === value
																)?.answer;

															setAnswers(newAnswers);
														}}
														label={`${question?.question}?`}
														options={question?.answers?.map((answer) => ({
															label: answer?.answer,
															value: answer?.id,
														}))}
													/>
												</div>
											))}
										</div>
									</div>
								)}
							</div>
							<Text className="my-4 text-dark-grey/65">
								By providing the details below, we will be able to make your
								emails more personalised and relevant to you. Rest assured that
								your privacy is important to us and your details will not be
								shared with any 3rd party marketing companies. For more
								information, please read our{' '}
								<a
									className="underline underline-offset-4"
									href="/travel-centre/booking-with-confidence/privacy-policy"
									target="_blank"
								>
									Privacy Policy
								</a>
								.
							</Text>
						</div>
					</div>
				</form>
			</FormProvider>
		</BookingPageWrapper>
	);
}

const passengerDefaultValues = {
	passengers: [
		{
			title: null,
			firstName: '',
			middleName: '',
			lastName: '',
			gender: null,
			dob: {
				day: null,
				month: null,
				year: null,
			},
			assistance: null,
		},
	],
	contact: {
		phone: '',
		email: '',
		address: {
			address1: '',
			address2: '',
			town: '',
			county: '',
			postcode: '',
		},
	},
	marketing: {
		email: 'no',
		phone: 'no',
	},
	terms_conditions: false,
	read_information: false,
};

function FooterContent({
	control,
	handleBack,
	handleSubmit,
	previousStep,
	isDisabled,
	category,
}) {
	const { errors } = useFormState({ control });

	const errata = useErrata(category);
	const renderErrata = useMemo(() => {
		if (!errata) return null;

		if (category === 'flights' || category === 'holidays') {
			const { outErrata, inErrata, hotelErrata } = errata;

			return (
				<>
					{category === 'holidays' && (
						<LoopWrapper list={hotelErrata}>
							{(errata) => (
								<RteStyledContent
									variant="atcom-rte"
									dangerouslySetInnerHTML={{
										__html: errata.safeHtml,
									}}
								/>
							)}
						</LoopWrapper>
					)}
					<LoopWrapper list={outErrata}>
						{(errata) => (
							<RteStyledContent
								variant="atcom-rte"
								dangerouslySetInnerHTML={{
									__html: errata.safeHtml,
								}}
							/>
						)}
					</LoopWrapper>
					<LoopWrapper list={inErrata}>
						{(errata) => (
							<RteStyledContent
								variant="atcom-rte"
								dangerouslySetInnerHTML={{
									__html: errata.safeHtml,
								}}
							/>
						)}
					</LoopWrapper>
				</>
			);
		}

		return (
			<LoopWrapper list={errata}>
				{(errata) => (
					<RteStyledContent
						variant="atcom-rte"
						dangerouslySetInnerHTML={{
							__html: errata.safeHtml,
						}}
					/>
				)}
			</LoopWrapper>
		);
	}, [errata, category]);

	return (
		<section className="w-full py-10 space-y-5 lg:max-w-4xl">
			<Heading as="h3" className="text-2xl font-bold font-body">
				Important Information
			</Heading>
			<div className="space-y-3">{renderErrata}</div>
			<Text className="my-4 text-dark-grey/65">
				All information collected is intended for Canadian Affair, the data
				controller, for the purposes of processing your bookings or
				subscriptions to our newsletters and/or programs. It may also be used
				for the conduct of marketing and statistical studies, to customise and
				continuously improve your customer experience, to provide you with the
				most suitable offers and to send you targeted advertising. If you have
				consented, it may be used to send you promotional offers. Some of this
				information may also be communicated to third parties, namely to our
				subcontractors, who may be located outside the European Union. In
				accordance with applicable regulation, you have various rights regarding
				the use and protection of your personal information. When the basis for
				processing is your consent, you may withdraw that consent at any time.
				To learn more about your rights, the use and protection of your personal
				information, please refer to our{' '}
				<a
					className="underline underline-offset-4"
					href="/travel-centre/booking-with-confidence/privacy-policy"
					target="_blank"
				>
					Privacy Policy
				</a>
				. If you wish to exercise one of these rights or contact our Data
				Protection Officer, please{' '}
				<a
					className="underline underline-offset-4"
					href="/about-us/contact-us"
					target="_blank"
				>
					Contact Us
				</a>
				.
			</Text>

			<div className="flex flex-col gap-3 my-4 lg:gap-2">
				{errors?.terms_conditions?.message ||
				errors?.read_information?.message ? (
					<Text as="span" className="text-core-red">
						* Please confirm that you have read the information above and the
						terms & conditions
					</Text>
				) : null}

				<div className="flex items-center justify-start gap-2">
					<FormField
						as="checkbox"
						control={control}
						id="terms_conditions"
						name="terms_conditions"
						wrapperClassName="w-fit mt-1"
					/>
					<Text as="label" htmlFor="terms_conditions">
						Please check this box to confirm that you have read and accepted the{' '}
						<a
							target="_blank"
							rel="noreferrer noopener"
							className="underline underline-offset-4"
							href="/travel-centre/terms-and-conditions"
						>
							Canadian Affair terms & conditions.
						</a>
					</Text>
				</div>
				<div className="flex items-center justify-start gap-2">
					<FormField
						as="checkbox"
						control={control}
						id="read_information"
						name="read_information"
						wrapperClassName="w-fit mt-1"
					/>
					<Text as="label" htmlFor="read_information">
						Please confirm that you have read the important information above.
					</Text>
				</div>
			</div>
			<div className="flex flex-col items-start justify-between gap-2 my-5 lg:flex-row lg:items-center">
				<Button
					label="Agree & Continue"
					variant="supporting-yellow"
					onClick={handleSubmit}
					className="justify-between hidden md:flex w-fit"
					disabled={isDisabled}
				/>
				<Button
					type="button"
					disableAnimation
					variant="unstyled"
					onClick={handleBack}
					iconName="arrow-left"
					className="flex-row-reverse"
					label={`Back to ${previousStep?.name}`}
					labelClassName="font-normal"
				/>
			</div>
		</section>
	);
}

export default PassengerDetailsTemplate;

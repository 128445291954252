import PropTypes from 'prop-types';

import Text from '@/components/common/atoms/text';
import Icon from '@/components/common/atoms/icon';

/**
 * @typedef {object} SelectedTickProps
 * @property {boolean} isSelected
 * */

/**
 * @name SelectedTick
 * @description Renders a tick indicator with selected text
 * @returns {React.JSX.Element | null}
 * @example <SelectedTick />
 */
function SelectedTick({ isSelected }) {
    if (!isSelected) return null;

    return (
        <Text className="flex items-center gap-2">
            <Text as="span" className="font-bold">
                Selected
            </Text>
            <span className="flex justify-center w-6 h-6 p-1 text-center rounded-full bg-check-green">
                <Icon name="check" className="w-full h-full text-white" />
            </span>
        </Text>
    );
}

SelectedTick.propTypes = {
    isSelected: PropTypes.bool,
}

export default SelectedTick;

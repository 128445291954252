// import 'leaflet-css';
import PropTypes from 'prop-types';
import { lazy, Suspense } from 'react';
import { TileLayer, ZoomControl } from 'react-leaflet';

import { cn } from '@/lib/utils';
import { MAP_ACCESS_TOKEN } from '@/lib/constants';
import Skeleton from '@components/common/atoms/skeleton';

const MapContainer = lazy(() =>
	import('react-leaflet').then((module) => ({ default: module.MapContainer }))
);

function Map({
	className,
	defaultCenter,
	scrollWheelZoom,
	minZoom,
	zoomLevel,
	children,
	instance,
	wrapperClassName,
	setMap,
}) {
	return (
		<div
			id="map"
			className={cn(
				'h-full w-full z-[0] results-map-container',
				wrapperClassName
			)}
		>
			<Suspense fallback={<Skeleton className="min-h-96.25 w-full" />}>
				<MapContainer
					id={instance}
					className={cn('h-full w-full z-[0]', className)}
					center={defaultCenter}
					whenReady={(map) => {
						map.target.flyTo(defaultCenter, zoomLevel || 10);
						if (typeof setMap === 'function') setMap(map);
					}}
					zoom={zoomLevel || 13}
					zoomControl={false}
					scrollWheelZoom={scrollWheelZoom || false}
					minZoom={minZoom || 10}
				>
					<TileLayer
						attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${MAP_ACCESS_TOKEN}`}
					/>

					<ZoomControl position="topleft" />
					{children}
				</MapContainer>
			</Suspense>
		</div>
	);
}

Map.propTypes = {
	className: PropTypes.string,
	defaultCenter: PropTypes.array,
	scrollWheelZoom: PropTypes.bool,
	minZoom: PropTypes.number,
	zoomLevel: PropTypes.number,
	children: PropTypes.node,
	instance: PropTypes.string,
	setMap: PropTypes.func,
};

Map.defaultProps = {
	className: '',
	defaultCenter: [43.65107, -79.347015],
	scrollWheelZoom: false,
	minZoom: 6,
	zoomLevel: 7,
	children: null,
	instance: 'map',
};

export default Map;

import qs from 'qs';
import {
    isDate,
    isBefore,
    differenceInDays,
    startOfDay,
    addDays,
    format,
    isValid
} from 'date-fns';
import {
    HOLIDAY_DURATIONS,
    HOLIDAY_DESTINATIONS_DEPARTING,
} from '@/lib/utils';

import { HolidayBookingForm } from '@/components/holidays';
import { holidayBookingSchema } from '@/validationSchemas/holidayBooking';
import invoke from '@/lib/invoke';
import { ENDPOINTS } from '@/lib/api';

export default class BookingType {

    static htmlAttribute = 'data-hotel'

    // static nextPage = '/booking/holidays/passenger-details'
    static nextPage = '/booking/holidays/travel-plan'

    static stateName = 'holidays'

    static bookingType = 'holidays'

    static validationSchema = holidayBookingSchema

    static getFormComponent() {
        return HolidayBookingForm;
    }

    static async getPreviewParams(baseParams, rooms) {
        if (!rooms) return null;
        if (!(rooms && rooms[0]?.room)) return null;

        const roomParams = rooms.reduce((totalRooms, room) => {
            if (!room?.room?.value) return totalRooms;
            if (!room?.adults?.value) return totalRooms;

            // default to 2 adults on first room
            const defaultAdults = totalRooms.length === 0 ? 2 : 0;
            totalRooms.push({
                roomCode: room.room?.value,
                name: room.room?.name,
                adults: room?.adults?.value
                    ? parseInt(room.adults.value)
                    : defaultAdults,
                children: room?.children?.value ? parseInt(room.children.value) : 0,
                infants: room?.infants?.value ? parseInt(room.infants.value) : 0,
            });
            return totalRooms;
        }, []);

        const { startDate, endDate, outboundDepart, code, holidayType } = baseParams;

        // before we can return the params, we need to fetch the holiday from the API
        // and get the correct flight details
        const bookingInfo = await BookingType.getHolidayFromApi(startDate, endDate, outboundDepart, code, roomParams, holidayType);

        if (bookingInfo) {
            const hotel = bookingInfo?.[0]?.items.find((item) => item?.type === 'accommodation');
            const flight = bookingInfo?.[0]?.items.find((item) => item?.type === 'flight');

            return {
                category: 'holidays',
                includeHolidayUpgrades: true,
                items: [{
                    ...baseParams,
                    ...bookingInfo?.[0],
                    rooms: hotel?.rooms?.map((room) => ({
                        roomCode: room?.roomCode,
                        adults: room?.adults,
                        children: room?.children,
                        infants: room?.infants,
                    })),
                    location: hotel?.location,
                    outboundArrive: flight?.outbound?.arrival,
                }]
            }
        }

        return null;
    };

    static async getHolidayFromApi(startDate, endDate, outboundDepart, code, rooms, holidayType) {
        if (!(startDate && endDate && outboundDepart && code && rooms?.length)) {
            return null;
        }

        const { endpoint } = ENDPOINTS.find((e) => e.key === 'holiday-search');
        const body = {
            type: 'holidays',
            // type: holidayType,
            startDate,
            endDate,
            outboundDepart, //: 'LGW-AIR',
            accomCode: code,
            rooms,
            includeBookingItems: 1,
        };

        const { res, error } = await invoke({
            method: 'GET',
            params: body,
            endpoint: endpoint
        });

        if (error) {
            console.error('error', error);
            return null;
        }

        if (res.data.hits) {
            const firstHit = res.data.hits[0];
            const { bookingItems } = firstHit;
            return bookingItems;
        }

        return null;
    }

    static getDefaultValues(urlParams, holidayMaxDate) {
        const from = urlParams?.from ? urlParams.from.split(':') : [];
        const startDate = urlParams?.startDate
            ? new Date(urlParams.startDate)
            : null;
        const endDate = urlParams?.endDate ? new Date(urlParams.endDate) : null;

        const isValidEndDate =
            isDate(startDate) &&
            isDate(endDate) &&
            !isBefore(startOfDay(endDate), startOfDay(startDate));

        const hasHolidayMaxDate = holidayMaxDate && isValid(new Date(holidayMaxDate));

        // only pre-fill if at least 3 days from today & before max date
        const isValidHolidayFromDate =
            isDate(startDate) &&
            differenceInDays(startOfDay(startDate), startOfDay(new Date())) >=
            3 &&
            (!hasHolidayMaxDate || differenceInDays(
                startOfDay(startDate),
                startOfDay(new Date(holidayMaxDate))
            ) <= 0);

        // only pre-fill if after the from date & before max date
        const isValidHolidayEndDate =
            isValidHolidayFromDate &&
            isValidEndDate &&
            (!hasHolidayMaxDate || differenceInDays(
                startOfDay(endDate),
                startOfDay(new Date(holidayMaxDate))
            ) <= 0);

        let duration =
            isDate(startDate) && isDate(endDate)
                ? differenceInDays(endDate, startDate)
                : -1;

        // only allow 7/14 night durations

        let flightEndDate = isValidHolidayEndDate ? endDate : undefined;
        if (duration <= 0 && !isDate(endDate)) {
            const isValidFlightEnd = isValidHolidayEndDate && [7, 14].includes(duration);
            if (!isValidFlightEnd) {
                flightEndDate = undefined;

                // set default end date (7 days from start date)
                if (isValidHolidayFromDate) {
                    const defaultEndDate = !hasHolidayMaxDate ||
                        differenceInDays(
                            startOfDay(addDays(startDate, 7)),
                            startOfDay(new Date(holidayMaxDate))
                        ) <= 0
                        ? addDays(startDate, 7)
                        : new Date(holidayMaxDate);

                    flightEndDate = defaultEndDate;
                    duration = 7;
                }
            }
        }

        const durationOption = HOLIDAY_DURATIONS.find(
            (option) => option.value === duration
        );

        const roomdefaultParams = urlParams?.rooms?.length
            ? urlParams.rooms
            : [{ adults: 2, children: 0, infants: 0 }];

        const rooms = roomdefaultParams.map((room) => {
            const acc = { room: null };

            Object.keys(room).map((type) => {
                const value = room[type];
                acc[type] = {
                    value,
                    label: value.toString(),
                };
            }, {});

            return acc;
        });

        return {
            from: {
                value: from[0] ? from[0] : HOLIDAY_DESTINATIONS_DEPARTING[0].value,
                label: from[1] ? from[1] : HOLIDAY_DESTINATIONS_DEPARTING[0].label,
            },
            when: {
                from: isValidHolidayFromDate ? startDate : undefined,
                to: flightEndDate,
            },
            duration: durationOption ? durationOption : { value: 7, label: '7 Nights' },
            rooms: rooms,
        };
    }

    static createQueryStringFromBookingWidget(urlParams, values, loc) {
        const { type } = urlParams;
        const { duration, rooms, when, from } = values;
        const typeObj = type?.split(':');

        const formattedRooms = [];
        if (rooms?.length) {
            rooms.forEach((room) => {
                const roomData = {
                    adults: 0,
                    children: 0,
                    infants: 0,
                };

                Object.keys(room).map((type) => {
                    const roomInfo = room[type];
                    if (!roomInfo) return;

                    roomData[type] = roomInfo.value;
                });
                formattedRooms.push(roomData);
            });
        }

        const queryString = qs.stringify({
            category: 'holidays',
            type: !!typeObj ? `${typeObj[0]}:${typeObj[1]}` : 'holidays:City Break',
            from: `${from.value}:${from.label}`,
            location: loc,
            duration: duration?.value ? `${duration.value}:${duration.label}` : '',
            roomCount: rooms.length > 0
                ? `${rooms.length}:${rooms.length}`
                : '',
            rooms: formattedRooms,
            startDate: format(when?.from, 'yyyy-MM-dd'),
            endDate: format(when?.to, 'yyyy-MM-dd'),
        });

        return queryString;
    }
}

import './site/styles/sass/styles.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import App from '@/routes/routes';
import reportWebVitals from './reportWebVitals';

// React Query Client
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: 2,
			staleTime: 60 * 1000, // 1 minute
		},
	},
});

// we check if we are loading the main routing or just portals
const hasRoot = document.getElementById('root');
const hasPortals = document.getElementById('booking-portals-only');

let root;

if (hasPortals) {
	root = ReactDOM.createRoot(hasPortals);
} else if (hasRoot) {
	root = ReactDOM.createRoot(hasRoot);
}

root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<App />
		</QueryClientProvider>
	</React.StrictMode>
);

reportWebVitals();

import { useDisclosure } from '@/hooks';
import {
	Heading,
	Text,
	Icon,
	Button,
	MediaCarousel,
	RteStyledContent,
	Route,
} from '@/components/common';
import { cn, formatCarouselSlides, formatLocalDate } from '@/lib/utils';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { useBreakpoint } from '@/hooks';

function SummaryCard({
	hotel,
	hideChange,
	disableChange,
	onChangeRoomClick,
	className,
	badge,
	children,
	mobileRoomChangeDialogComponent = null,
}) {
	const isSmallDevice = useBreakpoint('md');
	const { onToggle, isOpen } = useDisclosure(false);

	const formattedStartDate = hotel?.startDate
		? formatLocalDate(hotel.startDate, 'dd MMMM yyyy')
		: null;
	const formattedEndDate = hotel?.endDate
		? formatLocalDate(hotel.endDate, 'dd MMMM yyyy')
		: null;

	const slides = useMemo(
		() => formatCarouselSlides(hotel?.gallery),
		[hotel?.gallery]
	);

	return (
		<section className={cn('lg:p-5 w-full bg-white', className)}>
			<div className="flex flex-col-reverse lg:gap-5 lg:flex-row">
				<div className="flex-1 p-5 lg:p-0 xl:mr-6">
					<Heading
						as="h2"
						className="mb-4 text-3xl font-bold font-body lg:mb-1.5"
					>
						{hotel?.pagetitle || hotel?.name}
					</Heading>
					{isOpen ? (
						// TODO: create a separate hotel details component
						<div>
							{hotel?.facilities?.length > 0 && (
								<div>
									<Heading
										as="h4"
										className="mb-2 text-base font-bold leading-snug tracking-tighter sm:text-lg md:text-xl font-body md:mb-0"
									>
										Facilities
									</Heading>
									<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
										{hotel.facilities.map((facility) => (
											<div
												key={facility?.id}
												className="flex items-center gap-2 my-0.5 md:my-2"
											>
												<img
													src={facility?.image}
													alt={`${facility?.title} icon`}
													className="w-6 h-6"
												/>
												<Text
													as="span"
													className="leading-snug tracking-tighter"
												>
													{facility?.title}
												</Text>
											</div>
										))}
									</div>
								</div>
							)}
							{hotel?.introtext && (
								<div className="my-4">
									<Heading
										as="h4"
										className="mb-2 text-base font-bold leading-snug tracking-tighter sm:text-lg md:text-xl font-body md:mb-0"
									>
										About the hotel
									</Heading>
									<Text className="text-base font-normal leading-snug tracking-normal font-body text-dark-grey/75">
										{hotel.introtext}
									</Text>
								</div>
							)}
							{hotel?.nearby && (
								<div>
									<Heading
										as="h4"
										className="mb-2 text-base font-bold leading-snug tracking-tighter sm:text-lg md:text-xl font-body md:mb-0"
									>
										What's nearby
									</Heading>
									<RteStyledContent
										variant="normal"
										dangerouslySetInnerHTML={{ __html: hotel.nearby }}
									/>
								</div>
							)}
						</div>
					) : null}
					{(hotel?.facilities?.length > 0 ||
						hotel?.introtext ||
						hotel?.nearby) && (
						<Button
							variant="unstyled"
							onClick={onToggle}
							className="gap-1 my-4 lg:my-1.5 hover:opacity-75"
						>
							<Text as="span" className="text-sm leading-tight">
								{isOpen ? 'Hide' : 'Info'}
							</Text>
							<Icon
								className="w-3 h-3"
								name={isOpen ? 'chevron-up' : 'chevron-down'}
							/>
						</Button>
					)}
					<div>
						{badge}

						{formattedStartDate || formattedEndDate ? (
							<Route
								className="my-4 lg:my-1.5"
								iconName="hotel"
								renderFrom={() => (
									<Text as="span" className="flex flex-col">
										<Text
											as="span"
											className="leading-[1.3] font-medium tracking-less-tight"
										>
											Check in
										</Text>
										<Text
											as="span"
											className="font-bold leading-[1.3] tracking-less-tight"
										>
											{formattedStartDate ? formattedStartDate : '-'}
										</Text>
									</Text>
								)}
								renderTo={() => (
									<Text as="span" className="flex flex-col items-end">
										<Text
											as="span"
											className="leading-[1.3] font-medium tracking-less-tight"
										>
											Check out
										</Text>
										<Text
											as="span"
											className="font-bold leading-[1.3] tracking-less-tight"
										>
											{formattedEndDate ? formattedEndDate : '-'}
										</Text>
									</Text>
								)}
							/>
						) : null}

						{hotel?.rooms?.length > 0 && (
							<div className="flex flex-wrap items-start gap-1 lg:pt-4">
								<Text as="span" className="leading-[1.3] tracking-less-tight">
									{pluralize('Room', hotel.rooms.length)}:
								</Text>

								<Text
									as="span"
									className="font-bold leading-[1.3] tracking-less-tight"
								>
									{hotel.rooms
										.map(
											(room) =>
												room?.name +
												(room?.boardBasis
													? ` - ${
															room?.boardBasisDisplayName || room?.boardBasis
													  }`
													: '')
										)
										.filter((name) => name)
										.join(', ')}
								</Text>

								{!hideChange &&
									typeof onChangeRoomClick === 'function' &&
									!!mobileRoomChangeDialogComponent &&
									(isSmallDevice ? (
										mobileRoomChangeDialogComponent
									) : (
										<Button
											variant="unstyled"
											className="inline text-base font-bold hover:opacity-75 leading-[1.3] tracking-less-tight underline underline-offset-4"
											onClick={onChangeRoomClick}
											disabled={disableChange}
										>
											{`(change)`}
										</Button>
									))}
							</div>
						)}

						{children}
					</div>
				</div>
				{slides?.length > 0 && (
					<div className="flex flex-col items-end justify-start gap-2 h-72 md:h-[35vh] lg:h-[32.5vh] w-full lg:w-5/12 xl:w-[22rem]">
						<div className="w-full col-span-1 h-full max-h-72 md:max-h-[35vh] lg:max-h-full">
							<MediaCarousel
								slides={slides}
								showSlideTitles
								showAltOnBottom
								theme="dark"
								className="h-full"
							/>
						</div>
					</div>
				)}
			</div>
		</section>
	);
}
export default SummaryCard;

import PropTypes from 'prop-types';
import Heading from '@/components/common/atoms/heading';
import Text from '@/components/common/atoms/text';
import VisaBookingNotice from '@/components/common/molecules/visa-booking-notice';

/**
 * @name HotelBookingTravelDetails
 * @description A summary of the hotel travel notice details
 * @returns {React.JSX.Element}
 * @example
 * <HotelBookingTravelDetails hasErrata={false} />
 * */

function HotelBookingTravelDetails({ hasErrata }) {
    return (
        <>
            <div className="border-b border-lighter-grey">
                <Heading
                    as="h3"
                    className="text-base font-bold uppercase w-fit font-body"
                >
                    Travel Documents
                </Heading>
                <Text className="pt-2 pb-4 text-dark-grey/65">
                    You MUST present at check-in your Canadian Affair voucher. No other form of printed documentation will be accepted.
                </Text>
            </div>
            <VisaBookingNotice
                className={hasErrata ? 'border-b border-lighter-grey' : ''}
                textClassName={!hasErrata ? 'pb-0' : ''}
            />
        </>
    );

};

HotelBookingTravelDetails.propTypes = {
    hasErrata: PropTypes.bool,
}

HotelBookingTravelDetails.defaultProps = {
    hasErrata: false,
}

export default HotelBookingTravelDetails;

import { ErrorBoundary } from 'react-error-boundary';

import { useSeatSelection } from '@/context';
import { ErrorHandler } from '@/components/common';
import { SeatsFlightCard } from '@components/flights';

function SelectionList({ category, useParentFlight }) {
	const { inboundSectors, outboundSectors } = useSeatSelection();

	return (
		<ErrorBoundary FallbackComponent={ErrorHandler}>
			{outboundSectors.map((flight, idx) => (
				<SeatsFlightCard
					type="outbound"
					category={category}
					flight={flight}
					flightIndex={idx}
					key={`${flight?.flightNumber}-outbound`}
					totalFlights={outboundSectors?.length || 0}
					useParentFlight={useParentFlight}
				/>
			))}

			{inboundSectors.map((flight, idx) => (
				<SeatsFlightCard
					type="inbound"
					category={category}
					flight={flight}
					flightIndex={idx}
					key={`${flight?.flightNumber}-return`}
					totalFlights={inboundSectors?.length || 0}
					useParentFlight={useParentFlight}
				/>
			))}
		</ErrorBoundary>
	);
}

export default SelectionList;

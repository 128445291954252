import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { addDays, differenceInDays, format, isValid, startOfDay } from 'date-fns';
import { Controller } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import {
	Text,
	Button,
	FormField,
	DatePlaceHolder,
	BookingCalendar,
} from '@/components/common';
import {
	cn,
	Required,
	ONE_TO_NINE,
	ZERO_TO_NINE,
	HOLIDAY_DESTINATIONS_DEPARTING,
	FLIGHT_HOTEL_DURATIONS,
	HOLIDAY_DURATIONS,
} from '@/lib/utils';
import { HOLIDAY_MAX_DATE } from '@/lib/utils/restrictions';
import { useDisclosure, useFetch } from '@/hooks';
import { getAvailableRooms } from '@/lib/utils/room';

function BookingForm({
	control,
	errors,
	watch,
	clearErrors,
	setValue,
	params,
}) {
	const { from, to } = watch('when');
	const roomPax = watch('rooms');
	const duration = watch('duration');
	const departingFrom = watch('from');

	const { isOpen, onToggle } = useDisclosure(() => {
		// show field summary
		if (from && to) {
			return true;
		}

		// show empty field
		if (!from && !to) {
			return true;
		}

		return false;
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'rooms',
	});

	const validateTotalPeople = useCallback((value, formValues) => {
		// only allow up to 9 people per booking
		const adults = formValues.adults?.value
			? parseInt(formValues.adults.value)
			: 2;
		const children = formValues.children?.value
			? parseInt(formValues.children.value)
			: 0;
		const infants = formValues.infants?.value
			? parseInt(formValues.infants.value)
			: 0;

		// show custom message to prevent 3 fields with the same message
		const isValid = adults + children + infants <= 9;
		setShowMaxPeopleError(!isValid);

		return isValid;
	});

	const validRooms = roomPax?.filter((room) => !!room?.adults?.value);
	const roomsParams = useMemo(() => {
		const validStartDate = isValid(from) ? format(from, 'yyyy-MM-dd') : null;
		const validEndDate = isValid(to) ? format(to, 'yyyy-MM-dd') : null;

		if (!(params?.code && validStartDate && validEndDate)) {
			return null;
		}

		return {
			code: params?.code,
			startDate: format(from, 'yyyy-MM-dd'),
			endDate: format(to, 'yyyy-MM-dd'),
			rooms: validRooms?.map((room, idx) => ({
				adults: room?.adults?.value,
				children: room?.children?.value,
				infants: room?.infants?.value,
			})),
		};
	}, [JSON.stringify(params), to, from, JSON.stringify(validRooms)]);

	const { data, isLoading } = useFetch({
		key: 'hotel-rooms',
		params: roomsParams || {},
		config: {
			enabled: !!roomsParams,
			retry: false,
		},
	});

	// get all previous selected room keys in order
	const selectedRoomKeys = roomPax
		.map((room) => room?.room?.key)
		.filter(Boolean);

	return (
		<div className="flex flex-col gap-4">
			{errors?.code ? (
				<Text as="p" className="my-2 text-red-500">
					{errors?.code?.message}
				</Text>
			) : null}

			<FormField
				name="from"
				as="select"
				showRequired
				errors={errors}
				control={control}
				validation={[Required]}
				placeholder="Select"
				label="Departing from"
				wrapperClassName="h-full w-full"
				labelClassName="whitespace-nowrap"
				options={HOLIDAY_DESTINATIONS_DEPARTING}
			/>

			<div className="flex flex-col items-center justify-center gap-3">
				<Text as="label" className="font-bold text-gray-700">
					Select a departure date
				</Text>

				{!isOpen && (
					<FormField
						name="duration"
						as="select"
						showRequired
						errors={errors}
						control={control}
						validation={[Required]}
						placeholder="Select"
						label="For"
						wrapperClassName="h-full w-full"
						labelClassName="whitespace-nowrap"
						options={HOLIDAY_DURATIONS}
						// value={HOLIDAY_DURATIONS[0]}
					/>
				)}

				<div className="flex flex-col w-full">
					<Controller
						name="when"
						control={control}
						render={({ field }) => {
							return isOpen ? (
								<DatePlaceHolder
									hideTime
									showIcon
									type="inputdate"
									handleClick={onToggle}
									className="cursor-pointer"
									selected={{ from: new Date(from), to: new Date(to) }}
								/>
							) : (
								<BookingCalendar
									className="w-full"
									code={params?.code}
									{...field}
									priceEndpoint="holiday-prices"
									pricingParams={(baseParams) => {
										return {
											...baseParams,
											outboundDepart: departingFrom?.value,
											includeMonths: 2,
											duration: duration?.value || 7,
										};
									}}
									onChange={(range) => {
										// set end date 7/14 days after from date
										const daysToAdd =
											parseInt(duration?.value) || HOLIDAY_DURATIONS[0]?.value;

										// do not exceed max date
										const hasMaxDate = HOLIDAY_MAX_DATE && isValid(new Date(HOLIDAY_MAX_DATE));
										const newEndDate = !hasMaxDate ||
											differenceInDays(
												startOfDay(addDays(range.from, daysToAdd)),
												startOfDay(new Date(HOLIDAY_MAX_DATE))
											) <= 0
											? addDays(range.from, daysToAdd)
											: new Date(HOLIDAY_MAX_DATE);

										field.onChange({
											from: range.from,
											to: newEndDate,
										});

										if (range.from) {
											setTimeout(() => {
												onToggle();
											}, 500);
										}
									}}
									minDate={addDays(new Date(), 3)}
									maxDate={HOLIDAY_MAX_DATE ? new Date(HOLIDAY_MAX_DATE) : null}
								/>
							);
						}}
					/>
					{!isOpen && (
						<Text
							as="p"
							className="mt-1 w-full text-sm font-normal leading-tight !text-light-black/75 text-body"
						>
							Prices based on two people sharing
						</Text>
					)}
				</div>

				<div className="flex flex-col w-full">
					<ErrorMessage
						errors={errors}
						name="when.from"
						render={({ message }) => (
							<Text as="span" variant="error" className="mt-2">
								&uarr; {message}
							</Text>
						)}
					/>
					<ErrorMessage
						errors={errors}
						name="when.to"
						render={({ message }) => (
							<Text as="span" variant="error" className="mt-2">
								&uarr; {message}
							</Text>
						)}
					/>
				</div>
			</div>

			{fields?.map((_, idx) => (
				<div
					key={`booking-holidays-rooms-${_.id}`}
					className="flex flex-col gap-4"
				>
					<div className="grid w-full grid-cols-2 gap-3 md:grid-cols-3">
						<FormField
							name={`rooms.${idx.toString()}.adults`}
							label={idx === 0 ? 'Who' : `Who room ${idx + 1}?`}
							as="select"
							options={ONE_TO_NINE}
							control={control}
							errors={errors}
							validation={[Required, validateTotalPeople]}
							wrapperClassName="w-full lg:min-w-[124px]"
							placeholder="Adults"
							footer="Age 18+"
						/>

						<FormField
							name={`rooms.${idx.toString()}.children`}
							as="select"
							options={ZERO_TO_NINE}
							control={control}
							errors={errors}
							validation={[validateTotalPeople]}
							wrapperClassName="w-full lg:min-w-[124px] mt-6.25"
							placeholder="Children"
							footer="Age 2-17"
						/>

						<FormField
							name={`rooms.${idx.toString()}.infants`}
							as="select"
							options={ZERO_TO_NINE}
							control={control}
							errors={errors}
							validation={[validateTotalPeople]}
							className={cn('md:mt-6.25')}
							wrapperClassName="w-full col-span-2 md:col-span-1 lg:min-w-[124px]"
							placeholder="Infants"
							footer="Age < 2"
						/>
					</div>

					<FormField
						name={`rooms.${idx.toString()}.room`}
						as="select"
						control={control}
						errors={errors}
						validation={[Required]}
						wrapperClassName="h-full w-full col-span-2"
						labelClassName="whitespace-nowrap"
						placeholder="Select Room"
						options={getAvailableRooms(data, selectedRoomKeys, idx)}
						isLoading={isLoading}
						disabled={!roomsParams}
					/>

					{fields?.length > 1 && (
						<Button
							hideIcon
							type="button"
							disableAnimation
							variant="unstyled"
							label="Remove room"
							className="h-auto underline border rounded-md w-fit border-border-color text-core-blue/70 underline-offset-4"
							labelClassName="text-sm font-normal hover:text-core-blue"
							onClick={() => {
								remove(idx);

								// clear errors
								clearErrors();
							}}
						/>
					)}
				</div>
			))}

			{fields?.length < 3 && (
				<Button
					type="button"
					variant="unstyled"
					label="Add a room"
					onClick={() => {
						// add room
						append({
							adults: null,
							children: null,
							infants: null,
						});

						// clear errors
						clearErrors();
					}}
					className="underline underline-offset-2 w-fit"
					hideIcon
				/>
			)}
		</div>
	);
}

BookingForm.propTypes = {
	params: PropTypes.shape({
		location: PropTypes.string,
		from: PropTypes.string,
		adults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		infants: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		startDate: PropTypes.string,
		endDate: PropTypes.string,
	}),
};

BookingForm.defaultProps = {
	params: {},
	hideClearBtn: false,
};

export default BookingForm;

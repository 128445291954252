import React from 'react';

import { useUrlParams } from '@/hooks';
import { withPortal } from '@/HOCs/withPortal';
import { MotorHomeList } from '@/components/motorhomes';
import {
	Button,
	Column,
	PageHeader,
	FilterWidget,
	SearchWidget,
	PageWrapper,
} from '@/components/common';
import { cn } from '@/lib/utils';
import useVerticalOverflow from '@/hooks/useVerticalOverflow';

/**
 * @name MotorHomeSearch Page
 * @description This page is used to display the motorhome search results - it is also a portalized page with an id of 'booking-motorhome-search-page'
 * @returns {React.JSX.Element}
 **/
function MotorHomeSearch({ portalData }) {
	const { params } = useUrlParams('motorhomehire_filters');
	const { observe, isOverflowing } = useVerticalOverflow(true);

	return (
		<>
			<SearchWidget
				ref={observe}
				category="motorhome-hire"
				className={cn(
					portalData?.withSiteHeader
						? 'fixed z-50 w-full top-15 md:top-25 drop-shadow-3xl max-h-[calc(100vh-3.75rem)] md:max-h-[calc(100vh-6.25rem)]'
						: 'max-h-screen',
					isOverflowing ? 'overflow-y-auto' : ''
				)}
			/>

			<PageWrapper
				error={null}
				loading={false}
				loaderClassName="min-h-[calc(100dvh-4rem)] md:min-h-[calc(100vh-4.5rem)]"
			>
				<div className="w-full min-h-screen pt-5 pb-10 mx-auto bg-light-grey lg:bg-white">
					<PageHeader title="Motorhome Search Results" />
					<Column className="lg:gap-12">
						<FilterWidget
							widgetLabel="Filter Motorhomes By"
							paramsKey="motorhomehire_filters"
							filters={[
								{ type: 'dates', name: 'dates' },
								{ type: 'budget', name: 'budget', min: 400, max: 10000 },
								{ type: 'capacity', name: 'capacity' },
							]}
						/>
						<div className="w-full col-span-1 mx-auto lg:col-span-3">
							<MotorHomeList />
							<div className="flex items-center justify-center py-5">
								<Button
									variant="outline"
									className="font-semibold border-lightest-grey text-lightest-grey hover:bg-core-blue hover:text-white"
								>
									Load More
								</Button>
							</div>
						</div>
					</Column>
				</div>
			</PageWrapper>
		</>
	);
}

// create a portalized version of this component
export default withPortal(MotorHomeSearch, 'booking-motorhome-search-page');

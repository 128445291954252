import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import {
	Text,
	DirectionalArrow,
	NullTerniaryWrapper,
} from '@components/common';
import {
	currencyFormatter,
	formatLocalDate,
	formatLocalTime,
} from '@/lib/utils';

function FlightDetails({ flight, showPricingBreakdown }) {
	const peoplePrices = useMemo(() => {
		if (!flight?.prices) return () => null;

		const persons = Object.keys(flight?.prices) || [];
		return (
			<div>
				{persons.map((person, idx) => {
					const personDetails = flight?.prices[person];

					if (!personDetails?.quantity) return null;

					return (
						<div
							key={`${person}-price-${idx}`}
							className="flex items-center justify-between"
						>
							<Text as="small" className="text-sm capitalize">
								{`${personDetails?.quantity}x`} {person.replace('s', '')}{' '}
							</Text>
							<NullTerniaryWrapper
								condition={showPricingBreakdown && !!personDetails?.total}
							>
								<Text as="small" className="text-sm">
									{currencyFormatter({
										amount: personDetails?.total ?? 0,
									})}
								</Text>
							</NullTerniaryWrapper>
						</div>
					);
				})}
			</div>
		);
	}, [flight]);

	const departureDate = formatLocalDate(flight?.departureTime, 'dd MMM, yyyy');
	const departureTime = formatLocalTime(flight?.departureTime);
	const arrivalTime = formatLocalTime(flight?.arrivalTime);

	// show sector flight numbers, otherwise parent flight number if sectors aren't provided
	const displayNumber = useMemo(() => {
		if (!flight?.sectors?.length) return flight?.flightNumber;

		return flight.sectors
			.map((sec) => sec?.carrier?.code + sec?.flightNumber)
			.filter((num) => num)
			.join(' / ');
	}, [flight?.sectors]);

	return (
		<div className="flex flex-col gap-1 py-2">
			<div className="grid grid-cols-5 gap-3">
				<Text
					title={flight?.departureName}
					className="col-span-2 text-sm line-clamp-3"
				>
					{flight?.departureName}
				</Text>
				<div className="flex items-start w-full col-span-1 py-1">
					<DirectionalArrow />
				</div>
				<Text
					title={flight?.arrivalName}
					className="col-span-2 text-sm line-clamp-3"
				>
					{flight?.arrivalName}
				</Text>
			</div>
			<Text className="flex items-center gap-1 text-sm font-light">
				{displayNumber ? (
					<Text as="small" className="text-sm font-light uppercase">
						{displayNumber}
					</Text>
				) : null}
				{departureDate ? (
					<Text as="small" className="text-sm font-light">
						{departureDate},
					</Text>
				) : null}
				<Text as="small" className="text-sm font-light">
					{departureTime} - {arrivalTime}
					{flight?.daysForward > 0 && (
						<Text as="span" className="text-sm font-light">
							{' '}
							(+{flight?.daysForward})
						</Text>
					)}
				</Text>
			</Text>

			<Text as="small" className="text-sm" title={flight?.cabin?.code}>
				Fare type:{' '}
				{flight?.cabin?.displayName ||
					flight?.cabin?.name ||
					flight?.cabin?.code}
			</Text>
			{peoplePrices}

			{/* Add the taxes */}
			<Text as="small" className="text-sm font-light text-lightest-grey/70">
				Fares include taxes and other charges
			</Text>
		</div>
	);
}

FlightDetails.propTypes = {
	flight: PropTypes.shape({
		departureName: PropTypes.string,
		arrivalName: PropTypes.string,
		carrier: PropTypes.shape({
			code: PropTypes.string,
		}),
		flightNumber: PropTypes.string,
		departureTime: PropTypes.string,
		arrivalTime: PropTypes.string,
		cabin: PropTypes.shape({
			code: PropTypes.string,
		}),
		prices: PropTypes.object,
	}),
	showPricingBreakdown: PropTypes.bool,
};

FlightDetails.defaultProps = {
	flight: {
		departureName: '',
		arrivalName: '',
		carrier: {
			code: '',
		},
		flightNumber: '',
		departureTime: '',
		arrivalTime: '',
		cabin: {
			code: '',
		},
		prices: {},
	},
	showPricingBreakdown: false,
};

export default FlightDetails;

import qs from 'qs';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	addDays,
	differenceInDays,
	format,
	isDate,
	isValid,
	startOfDay,
} from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';

import { useSearchSave } from '@/hooks';
import { MotorCarSchema } from '@/validationSchemas';
import { FormField, SearchFormButton, Text } from '@/components/common';
import useDateRestrictions from '@/hooks/useDateRestrictions';

/**
 * @typedef {Object} MotorCarFormProps
 * @property {()=>void} clear
 * @property {Object} params
 * @property {boolean} motorhome
 * @property {boolean} hideClearBtn
 */

/**
 * @name MotorCarForm
 * @description A form for searching for motorhomes or cars
 * @param {MotorCarFormProps} props
 * @returns {React.JSX.Element}
 * @example
 * <MotorCarForm clear={clearForm} params={params} hideClearBtn={false} motorhome />
 */

function MotorCarForm({ clear, params, motorhome, hideClearBtn }) {
	const navigate = (url) => (window.location.href = url);

	const { saveSearch, getSearch } = useSearchSave();
	const [hasSelectedTime, setHasSelectedTime] = useState(false);
	const [showSelectedTimeMsg, setShowSelectedTimeMsg] = useState(false);
	const { carHireMaxDate } = useDateRestrictions();

	const existingSearch = getSearch(motorhome ? 'motorhome' : 'carhire');

	let defaultParams = null;

	if (existingSearch && !params) {
		defaultParams = qs.parse(existingSearch);
	} else {
		defaultParams = params;
	}

	const emptyParams = {
		pickup: null,
		dropoff: null,
		when: {
			from: addDays(new Date(), 3),
			to: addDays(new Date(), 8),
		},
	};

	const defaultValues = useMemo(() => {
		if (!defaultParams) return emptyParams;
		let startDate, endDate;
		const pickup = defaultParams?.pickup ? defaultParams.pickup.split(':') : [];
		const dropoff = defaultParams?.dropoff
			? defaultParams.dropoff.split(':')
			: [];

		if (defaultParams?.startDate) {
			const start = new Date(defaultParams.startDate);
			const pickupTimeParts = defaultParams?.pickupTime
				? defaultParams.pickupTime.split(':')
				: [];

			startDate = new Date(
				start.getFullYear(),
				start.getMonth(),
				start.getDate(),
				pickupTimeParts[0] || 0,
				pickupTimeParts[1] || 0
			);
		}

		if (defaultParams?.endDate) {
			const end = new Date(defaultParams.endDate);
			const dropoffTimeParts = defaultParams?.dropoffTime
				? defaultParams.dropoffTime.split(':')
				: [];

			endDate = new Date(
				end.getFullYear(),
				end.getMonth(),
				end.getDate(),
				dropoffTimeParts[0] || 0,
				dropoffTimeParts[1] || 0
			);
		}

		const hasMaxDate = carHireMaxDate && isValid(new Date(carHireMaxDate));

		// only pre-fill if at least 3 days from today & before max date
		const fromDate =
			isDate(startDate) &&
			differenceInDays(startOfDay(startDate), startOfDay(new Date())) >= 3 &&
			(
				!hasMaxDate ||
				differenceInDays(startOfDay(startDate), startOfDay(new Date(carHireMaxDate))) <= 0
			)
			? startDate
			: addDays(new Date(), 3);

		// only pre-fill if after the from date & before max date
		const defaultEndDate = !hasMaxDate || differenceInDays(
			addDays(fromDate, 8),
			startOfDay(new Date(carHireMaxDate))
		) <= 0
			? addDays(fromDate, 8)
			: new Date(carHireMaxDate);
		const toDate =
			isDate(endDate) &&
			differenceInDays(startOfDay(endDate), startOfDay(fromDate)) >= 0 &&
			(
				!hasMaxDate ||
				differenceInDays(startOfDay(endDate), startOfDay(new Date(carHireMaxDate))) <= 0
			)
			? endDate
			: defaultEndDate;

		return {
			pickup: pickup[0]
				? {
						value: pickup[0],
						label: pickup[1] || '',
				  }
				: null,
			dropoff: dropoff[0]
				? {
						value: dropoff[0],
						label: dropoff[1] || '',
				  }
				: null,
			when: {
				from: fromDate,
				to: toDate,
			},
			motorhome: motorhome || false,
		};
	}, [defaultParams, emptyParams, motorhome, carHireMaxDate]);

	const {
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
	} = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(MotorCarSchema),
	});

	const formStartDate = watch('when.from');
	const formEndDate = watch('when.to');
	useEffect(() => {
		if (!carHireMaxDate) return;
		if (!(formStartDate && formEndDate)) return;

		// reset form value if not valid with max date restriction
		const maxDate = new Date(carHireMaxDate);
		if (
			differenceInDays(new Date(formStartDate), maxDate) > 0 ||
			differenceInDays(new Date(formEndDate), maxDate) > 0
		) {
			setValue('when', {});
		}

	}, [carHireMaxDate, formStartDate, formEndDate]);

	const onSubmit = async (data) => {
		if (!showSelectedTimeMsg) {
			setShowSelectedTimeMsg(true);
		}
		if (!hasSelectedTime) return;

		const { pickup, dropoff, when } = data;
		const { from, to } = when;

		const queryString = qs.stringify({
			pickup: `${pickup.value}:${pickup.label}`,
			dropoff: `${dropoff.value}:${dropoff.label}`,
			startDate: format(from, 'yyyy-MM-dd'),
			endDate: format(to, 'yyyy-MM-dd'),
			pickupTime: hasSelectedTime ? format(from, 'HH:mm') : null,
			dropoffTime: hasSelectedTime ? format(to, 'HH:mm') : null,
			category: motorhome ? 'motorhome-hire' : 'car-hire',
		});

		saveSearch(queryString, motorhome ? 'motorhome' : 'carhire');

		if (motorhome) {
			navigate(`/search/motorhome-hire?${queryString}`);
		} else {
			navigate(`/search/car-hire?${queryString}`);
		}
	};

	const onError = () => {
		if (!showSelectedTimeMsg) {
			setShowSelectedTimeMsg(true);
		}
	};

	const selectedPickup = watch('pickup');
	const selectedDropoff = watch('dropoff');

	const getLocationType = (location) => {
		if (typeof location !== 'string') return null;

		const parts = location.split('-');
		if (!(parts && parts[1])) return null;

		return parts[1].toUpperCase();
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit, onError)}
			className="mx-auto flex flex-col lg:flex-row w-full items-center justify-center lg:items-start gap-2 md:gap-2.5 lg:gap-5"
		>
			<div className="flex flex-col md:flex-row w-full items-start justify-start gap-2 md:gap-2.5 lg:gap-5">
				<FormField
					name="pickup"
					label="Pick Up"
					control={control}
					errors={errors}
					as="select"
					endpoint="car-rental/locations"
					queryKey="car-rental-locations"
					isAsync
					onChange={(field, value) => {
						field.onChange(value);

						// reset dropoff input if not valid with newly selected pickup
						if (selectedDropoff?.value) {
							const selectedType = getLocationType(value?.value);
							if (
								selectedDropoff?.value !== value?.value &&
								selectedType !== 'AIR' &&
								!selectedDropoff.value.toUpperCase().includes('-AIR')
							) {
								setValue('dropoff', null);
							}
						}
					}}
				/>
				<FormField
					name="dropoff"
					label="Drop off"
					control={control}
					errors={errors}
					as="select"
					disabled={typeof selectedPickup?.value !== 'string'}
					endpoint="car-rental/locations"
					queryKey="car-rental-locations"
					filterAsyncOptions={(items) => {
						if (typeof selectedPickup?.value !== 'string') return [];

						const pickupType = getLocationType(selectedPickup?.value);
						if (!pickupType) return [];

						// allow any dropoff when picking up from an airport
						if (pickupType === 'AIR') return items;

						// picking up from a city location - restrict dropoff locations
						return items.filter((item) => {
							// allow return to same location
							if (item.value === selectedPickup.value) return true;

							// or, allow return to airport
							return (
								typeof item?.value === 'string' &&
								item.value.toUpperCase().includes('-AIR')
							);
						});
					}}
					isAsync
				/>
				<div className="flex flex-col w-full">
					<FormField
						name="when"
						label="When"
						control={control}
						errors={errors}
						as="date-range"
						disabledDays={{
							before: addDays(new Date(), 3),
							after: carHireMaxDate ? new Date(carHireMaxDate) : null,
						}}
						hasSelectedTime={hasSelectedTime}
						setHasSelectedTime={setHasSelectedTime}
						{...(!existingSearch && !params && { useCurrentTime: false })}
					/>
					{!hasSelectedTime && showSelectedTimeMsg && (
						<Text as="span" variant="error">
							&uarr; This requires time.
						</Text>
					)}
				</div>
			</div>
			<SearchFormButton
				onCancel={clear}
				hideCancel={hideClearBtn}
				className="flex-1"
				label={`Find ${motorhome ? 'Motorhomes' : 'Cars'}`}
			/>
		</form>
	);
}

MotorCarForm.propTypes = {
	clear: PropTypes.func.isRequired,
	params: PropTypes.shape({
		pickup: PropTypes.string,
		startDate: PropTypes.string,
		pickupTime: PropTypes.string,
		dropoff: PropTypes.string,
		endDate: PropTypes.string,
		dropoffTime: PropTypes.string,
	}),
	motorhome: PropTypes.bool,
};

MotorCarForm.defaultProps = {
	motorhome: false,
	params: {},
};

export default MotorCarForm;

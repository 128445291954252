import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '@/pages/Home';
import { Heading, ScrollToTop } from '@/components/common';
import {
	HeaderSearchWidget,
	HomeSearchWidget,
	SiteSearchWidget,
	CarHireSearchPage,
	HotelSearchPage,
	MotorHomeSearchPage,
	HolidaySearchPage,
	FlightSearchPage,
	FindMyHolidayPage,
	MarketingMaterialSearch,
	FlightSpecialOfferSearch,
	HotelsBookingWidget,
	MotorHomeBookingWidget,
	ItineraryBookingWidget,
} from '@/components/portals';
import {
	CarHireExtrasPage,
	BookingCompletePage,
	DriverDetailsPage,
	PaymentsPage,
	PassengerDetailsPage,
	QuoteSavedPage,
	ConfirmBookingPage,
} from '@/components/pages';
import Login from '@/pages/Login-own';
import {
	BaggagePage,
	FlightOptionPlus,
	FlightsPassengers,
	SeatSelectionPage,
} from '@/components/flights';
import { MotorHomeHireExtrasPage } from '@/components/motorhomes';
import { MultiStepTravelPlanPage, TravelPlanPage } from '@/components/holidays';
import {
	carhireSteps,
	holidaySteps,
	motorhomeSteps,
	holidayMultiSteps,
} from '@/lib/steps';
import {
	MyCafHelp,
	AuthHeader,
	MyCafLayout,
	MyCafDetails,
	MyCafBookings,
	MyCafOverview,
	MyCafFavourites,
	MyCafFlightExtras,
	MyCafBookingDetails,
	MyCafSeatSelection,
	MyCafETicktet,
	MyCafHotel,
	MyCafMotorhome,
	MyCafCarhire,
} from '@components/mycaf';

// import Login from '@/pages/Login';
// import Login2 from '@/pages/Login2';

function AppRouter() {
	const isProduction = import.meta.env.PROD;
	return (
		<Router>
			{isProduction ? (
				<>
					<HomeSearchWidget />
					<HeaderSearchWidget />
					<SiteSearchWidget />
					<CarHireSearchPage />
					<MotorHomeSearchPage />
					<HotelSearchPage />
					<HolidaySearchPage />
					<FindMyHolidayPage />
					<MarketingMaterialSearch />
					<FlightSpecialOfferSearch />
					<HotelsBookingWidget />
					<MotorHomeBookingWidget />
					<ItineraryBookingWidget />
					<FlightSearchPage />
					<AuthHeader />
					<ScrollToTop>
						<Routes>
							<Route path="/booking">
								<Route path=":category">
									<Route
										path="passenger-details"
										element={<PassengerDetailsPage />}
									/>
									<Route
										path="driver-details"
										element={<DriverDetailsPage />}
									/>
									<Route path="payment" element={<PaymentsPage />} />
									<Route
										path="booking-complete"
										element={<BookingCompletePage />}
									/>
									<Route path="quote-saved" element={<QuoteSavedPage />} />
									<Route
										path="confirm-booking"
										element={<ConfirmBookingPage />}
									/>
								</Route>
								<Route path="flights">
									<Route
										path="passenger-details"
										element={<FlightsPassengers />}
									/>
									<Route path="option-plus" element={<FlightOptionPlus />} />
									<Route path="baggage" element={<BaggagePage />} />
									<Route path="seats" element={<SeatSelectionPage />} />
								</Route>
								<Route path="holidays">
									<Route
										path="travel-plan"
										element={<TravelPlanPage steps={holidaySteps} />}
									/>
									<Route
										path="passenger-details"
										element={<FlightsPassengers />}
									/>
									<Route path="option-plus" element={<FlightOptionPlus />} />
									<Route path="baggage" element={<BaggagePage />} />
									<Route path="seats" element={<SeatSelectionPage />} />
								</Route>
								<Route path="holidays-multistep">
									<Route
										path="travel-plan"
										element={
											<MultiStepTravelPlanPage steps={holidayMultiSteps} />
										}
									/>
								</Route>
								<Route path="car-hire">
									<Route
										path="extras"
										element={<CarHireExtrasPage steps={carhireSteps} />}
									/>
								</Route>
								<Route path="motorhome-hire">
									<Route
										path="extras"
										element={<MotorHomeHireExtrasPage steps={motorhomeSteps} />}
									/>
								</Route>
							</Route>

							{/* <Route path="/my-canadian-affair" element={<MyCafLayout />}>
								<Route path="" element={<MyCafOverview />} />
								<Route path="bookings">
									<Route path="" element={<MyCafBookings />} />
									<Route path=":booking-ref">
										<Route path="" element={<MyCafBookingDetails />} />
										<Route
											path="flight-extras"
											element={<MyCafFlightExtras />}
										/>
										<Route
											path="seat-selection"
											element={<MyCafSeatSelection />}
										/>
										<Route path="request-eticket" element={<MyCafETicktet />} />
									</Route>
								</Route>
								<Route path="favourites" element={<MyCafFavourites />} />
								<Route path="details" element={<MyCafDetails />} />
								<Route path="help" element={<MyCafHelp />} />
							</Route> */}
						</Routes>
					</ScrollToTop>
				</>
			) : (
				<ScrollToTop>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/search">
							<Route path="car-hire">
								<Route path="" element={<CarHireSearchPage />} />
								{/* <Route path="extras" element={<CarHireExtras />} /> */}
							</Route>
							<Route path="motorhome-hire">
								<Route path="" element={<MotorHomeSearchPage />} />
								{/* <Route path="extras" element={<CarHireExtras />} /> */}
							</Route>
							<Route path="hotels" element={<HotelSearchPage />} />
							<Route path="holidays" element={<HolidaySearchPage />} />
							<Route path="flights" element={<FlightSearchPage />} />
						</Route>
						<Route path="/booking">
							<Route path=":category">
								<Route
									path="passenger-details"
									element={<PassengerDetailsPage />}
								/>
								<Route path="driver-details" element={<DriverDetailsPage />} />
								<Route path="payment" element={<PaymentsPage />} />
								<Route
									path="booking-complete"
									element={<BookingCompletePage />}
								/>
								<Route path="quote-saved" element={<QuoteSavedPage />} />
								<Route
									path="confirm-booking"
									element={<ConfirmBookingPage />}
								/>
							</Route>

							<Route path="flights">
								<Route
									path="passenger-details"
									element={<FlightsPassengers />}
								/>
								<Route path="option-plus" element={<FlightOptionPlus />} />
								<Route path="baggage" element={<BaggagePage />} />
								<Route path="seats" element={<SeatSelectionPage />} />
							</Route>
							<Route path="holidays">
								<Route
									path="travel-plan"
									element={<TravelPlanPage steps={holidaySteps} />}
								/>
								<Route
									path="passenger-details"
									element={<FlightsPassengers />}
								/>
								<Route path="option-plus" element={<FlightOptionPlus />} />
								<Route path="baggage" element={<BaggagePage />} />
								<Route path="seats" element={<SeatSelectionPage />} />
							</Route>
							<Route path="holidays-multistep">
								<Route
									path="travel-plan"
									element={
										<MultiStepTravelPlanPage steps={holidayMultiSteps} />
									}
								/>
							</Route>
							<Route path="car-hire">
								<Route
									path="extras"
									element={<CarHireExtrasPage steps={carhireSteps} />}
								/>
							</Route>
							<Route path="motorhome-hire">
								<Route
									path="extras"
									element={<MotorHomeHireExtrasPage steps={motorhomeSteps} />}
								/>
							</Route>
						</Route>
						<Route path="/my-canadian-affair" element={<MyCafLayout />}>
							<Route path="" element={<MyCafOverview />} />
							<Route path="bookings">
								<Route path="" element={<MyCafBookings />} />
								<Route path=":booking_ref">
									<Route path="" element={<MyCafBookingDetails />} />
									<Route path="flight-extras" element={<MyCafFlightExtras />} />
									<Route
										path="seat-selection"
										element={<MyCafSeatSelection />}
									/>
									<Route path="add-hotel" element={<MyCafHotel />} />
									<Route
										path="add-motorhomehire"
										element={<MyCafMotorhome />}
									/>
									<Route path="add-carhire" element={<MyCafCarhire />} />
									<Route path="request-eticket" element={<MyCafETicktet />} />
								</Route>
							</Route>
							<Route path="favourites" element={<MyCafFavourites />} />
							<Route path="details" element={<MyCafDetails />} />
							<Route path="help" element={<MyCafHelp />} />
						</Route>
						<Route
							path="find-my-holiday"
							element={<FindMyHolidayPage portalData={{ pageId: 92 }} />}
						/>
						<Route
							path="marketing-material"
							element={<MarketingMaterialSearch />}
						/>
						<Route
							path="flight-special-offers"
							element={<FlightSpecialOfferSearch />}
						/>
						<Route
							path="toronto-flight-special-offers"
							element={
								<FlightSpecialOfferSearch portalData={{ location: 'YYZ' }} />
							}
						/>
						<Route
							path="toronto-hotels"
							element={
								<HotelSearchPage
									portalData={{
										location: 'TOR-CITY',
										title: 'Hotels in Toronto',
									}}
								/>
							}
						/>
						<Route
							path="*"
							element={
								<div className="flex items-center justify-center w-full h-screen">
									<Heading>404 Not Found</Heading>
								</div>
							}
						/>
						<Route path="/login" element={<Login />} />
						{/* <Route path="/login2" element={<Login2 />} /> */}
					</Routes>
				</ScrollToTop>
			)}
		</Router>
	);
}

export default AppRouter;

import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import Heading from '@/components/common/atoms/heading';
import Text from '@/components/common/atoms/text';
import Button from '@/components/common/atoms/button';
import useFetch from '@/hooks/useFetch';
import PageWrapper from '@/components/common/organisms/page-wrapper';
import RoomOptionCard from '@/components/holidays/molecules/room-option-card';
import { getAvailableRooms } from '@/lib/utils/room';

function RoomOptionsList({
	hotel,
	handleChange,
	handleClose,
	disabled,
	scrollToContainer,
}) {
	const [selectedRooms, setSelectedRooms] = useState([]);

	const originalRooms = useMemo(
		() =>
			hotel?.rooms?.map((room) => ({
				adults: room?.pax?.adult,
				children: room?.pax?.child,
				infants: room?.pax?.infant,
				key: room?.key,
				name: room?.name,
				roomCode: room?.roomCode,
				pricePerPerson: room?.pricePerPerson,
				pricePerPersonPerNight: room?.pricePerPersonPerNight,
			})),
		[hotel?.rooms]
	);

	// update selected rooms when the hotel changes
	useEffect(() => {
		setSelectedRooms(originalRooms?.length ? originalRooms : []);
	}, [originalRooms]);

	const roomVariantParams = useMemo(() => {
		if (
			!(
				hotel?.code &&
				hotel?.startDate &&
				hotel?.endDate &&
				hotel?.rooms?.length
			)
		)
			return null;

		return {
			code: hotel.code,
			startDate: hotel.startDate,
			endDate: hotel.endDate,
			rooms: hotel?.rooms?.map((room, idx) => ({
				adults: room?.pax?.adult || 0,
				children: room?.pax?.child || 0,
				infants: room?.pax?.infant || 0,
			})),
			includeImages: 1,
		};
	}, [hotel?.code, hotel?.startDate, hotel?.endDate, hotel?.rooms]);

	const { data, error, isLoading } = useFetch({
		key: 'hotel-rooms',
		params: roomVariantParams || {},
		config: {
			enabled: !!roomVariantParams,
			retry: false,
		},
	});

	const selectedRoomKeys = selectedRooms
		.map((room) => room?.key)
		.filter(Boolean);

	const handleSelectRoom = (idx, newRoom) => {
		if (!originalRooms[idx]) return;

		let updatedSelection = [...selectedRooms];

		// reset child selections when changing a parent room
		if (idx < updatedSelection.length) {
			updatedSelection = updatedSelection.slice(0, idx);
		}

		// handle deselect a room
		if (!newRoom?.value) {
			delete updatedSelection[idx];
			setSelectedRooms(updatedSelection);
			return;
		}

		// handle select a room
		const newParams = JSON.parse(JSON.stringify(originalRooms[idx]));
		if (!newParams) return;

		newParams.key = newRoom.key;
		newParams.name = newRoom.name;
		newParams.roomCode = newRoom.value;
		newParams.pricePerPerson = newRoom.pricePerPerson;
		newParams.pricePerPersonPerNight = newRoom.pricePerPersonPerNight;
		updatedSelection[idx] = newParams;

		// update local state
		setSelectedRooms(updatedSelection);

		// push changes to booking state when selecting the last room
		if (updatedSelection?.length === originalRooms?.length) {
			if (scrollToContainer && typeof scrollToContainer === 'function') {
				scrollToContainer();
			}
			handleChange(updatedSelection);
			return;
		}
	};

	const hasRoomOptions = useMemo(() => {
		if (!data?.data) return false;

		return Object.keys(data?.data || {}).length > 0;
	}, [data?.data]);

	return (
		<PageWrapper
			error={error}
			loading={isLoading}
			loaderClassName="h-96"
			errorClassName="min-h-120 md:min-h-96.25"
			loadingText="Fetching room options..."
			className="flex flex-col w-full"
		>
			<div className="hidden px-3 pb-6 md:flex md:flex-row md:items-center md:justify-between md:px-5">
				<Heading
					as="h3"
					className="py-0 my-0 text-3xl font-body leading-extra-tight tracking-extra-tight"
				>
					Your Room Options
				</Heading>
				<Button
					label="Cancel"
					variant="unstyled"
					className="underline underline-offset-4"
					hideIcon
					labelClassName="text-lg tracking-tighter leading-loose-snug"
					onClick={handleClose}
				/>
			</div>
			{hasRoomOptions ? (
				[...Array(originalRooms?.length || 0)].map((_, idx) => (
					<RoomOptionCard
						key={`select-room-${idx}`}
						roomIdx={idx}
						selectedRooms={selectedRooms}
						handleSelect={handleSelectRoom}
						altRooms={getAvailableRooms(data, selectedRoomKeys, idx)}
						disabled={disabled}
					/>
				))
			) : (
				<div className="px-6">
					<Text>No other rooms are available.</Text>
				</div>
			)}
		</PageWrapper>
	);
}

RoomOptionsList.propTypes = {
	hotel: PropTypes.object,
	handleChange: PropTypes.func.isRequired,
	handleClose: PropTypes.func,
	disabled: PropTypes.bool,
};

RoomOptionsList.defaultProps = {
	hotel: {},
	altHotels: [],
	disabled: false,
};

export default RoomOptionsList;
